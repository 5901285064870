import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import SearchNominatim from 'ol-ext/control/SearchNominatim';
import VectorSource from 'ol/source/Vector.js';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Map from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import WKT from 'ol/format/WKT';
import { toggleSubmenu } from './toggleFunctions.js';
import { StatusLayerManager } from './statusLayerManager.js';
import { AgrupadosLayerManager } from './agrupadosLayerManager.js';
import { FeatureSelector } from './featureSelector.js';
import { DemandaFormHandler } from './demandaFormHandler.js';
import { initializePopup, displayPopup } from './popupUtils.js';
import { addZoomControls } from './controls/zoom/';
import { addHideMenuControls } from './controls/hideMenu/';
import { ModalManager } from './modalManager.js';
import { PrioridadeManager } from './prioridadeManager.js';
import { DemandaVisibilityManager } from './demandaVisibilityManager.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { initDeliberacaoExecutar } from './deliberacaoExecutar.js';
import { initDeliberacaoRecusar } from './deliberacaoRecusar.js';
import { initDeliberacaoEncaminhar } from './deliberacaoEncaminhar.js';
import { Loader } from './loading.js';

const vectorSource = new VectorSource();
var mapView = new ol.View({
  zoom: 12,
  center: ol.proj.fromLonLat([-46.325733, -23.701631]),
});

var map = new ol.Map({
  target: 'map',
  view: mapView,
  controls: [new ScaleLine({ units: 'metric' })],
});

window.map = map;

// Adiciona os controles personalizados ao mapa
addZoomControls(map);
addHideMenuControls(map);

// Inicializa a funcionalidade de deliberação
document.addEventListener('DOMContentLoaded', function () {
  initDeliberacao();
});

fetch(
  'https://nominatim.openstreetmap.org/search.php?city=sao%20paolo&city=santo%20andre&polygon_geojson=1&format=geojson&limit=1'
)
  .then(function (response) {
    return response.json();
  })
  .then(function (json) {
    const features = new GeoJSON().readFeatures(json);
    const geometry = features[0].getGeometry();
    const originalGeometry = geometry.clone();
    const extent = originalGeometry.getExtent();
    geometry.transform('EPSG:4326', map.getView().getProjection());
    vectorSource.addFeature(features[0]);

    const search = new SearchNominatim({
      className: 'my-search-button',
      viewbox: extent,
      bounded: 1,
      placeholder: 'Busca...',
    });

    // Espera o controle ser adicionado ao DOM
    setTimeout(() => {
      const searchButton = document.querySelector('.my-search-button button'); // Seleciona o botão dentro do controle
      if (searchButton) {
        // Define o estilo do botão
        searchButton.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        searchButton.style.border = 'none';
        searchButton.style.width = '32px';
        searchButton.style.height = '25px';
        searchButton.innerHTML =
          '<img src="assets/lupa.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';

        // Remove o atributo title
        searchButton.removeAttribute('title');

        // Cria a tooltip e define o texto
        const tooltip = document.createElement('div');
        tooltip.className = 'custom-tooltip';
        tooltip.innerText = 'Buscar Endereço';
        document.body.appendChild(tooltip);

        // Adicionar eventos de mouse para hover
        searchButton.addEventListener('mouseover', function (e) {
          const rect = searchButton.getBoundingClientRect();
          tooltip.style.left = `${rect.right + 10}px`;
          tooltip.style.top = `${rect.top}px`;
          tooltip.classList.add('custom-tooltip-visible');
        });

        searchButton.addEventListener('mouseout', function () {
          tooltip.classList.remove('custom-tooltip-visible');
        });
      }
    });

    // Modificação para ajustar a exibição dos resultados
    search.handleResponse = function (response) {
      return response
        .map(function (entry) {
          // Divide o endereço em partes separadas por vírgulas
          let addressParts = entry.display_name.split(',');

          // Filtra as partes indesejadas que contenham "highway", "residential", etc.
          addressParts = addressParts.filter((part) => {
            // Remove partes que contêm termos indesejados ou que sejam excessivamente longas
            return (
              !/highway|residential|road|way|São Paulo|Santo André/i.test(
                part.trim()
              ) && part.trim().length > 0
            );
          });

          // Junta apenas as três primeiras partes do endereço filtrado
          const filteredAddress = addressParts.slice(0, 4).join(', ').trim();

          // Retorna o objeto modificado com o display_name filtrado
          return {
            ...entry,
            display_name: filteredAddress,
          };
        })
        .filter(function (entry) {
          const coordinate = [entry.lon, entry.lat].map(Number);
          return originalGeometry.intersectsCoordinate(coordinate);
        });
    };

    // Adiciona o evento ao selecionar um resultado
    search.on('select', function (e) {
      map.getView().animate({
        center: e.coordinate,
        zoom: Math.max(map.getView().getZoom(), 20),
      });
    });

    map.getView().fit(geometry);
    map.addControl(search);
  });

var osmTile = new ol.layer.Tile({
  title: 'Open Street Map',
  visible: true,
  source: new ol.source.OSM(),
});

map.addLayer(osmTile);

var imagemSateliteDataGeo = new ol.layer.Tile({
  title: 'DIGITALGLOBE_2017_2018',
  source: new ol.source.TileWMS({
    url: 'https://datageo.ambiente.sp.gov.br/geoimage/datageoimg/wms',
    params: {
      LAYERS: 'datageoimg:APRM_RMSP_DIGITALGLOBE_2017_2018',
      TILED: true,
    },
    serverType: 'geoserver',
  }),
  visible: false,
});

map.addLayer(imagemSateliteDataGeo);

var LimiteMunicipalPolygonTile = new ol.layer.Tile({
  title: 'Limite Municipal',
  source: new ol.source.TileWMS({
    url: 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms',
    //url: 'http://portalgeobim.com.br:8085/geoserver/geobim_db/wms',
    params: { LAYERS: 'siga:SIGA_LIM_MUNICIPAL', TILED: true },
    //params: { LAYERS: 'geobim_db:SIGA_LIM_MUNICIPALPolygon', TILED: true },
    serverType: 'geoserver',
    visible: true,
  }),
});

map.addLayer(LimiteMunicipalPolygonTile);

//Inicio Função para deixar Agrupados Visiveis
// Criação e configuração da camada Agrupados
var agrupadosLayer = new ol.layer.Tile({
  title: 'Agrupados',
  name: 'Agrupados',
  source: new ol.source.TileWMS({
    url: 'https://portalgeobim.com.br/geoserver/geobim_db/wms?service=WMS',
    params: { LAYERS: 'geobim_db:selecionado-agrupado', TILED: true },
    serverType: 'geoserver',
  }),
  visible: false, // Inicialmente invisível
});

// Adicione a camada ao mapa
map.addLayer(agrupadosLayer);

// Gerencie a camada
const agrupadosLayerManager = new AgrupadosLayerManager(map);
agrupadosLayerManager.layer = agrupadosLayer; // Vincula a camada gerenciada

// Adicione evento ao checkbox
document
  .getElementById('demandasAgrupadas-checkboxes')
  .addEventListener('change', (event) => {
    agrupadosLayerManager.setLayerVisibility(event.target.checked);
  });

// Listener para o evento de agrupamento realizado
document.addEventListener('agrupamentoRealizado', (event) => {
  agrupadosLayerManager.refreshLayer();
  agrupadosLayerManager.setLayerVisibility(true); // Garanta que o layer fique visível
});

//Fim Função para deixar Agrupados Visiveis

//Inicio Função para deixar Demanda Visiveis
var Demanda = new ol.layer.Tile({
  title: 'Demanda',
  name: 'Demanda',
  source: new ol.source.TileWMS({
    url: 'https://portalgeobim.com.br/geoserver/geobim_db/wms?service=WMS',
    params: { LAYERS: 'geobim_db:demandas', TILED: true },
    serverType: 'geoserver',
  }),
  visible: false,
});

map.addLayer(Demanda);

document.addEventListener('DOMContentLoaded', function () {
  new DemandaFormHandler(map);

  const checkbox = document.getElementById('novasDemandas');
  checkbox.addEventListener('change', function (event) {
    const isChecked = event.target.checked;
    Demanda.setVisible(isChecked);
  });
});

const LIMPAR_SECAO = 'limpar-seção';

function hierarquiaViariaLayerFactory(categoria) {
  return function hierarquiaViariaLayer() {
    const url = 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms';
    const params = {
      LAYERS: 'SIGA_MUR_EIXO_HIERARQUIZACAO',
      TILED: true,
      CQL_FILTER: "HIERARQUIZACAO_VIARIA='" + categoria + "'",
      serverType: 'geoserver',
    };

    const tileLayerHierarquiViaria = new ol.layer.Tile({
      title: categoria,
      source: new ol.source.TileWMS({
        url: url,
        params: params,
      }),
      visible: false,
    });
    return tileLayerHierarquiViaria;
  };
}

function logradouroLayerFactory(categoria) {
  return function logradouroLayer() {
    const url = 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms';
    const params = {
      LAYERS: 'SIGA_MUR_EIXO_HIERARQUIZACAO',
      TILED: true,
      CQL_FILTER: "TIPO_LOGRADOURO='" + categoria + "'",
      serverType: 'geoserver',
    };

    const tileLayerHierarquiViaria = new ol.layer.Tile({
      title: categoria,
      source: new ol.source.TileWMS({
        url: url,
        params: params,
      }),
      visible: false,
    });
    return tileLayerHierarquiViaria;
  };
}

function LimiteSetorGrupoFactory(grupo) {
  return function LimiteSetorGrupo() {
    const tileLayerSetorGrupoFactory = new ol.layer.Tile({
      title: 'Setor Tec Grupo ' + grupo,
      source: new ol.source.TileWMS({
        url: 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms',
        //url: 'http://portalgeobim.com.br:8085/geoserver/geobim_db/wms',
        params: {
          LAYERS: 'siga:MUR_SETORES_TECNICOS',
          //LAYERS: 'geobim_db:Limite_Setor_Tec_SMU',
          TILED: true,
          CQL_FILTER: 'SETOR=' + grupo,
          //CQL_FILTER: 'setor_tec=' + grupo,
        },
        serverType: 'geoserver',
      }),
      visible: false,
    });
    return tileLayerSetorGrupoFactory;
  };
}

function UsoSoloLayerFactory(categoria) {
  return function UsoSoloLayer() {
    const tileLayerSoloLayerFactory = new ol.layer.Tile({
      title: categoria,
      source: new ol.source.TileWMS({
        url: 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms',
        //url: 'http://portalgeobim.com.br:8085/geoserver/geobim_db/wms',
        params: {
          LAYERS: 'siga:SIGA_PLA_USO_SOLO_MZU_2020',
          //LAYERS: 'geobim_db:SIGA_PLA_USO_SOLO_MZU_2020',
          TILED: true,
          CQL_FILTER: "CATEGORIA='" + categoria + "'",
          //CQL_FILTER: "categoria='" + categoria + "'",
        },
        serverType: 'geoserver',
      }),
      visible: false,
    });
    return tileLayerSoloLayerFactory;
  };
}

const hierarquiViariaLayers = [];

const categoriasHierarquiViaria = [
  'Não Informado',
  'Rodovia',
  'Via Arterial Primaria',
  'Via Arterial Secundaria',
  'Via Coletora Primaria',
  'Via Coletora Secundaria',
  'Via Local',
  'Via Metropolitana',
];
categoriasHierarquiViaria.forEach((categoria) => {
  const layer = hierarquiaViariaLayerFactory(categoria)();
  hierarquiViariaLayers.push(layer);
  map.addLayer(layer);
});

const logradouroLayers = [];

const categoriaslogradouro = [
  'ALM',
  'AVN',
  'CAM',
  'EST',
  'JD',
  'LGO',
  'MAR',
  'PCA',
  'PRQ',
  'PSL',
  'PTE',
  'ROD',
  'RUA',
  'TRV',
  'VID',
  'VIE',
];
categoriaslogradouro.forEach((categoria) => {
  const layer = logradouroLayerFactory(categoria)();
  logradouroLayers.push(layer);
  map.addLayer(layer);
});

const setorLayers = [];
for (let i = 1; i <= 12; i++) {
  const layer = LimiteSetorGrupoFactory(i)();
  setorLayers.push(layer);
  map.addLayer(layer);
}

const usoSoloLayers = [];

const categoriasSolo = [
  'Comércio/Serviços',
  'Industrial',
  'Institucional',
  'Misto',
  'Residencial',
  'Infraestrutura',
  'Verde',
];
categoriasSolo.forEach((categoria) => {
  const layer = UsoSoloLayerFactory(categoria)();
  usoSoloLayers.push(layer);
  map.addLayer(layer);
});

document
  .getElementById('enviar-filtros-espaciais')
  .addEventListener('click', function () {
    const selectedLogradouro =
      document.getElementById('logradouro-select').value;
    console.log('Logradouro selecionada:', selectedLogradouro);

    logradouroLayers.forEach((layer) => {
      if (
        selectedLogradouro === LIMPAR_SECAO ||
        layer.get('title') !== selectedLogradouro
      ) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
        console.log(`Camada de Logradouro ${selectedLogradouro} visível`);
      }
    });

    const selectedHierarquiaViaria = document.getElementById(
      'hierarquiaViaria-select'
    ).value;
    console.log('Hierarquia Viaria selecionada:', selectedHierarquiaViaria);

    hierarquiViariaLayers.forEach((layer) => {
      if (
        selectedHierarquiaViaria === LIMPAR_SECAO ||
        layer.get('title') !== selectedHierarquiaViaria
      ) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
        console.log(
          `Camada de Hierarquia Viaria ${selectedHierarquiaViaria} visível`
        );
      }
    });

    const selectedSetor = document.getElementById('setor-select').value;
    console.log('Setor técnico selecionado:', selectedSetor);

    setorLayers.forEach((layer, index) => {
      if (
        selectedSetor === LIMPAR_SECAO ||
        (index + 1).toString() !== selectedSetor
      ) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
        console.log(`Camada do Setor ${selectedSetor} visível`);
      }
    });

    const selectedUsoSolo = document.getElementById('uso-select').value;
    console.log('Uso do solo selecionado:', selectedUsoSolo);

    usoSoloLayers.forEach((layer) => {
      if (
        selectedUsoSolo === LIMPAR_SECAO ||
        layer.get('title') !== selectedUsoSolo
      ) {
        layer.setVisible(false);
      } else {
        layer.setVisible(true);
        console.log(`Camada de Uso do Solo ${selectedUsoSolo} visível`);
      }
    });
  });

// -----------------------------------

// -----------------------------------

document.addEventListener('DOMContentLoaded', function () {
  // Adiciona event listeners a cada botão
  addToggleEvent('limiteSetorTecToggle', 'limiteSetorTecSubmenu');
  addToggleEvent('usoSoloToggle', 'usoSoloSubmenu');
  addToggleEvent('demandasToggle', 'demandasSubmenu');
  addToggleEvent('logradouroToggle', 'logradouroSubmenu');
  addToggleEvent('hierarquiaViariaToggle', 'hierarquiaViariaSubmenu');
  addToggleEvent('portfolioToggle', 'portfolioSubmenu');
});

function addToggleEvent(buttonId, submenuId) {
  const button = document.getElementById(buttonId);

  if (button) {
    button.addEventListener('click', function () {
      toggleSubmenu(submenuId);
    });
  }
}

document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
  checkbox.addEventListener('change', function (event) {
    let categoria = checkbox.name;

    if (checkbox.id !== 'todos') {
      const layer = map
        .getLayers()
        .getArray()
        .find(
          (layer) =>
            layer.get('title') && layer.get('title').includes(categoria)
        );

      if (layer) {
        layer.setVisible(checkbox.checked);
      }
    }
  });
});

// document.addEventListener('DOMContentLoaded', function () {
//   new DemandaFormHandler(map);
// });

var mousePosition = new ol.control.MousePosition({
  className: 'mousePosition',
  coordinateFormat: function (coordinate) {
    // Convertendo coordenadas para graus, minutos e segundos
    var lon = coordinate[0];
    var lat = coordinate[1];

    var latAbs = Math.abs(lat);
    var latDeg = Math.floor(latAbs);
    var latMin = Math.floor((latAbs - latDeg) * 60);
    var latSec = ((latAbs - latDeg - latMin / 60) * 3600).toFixed(2);
    var latHemi = lat >= 0 ? 'N' : 'S';

    var lonAbs = Math.abs(lon);
    var lonDeg = Math.floor(lonAbs);
    var lonMin = Math.floor((lonAbs - lonDeg) * 60);
    var lonSec = ((lonAbs - lonDeg - lonMin / 60) * 3600).toFixed(2);
    var lonHemi = lon >= 0 ? 'E' : 'W';

    // Construindo a string formatada
    var formattedCoordinates =
      latDeg +
      '° ' +
      latMin +
      '′ ' +
      latSec +
      '″ ' +
      latHemi +
      ' ' +
      lonDeg +
      '° ' +
      lonMin +
      '′ ' +
      lonSec +
      '″ ' +
      lonHemi;

    return formattedCoordinates;
  },
  projection: 'EPSG:4326',
  target: document.getElementById('mouse-position'),
  undefinedHTML: '&nbsp;',
});

map.addControl(mousePosition);

// start : home Control
var homeButton = document.createElement('button');
homeButton.innerHTML =
  '<img src="assets/icons8-home-64(1).png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"><img>';
homeButton.className = 'myButton';

var homeElement = document.createElement('div');
homeElement.className = 'homeButtonDiv';
homeElement.appendChild(homeButton);

var homeControl = new ol.control.Control({
  element: homeElement,
});

homeButton.addEventListener('click', () => {
  location.reload();
});

map.addControl(homeControl);

setTimeout(() => {
  if (homeButton) {
    // Cria a tooltip e define o texto
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Atualizar';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    homeButton.addEventListener('mouseover', function (e) {
      const rect = homeButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    homeButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
});

// start: full screen Control
var fsButton = document.createElement('button');
fsButton.innerHTML =
  '<img src="assets/icons8-full-screen-48.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"><img>';
fsButton.className = 'myButton';

var fsElement = document.createElement('div');
fsElement.className = 'fsButtonDiv';
fsElement.appendChild(fsButton);

var fsControl = new ol.control.Control({
  element: fsElement,
});

fsButton.addEventListener('click', () => {
  var mapEle = document.getElementById('map');
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement &&
    !document.msFullscreenElement
  ) {
    if (mapEle.requestFullscreen) {
      mapEle.requestFullscreen();
    } else if (mapEle.msRequestFullscreen) {
      mapEle.msRequestFullscreen();
    } else if (mapEle.mozRequestFullscreen) {
      mapEle.mozRequestFullscreen();
    } else if (mapEle.webkitRequestFullscreen) {
      mapEle.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
});

map.addControl(fsControl);

setTimeout(() => {
  if (fsButton) {
    // Cria a tooltip e define o texto
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Tela Cheia';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    fsButton.addEventListener('mouseover', function (e) {
      const rect = fsButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    fsButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
});

//Função para deixar os botões escuro
document.querySelectorAll('.status-btn, #enviar').forEach((button) => {
  button.addEventListener('click', function () {
    // Toggle da classe 'active' para adicionar/remover o estilo escuro
    this.classList.toggle('active');
  });
});

const urlTemplate =
  'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&outputFormat=application/json&srsname=EPSG:4326&CQL_FILTER=ID_COLAB={id}';

const demandaVisibilityManager = new DemandaVisibilityManager(map, urlTemplate);

// Função para tornar o modal de relatório movível
let bannedIds = [];

// Função para buscar os IDs banidos da API e armazená-los
async function fetchBannedIds() {
  const url = 'https://portalgeobim.com.br/portalpsa/api/agrupamento/ids';
  const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching agrupamento IDs:', error);
    return [];
  }
}

// Atualizar os IDs banidos antes de qualquer interação
async function updateBannedIds() {
  bannedIds = await fetchBannedIds();
}

// Estilo para feições selecionadas
const selectedStyle = new ol.style.Style({
  image: new ol.style.Circle({
    radius: 4,
    fill: new ol.style.Fill({
      color: '#FFFF00',
    }),
    stroke: new ol.style.Stroke({
      color: '#FFFF00',
      width: 2,
    }),
  }),
});

// Estilo para feições não selecionadas
const defaultStyle = new ol.style.Style({
  image: new ol.style.Circle({
    radius: 4,
    fill: new ol.style.Fill({
      color: '#ff0000',
    }),
    stroke: new ol.style.Stroke({
      color: '#000000',
      width: 1,
    }),
  }),
});

function createAndAddDemandasColabLayer(map, categoriaValues) {
  const categoriasDemandasColab = [
    3325, 13361, 3375, 13381, 3379, 3373, 3374, 3378, 3377, 3376, 3309, 3355,
    3318, 3356, 3313, 3314, 3357, 3327, 3322, 3323, 3321, 3326, 3324, 3320,
    3331, 3344, 3369, 3367, 12359, 13369, 12550, 3348, 12551, 3335, 13381,
    13402, 13361, 13360, 13501, 13498, 13394, 13493, 13373, 13355, 13364, 13491,
    13391, 13368, 13356, 13363, 13384, 13388, 13404, 13492, 13406, 13403, 13450,
    13396, 13374, 13502, 225226,
  ];

  let cqlFilter;
  if (bannedIds.length > 0) {
    const idsString = bannedIds.join(',');
    cqlFilter = `ID_CATEGORIA IN (${categoriaValues.join(
      ','
    )}) AND "ID_COLAB" NOT IN (${idsString}) AND ID_CATEGORIA IN (${categoriasDemandasColab.join(
      ','
    )})`;
  } else {
    cqlFilter = `ID_CATEGORIA IN (${categoriaValues.join(
      ','
    )}) AND ID_CATEGORIA IN (${categoriasDemandasColab.join(',')})`;
  }

  const tileLayerDemandas = new ol.layer.Vector({
    title: 'Demandas' + categoriaValues.join(','),
    source: new ol.source.Vector({
      format: new ol.format.GeoJSON(),
      url: function () {
        return (
          'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&' +
          'outputFormat=application/json&srsname=EPSG:4326&' +
          'CQL_FILTER=' +
          encodeURIComponent(cqlFilter)
        );
      },
      strategy: ol.loadingstrategy.bbox,
    }),
    style: function (feature) {
      return feature.get('selected') ? selectedStyle : defaultStyle;
    },
    visible: false,
  });

  map.addLayer(tileLayerDemandas);
}
// Atualize os IDs banidos antes de adicionar a camada
updateBannedIds().then(() => {
  // Supondo que 'map' seja o objeto do mapa OpenLayers e 'categoriaValues' sejam os valores das categorias
  createAndAddDemandasColabLayer(map, categoriaValues);
});

document
  .getElementById('enviar-filtros-espaciais')
  .addEventListener('click', async () => {
    await updateBannedIds(); // Atualizar os IDs banidos antes de qualquer ação

    const categoriaSelect = document.getElementById('categoria');
    const grupoSelect = document.getElementById('grupos');
    const categoria = categoriaSelect.value;
    const grupo = grupoSelect.value;

    if (grupo === 'limpar-seção') {
      // Oculte todos os layers relacionados
      map
        .getLayers()
        .getArray()
        .forEach((layer) => {
          if (layer.get('title') && layer.get('title').startsWith('Demandas')) {
            layer.setVisible(false);
          }
        });
      return;
    }

    if (categoria && grupo) {
      const selectedOption = grupoSelect.options[grupoSelect.selectedIndex];
      const categoriaValues = selectedOption
        .getAttribute('data-values')
        .split(',');

      createAndAddDemandasColabLayer(map, categoriaValues);
      map
        .getLayers()
        .getArray()
        .forEach((layer) => {
          if (layer.get('title') === 'Demandas' + categoriaValues.join(',')) {
            layer.setVisible(true); // Aqui os layers são tornados visíveis
          }
        });
    }
  });

var categoriaSelect = document.getElementById('categoria');
var gruposSelect = document.getElementById('grupos');

function updateGruposOptions(selectedCategory) {
  var options = gruposSelect.querySelectorAll('option');

  options.forEach(function (option) {
    if (
      option.className === selectedCategory ||
      option.value === '' ||
      option.value.startsWith('limpar-seção')
    ) {
      option.style.display = 'block';
    } else {
      option.style.display = 'none';
    }
  });

  // Reset selected option to the default hidden one
  gruposSelect.value = '';
}

updateGruposOptions(categoriaSelect.value);

categoriaSelect.addEventListener('change', function () {
  var selectedCategory = this.value;
  updateGruposOptions(selectedCategory);
});

gruposSelect.addEventListener('click', function (event) {
  if (gruposSelect.disabled) {
    alert('Por favor, selecione uma categoria primeiro.');
    event.preventDefault();
  }
});

// Array de categorias
const categoriasDemandasColab = [
  3325, 13361, 3375, 13381, 3379, 3373, 3374, 3378, 3377, 3376, 3309, 3355,
  3318, 3356, 3313, 3314, 3357, 3327, 3322, 3323, 3321, 3326, 3324, 3320, 3331,
  3344, 3369, 3367, 12359, 13369, 12550, 3348, 12551, 3335, 13381, 13402, 13361,
  13360, 13501, 13498, 13394, 13493, 13373, 13355, 13364, 13491, 13391, 13368,
  13356, 13363, 13384, 13388, 13404, 13492, 13406, 13403, 13450, 13396, 13374,
  13502, 225226,
];

// Buscar IDs de agrupamento e, em seguida, adicionar camadas
fetchBannedIds().then((ids) => {
  bannedIds = ids; // Atualiza a variável global
  categoriasDemandasColab.forEach((categoria) =>
    createAndAddDemandasColabLayer(map, [categoria])
  );
});

// Configuração do botão de informações de feature e popup
var popup = initializePopup(map, 'popup');

var featureInfoButton = document.createElement('button');
featureInfoButton.innerHTML =
  '<img src="assets/icons8-info-50.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
featureInfoButton.className = 'myButton';
featureInfoButton.id = 'featureInfoButton';

var featureInfoElement = document.createElement('div');
featureInfoElement.className = 'featureInfoDiv';
featureInfoElement.style.margin = '10px auto auto 10px';
featureInfoElement.appendChild(featureInfoButton);

var featureInfoControl = new ol.control.Control({
  element: featureInfoElement,
});

var featureInfoFlag = false;
featureInfoButton.addEventListener('click', () => {
  featureInfoButton.classList.toggle('clicked');
  featureInfoFlag = !featureInfoFlag;
});

map.addControl(featureInfoControl);

setTimeout(() => {
  if (featureInfoButton) {
    // Cria a tooltip e define o texto
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Informar';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    featureInfoButton.addEventListener('mouseover', function (e) {
      const rect = featureInfoButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    featureInfoButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
});

function closePopup(event) {
  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }

  if (popup.getPosition() !== undefined) {
    popup.setPosition(undefined);
    popup.getElement().innerHTML = '';
    console.log('Popup fechado.');
  }
}

// Inicialização da camada Demandas Colab Info
var ids = categoriasDemandasColab.join(',');

var DemandasColabInfoTile = new ol.layer.Tile({
  title: 'Demandas Colab Info',
  source: new ol.source.TileWMS({
    url: 'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms',
    //url: 'http://portalgeobim.com.br:8085/geoserver/geobim_db/wms',
    params: {
      LAYERS: 'siga:COLAB_DEMANDAS',
      //LAYERS: 'geobim_db:siga:TESTE_COLAB',
      TILED: true,
      CQL_FILTER: 'ID_CATEGORIA IN (' + ids + ')', // Filtrando por múltiplos IDs
    },
    serverType: 'geoserver',
  }),
  visible: false,
});

map.addLayer(DemandasColabInfoTile);

var categoriasVisiveis = {};

document.querySelectorAll('.checkbox-container input').forEach((checkbox) => {
  checkbox.addEventListener('change', function () {
    let categoria = parseInt(this.name);
    if (isNaN(categoria)) {
      return;
    }
    if (this.checked) {
      categoriasVisiveis[categoria] = true;
    } else {
      delete categoriasVisiveis[categoria];
    }
  });
});

const loader = new Loader('loader-popup');

map.on('singleclick', function (evt) {
  if (featureInfoFlag) {
    var resolution = map.getView().getResolution();

    var categoriasFiltro = categoriasDemandasColab.join("','");

    if (categoriasFiltro) {
      var cqlFilter = "ID_CATEGORIA IN ('" + categoriasFiltro + "')";

      var url = DemandasColabInfoTile.getSource().getFeatureInfoUrl(
        evt.coordinate,
        resolution,
        'EPSG:3857',
        {
          INFO_FORMAT: 'application/json',
          propertyName:
            'ID_COLAB,ID_CATEGORIA,DTA_CRIACAO,DSC_STATUS,DSC_ENDERECO,NOM_BAIRRO,DSC_CATEGORIA,DSC_EVENTO',
          CQL_FILTER: cqlFilter,
        }
      );

      if (url) {
        // Mostrar o loader
        loader.show();

        fetch(url)
          .then((response) => response.text())
          .then((text) => {
            try {
              const data = JSON.parse(text);
              displayPopup(popup, data, evt.coordinate, closePopup);
            } catch (error) {
              console.error('Erro ao processar a resposta:', error, text);
            }
          })
          .catch((error) => {
            console.error('Erro ao fazer a requisição:', error);
          })
          .finally(() => {
            // Ocultar o loader após o término do fetch
            loader.hide();
          });
      }
    }
  }
});

// Final checkbox + Select de Filtros Espaciais

function clearAllLayers() {
  const layersToHide = [
    ...logradouroLayers,
    ...setorLayers,
    ...usoSoloLayers,
    ...hierarquiViariaLayers,
    ...map
      .getLayers()
      .getArray()
      .filter(
        (layer) =>
          layer.get('title') && layer.get('title').startsWith('Demandas')
      ),
  ];

  layersToHide.forEach((layer) => {
    layer.setVisible(false);
  });

  console.log('All specified layers have been hidden.');
}

document
  .getElementById('limpar-cadastro-filtros-espaciais')
  .addEventListener('click', function () {
    console.log('Clear button clicked');
    clearAllLayers();
  });

var demandasPopup = new ol.Overlay({
  element: document.getElementById('demandas-popup'),
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});
map.addOverlay(demandasPopup);

//Inicializar o calendário
$(document).ready(function () {
  var datepickerOptions = {
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
    autoclose: true,
  };

  $('#dataInicio').datepicker(datepickerOptions);
  $('#dataFinal').datepicker(datepickerOptions);
});

document.addEventListener('DOMContentLoaded', () => {
  const statusManager = new StatusLayerManager(map);

  document.querySelectorAll('.status-btn').forEach((button) => {
    button.addEventListener('click', () => {
      const status = button.getAttribute('data-status');

      if (statusManager.activeStatus === status) {
        statusManager.setActiveStatus(null);
        button.classList.remove('active');
      } else {
        statusManager.setActiveStatus(status);

        // Remover a classe 'active' de todos os botões de status
        document.querySelectorAll('.status-btn').forEach((btn) => {
          btn.classList.remove('active');
        });

        // Adicionar a classe 'active' ao botão clicado
        button.classList.add('active');
      }

      console.log(`Status alterado para: ${statusManager.activeStatus}`);
    });
  });

  document.getElementById('enviar-cadastro').addEventListener('click', () => {
    const status = statusManager.activeStatus;
    const dataInicio = document.getElementById('dataInicio').value;
    const dataFim = document.getElementById('dataFinal').value;

    if (status && dataInicio && dataFim) {
      const dataInicioFormatada = formatarData(dataInicio, 'start');
      const dataFimFormatada = formatarData(dataFim, 'end');
      const cqlFilter = `(DTA_CRIACAO BETWEEN '${dataInicioFormatada}' AND '${dataFimFormatada}') AND (DSC_STATUS = '${status}')`;
      console.log(`Consulta CQL: ${cqlFilter}`);
      statusManager.updateLayerWithFilter(cqlFilter);
    } else {
      alert('Por favor, selecione um status e defina as datas.');
    }
  });

  document.getElementById('limpar-cadastro').addEventListener('click', () => {
    statusManager.clearAll();
    console.log('Consulta limpa e layers removidos.');

    // Remover a classe 'active' de todos os botões de status
    document.querySelectorAll('.status-btn').forEach((btn) => {
      btn.classList.remove('active');
    });
  });
});

function formatarData(data, tipo) {
  const [dia, mes, ano] = data.split('/');
  if (tipo === 'start') {
    return `${ano}-${mes}-${dia}T00:00:00Z`;
  } else if (tipo === 'end') {
    return `${ano}-${mes}-${dia}T23:59:59Z`;
  }
  return '';
}

// Filtro Espacial Movimentando
function makeElementDraggable(el) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  el.onmousedown = function (e) {
    // Verifica se o clique não é em um elemento interativo
    if (
      e.target.tagName !== 'BUTTON' &&
      e.target.tagName !== 'INPUT' &&
      e.target.tagName !== 'TEXTAREA' &&
      e.target.tagName !== 'SELECT' &&
      e.target.tagName !== 'SPAN' &&
      e.target.tagName !== 'IMG' &&
      e.target.className !== 'form-close-button-agrupamento'
    ) {
      e = e || window.event;
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }
  };

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    el.style.top = el.offsetTop - pos2 + 'px';
    el.style.left = el.offsetLeft - pos1 + 'px';
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

// Tornar o elemento #agrupamento arrastável
makeElementDraggable(document.getElementById('agrupamento'));

// Fim Filtro Espacial Movimentando

// Função de medição
const measureSource = new ol.source.Vector();

const measureVector = new ol.layer.Vector({
  source: measureSource,
  style: {
    'fill-color': 'rgba(255, 255, 255, 0.2)',
    'stroke-color': '#ffcc33',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': '#ffcc33',
  },
});

//Função controle de botoes do Filtro Espaciais
document.addEventListener('DOMContentLoaded', function () {
  const enviarBtn = document.getElementById('enviar-filtro-espacial');

  // enviarBtn.addEventListener('click', function () {
  //   console.log('Formulário enviado com sucesso!');
  //   // Adicione aqui sua lógica para processar/enviar os dados do formulário
  // });
});

let sketch;
let helpTooltipElement;
let helpTooltip;
let measureTooltipElement;
let measureTooltip;
let draw;
const continuePolygonMsg = 'Click to continue drawing the polygon';
const continueLineMsg = 'Click to continue drawing the line';

// Length Control
var lengthButton = document.createElement('button');
lengthButton.innerHTML =
  '<img src="assets/icons8-length-50.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"><img>';
lengthButton.className = 'myButton';
lengthButton.id = 'lengthButton';

var lengthElement = document.createElement('div');
lengthElement.className = 'lengthButtonDiv';
lengthElement.appendChild(lengthButton);

var lengthControl = new ol.control.Control({
  element: lengthElement,
});

var lengthFlag = false;
lengthButton.addEventListener('click', () => {
  //disableOtherInteraction("lengthButton");
  lengthButton.classList.toggle('clicked');
  lengthFlag = !lengthFlag;
  document.getElementById('map').style.cursor = 'default';

  if (lengthFlag) {
    map.removeInteraction(draw);
    addInteraction('LineString');
  } else {
    map.removeInteraction(draw);
    measureSource.clear();
    const elements = document.getElementsByClassName(
      'ol-tooltip ol-tooltip-static'
    );
    while (elements.length > 0) elements[0].remove();
  }
});

map.addControl(lengthControl);

setTimeout(() => {
  if (lengthButton) {
    // Cria a tooltip e define o texto
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Medir';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    lengthButton.addEventListener('mouseover', function (e) {
      const rect = lengthButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    lengthButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
});

// Area control
var areaButton = document.createElement('button');
areaButton.innerHTML =
  '<img src="assets/measuring.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"><img>';
areaButton.className = 'myButton';
areaButton.id = 'areaButton';

var areaElement = document.createElement('div');
areaElement.className = 'areaButtonDiv';
areaElement.appendChild(areaButton);

var areaControl = new ol.control.Control({
  element: areaElement,
});

var areaFlag = false;
areaButton.addEventListener('click', () => {
  // disableOtherInteractin('areaButton');
  areaButton.classList.toggle('clicked');
  areaFlag = !areaFlag;
  document.getElementById('map').style.cursor = 'default';

  if (areaFlag) {
    map.removeInteraction(draw);
    addInteraction('Polygon');
  } else {
    map.removeInteraction(draw);
    measureSource.clear();
    const elements = document.getElementsByClassName(
      'ol-tooltip ol-tooltip-static'
    );
    while (elements.length > 0) elements[0].remove();
  }
});

map.addControl(areaControl);

setTimeout(() => {
  if (areaButton) {
    // Cria a tooltip e define o texto
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Desenhar Polígono';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    areaButton.addEventListener('mouseover', function (e) {
      const rect = areaButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    areaButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
});

function addInteraction(intType) {
  map.removeInteraction(draw); // Remove qualquer interação de desenho existente

  draw = new ol.interaction.Draw({
    source: measureSource,
    type: intType,
    style: new ol.style.Style({
      fill: new ol.style.Fill({
        color: 'rgba(200, 200, 200, 0.6)',
      }),
      stroke: new ol.style.Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
      }),
      image: new ol.style.Circle({
        radius: 5,
        stroke: new ol.style.Stroke({
          color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new ol.style.Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
      }),
    }),
  });

  draw.on('drawstart', function (evt) {
    helpTooltipElement.style.display = 'none';

    sketch = evt.feature;

    /** @type {import("..src/ol/coordinate.js").Coordinate|undefined} */
    var tooltipCoord = evt.coordinate;

    //listener = sketch.getGeometry().on('change', function (evt)) {
    sketch.getGeometry().on('change', function (evt) {
      var geom = evt.target;
      var output;
      if (geom instanceof ol.geom.Polygon) {
        output = formatArea(geom);
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (geom instanceof ol.geom.LineString) {
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
      measureTooltipElement.innerHTML = output;
      measureTooltip.setPosition(tooltipCoord);
    });
  });

  draw.on('drawend', function () {
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    measureTooltip.setOffset([0, -7]);
    //unset sketch
    sketch = null;

    helpTooltipElement.style.display = 'none';
    measureTooltipElement = null;

    createMeasureTooltip();
  });

  map.addInteraction(draw);

  createMeasureTooltip();
  createHelpTooltip();

  var pointerMoveHandler = function (evt) {
    if (evt.dragging) {
      return;
    }
    var helpMsg = 'Click to start drawing';
    if (sketch) {
      var geom = sketch.getGeometry();
      if (geom instanceof ol.geom.Polygon) {
        helpMsg = continuePolygonMsg;
      } else if (geom instanceof ol.geom.LineString) {
        helpMsg = continueLineMsg;
      }
    }

    helpTooltipElement.innerHTML = helpMsg;
    helpTootip.setPosition(evt.coordinate);
  };

  map.on('pointermove', pointerMoveHandler);
}

function createMeasureTooltip() {
  if (measureTooltipElement) {
    measureTooltipElement.parentNode.removeChild(measureTooltipElement);
  }
  measureTooltipElement = document.createElement('div');
  measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
  measureTooltip = new ol.Overlay({
    element: measureTooltipElement,
    offset: [0, -15],
    positioning: 'bottom-center',
  });
  map.addOverlay(measureTooltip);
}

function createHelpTooltip() {
  if (helpTooltipElement) {
    helpTooltipElement.parentNode.removeChild(helpTooltipElement);
  }
  helpTooltipElement = document.createElement('div');
  helpTooltipElement.className = 'ol-tooltip hidden';
  helpTootip = new ol.Overlay({
    element: helpTooltipElement,
    offset: [15, 0],
    positioning: 'center-left',
  });
  map.addOverlay(helpTootip);
}

const formatLength = function (line) {
  const length = ol.sphere.getLength(line);
  let output;
  if (length > 1000) {
    output = Math.round((length / 1000) * 100) / 100 + ' km';
  } else {
    output = Math.round(length * 100) / 100 + ' m';
  }
  return output;
};

const formatArea = function (polygon) {
  const area = ol.sphere.getArea(polygon);
  let output;
  if (area > 1000000) {
    output = Math.round((area / 1000000) * 100) / 100 + ' km²';
  } else {
    output = Math.round(area * 100) / 100 + ' m²';
  }
  return output;
};

map.addLayer(measureVector);

document
  .getElementById('pedestre-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('pedestre-checkboxes');
    toggleCheckboxes('pedestre');
  });

document
  .getElementById('transport-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('transport-checkboxes');
    toggleCheckboxes('transport');
  });

document
  .getElementById('viasTransito-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('viasTransito-checkboxes');
    toggleCheckboxes('viasTransito');
  });

document
  .getElementById('aguaEsgoto-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('aguaEsgoto-checkboxes');
    toggleCheckboxes('aguaEsgoto');
  });

document
  .getElementById('limpezaEConservacao-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('limpezaEConservacao-checkboxes');
    toggleCheckboxes('limpezaEConservacao');
  });

document
  .getElementById('estabelecimentoIrregular-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('estabelecimentoIrregular-checkboxes');
    toggleCheckboxes('estabelecimentoIrregular');
  });

document
  .getElementById('urbanismo-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('urbanismo-checkboxes');
    toggleCheckboxes('urbanismo');
  });

document
  .getElementById('iluminacaoEnergia-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('iluminacaoEnergia-checkboxes');
    toggleCheckboxes('iluminacaoEnergia');
  });

document
  .getElementById('demandasAgrupadas-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('demandasAgrupadas-checkboxes');
    toggleCheckboxes('demandasAgrupadas');
  });

document
  .getElementById('novasDemandas-toggle')
  .addEventListener('click', function () {
    closeOtherMenus('novasDemandas-checkboxes');
    toggleCheckboxes('novasDemandas');
  });

function closeOtherMenus(exceptId) {
  var checkboxes = document.querySelectorAll('.checkbox-section');
  checkboxes.forEach(function (checkbox) {
    // if (checkbox.id !== exceptId) {
    //   checkbox.classList.remove("visible");
    var arrowId = checkbox.id.split('-')[0] + '-toggle';
    document.querySelector('#' + arrowId + ' .arrow').style.transform =
      'rotate(0deg)';
  });
}

function toggleCheckboxes(type) {
  var checkboxDiv = document.getElementById(type + '-checkboxes');
  if (!checkboxDiv) {
    //console.error('Elemento não encontrado:', type + '-checkboxes');
    return; // Sai da função se o elemento não existir
  }
  var arrow = document.querySelector('#' + type + '-toggle .arrow');

  if (checkboxDiv.classList.contains('visible')) {
    checkboxDiv.classList.remove('visible');
    arrow.style.transform = 'rotate(0deg)';
  } else {
    checkboxDiv.classList.add('visible');
    arrow.style.transform = 'rotate(90deg)';
  }
}

// nova funçao que agrupa

function toggleAllCheckboxes(sourceCheckboxId, targetCheckboxIds) {
  const sourceCheckbox = document.getElementById(sourceCheckboxId);
  const isChecked = sourceCheckbox.checked;

  targetCheckboxIds.forEach(function (checkboxId) {
    const checkbox = document.getElementById(checkboxId);
    checkbox.checked = isChecked; // Marca ou desmarca conforme o checkbox 'todos'

    // Disparar evento de atualização no mapa, se necessário
    checkbox.dispatchEvent(new Event('change'));
  });
}

document
  .getElementById('faixaDePedestreApagadaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('faixaDePedestreApagadaTodos', [
      'faixaPedestreApagadaColab',
      'faixaPedestreApagadaCdo',
    ]);
  });

document
  .getElementById('biceclatarioDanificadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('biceclatarioDanificadoTodos', [
      'biceclatarioDanificadoColab',
      'biceclatarioDanificadoCdo',
    ]);
  });

document
  .getElementById('pontoDeTravessiaIrregularTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('pontoDeTravessiaIrregularTodos', [
      'pontoDeTravessiaIrregularColab',
      'pontoDeTravessiaIrregularCdo',
    ]);
  });

document
  .getElementById('rampaDeAcessibilidadeIrregularOuInexistenteTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('rampaDeAcessibilidadeIrregularOuInexistenteTodos', [
      'rampaDeAcessibilidadeIrregularOuInexistenteColab',
    ]);
  });

document
  .getElementById('rampaDeAcessibilidadeIrregularTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('rampaDeAcessibilidadeIrregularTodos', [
      'rampaDeAcessibilidadeIrregularCdo',
    ]);
  });

document
  .getElementById('rampaDeAcessibilidadeTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('rampaDeAcessibilidadeTodos', [
      'rampaDeAcessibilidadeCdo',
    ]);
  });

document
  .getElementById('cicloviaCiclofaixaMalSinalizadaOuApagadaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('cicloviaCiclofaixaMalSinalizadaOuApagadaTodos', [
      'cicloviaCiclofaixaMalSinalizadaOuApagadaColab',
      'cicloviaCiclofaixaMalSinalizadaOuApagadaCdo',
    ]);
  });

document
  .getElementById('calcadaIrregularTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('calcadaIrregularTodos', [
      'calcadaIrregularColab',
      'calcadaIrregularCdo',
    ]);
  });

document
  .getElementById('calcadaInexistenteTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('calcadaInexistenteTodos', [
      'calcadaInexistenteColab',
      'calcadaInexistenteCdo',
    ]);
  });

document
  .getElementById('calcadaInexistenteIrregularTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('calcadaInexistenteIrregularTodos', [
      'calcadaInexistenteIrregularCdo',
    ]);
  });

document
  .getElementById('estacaoDanificadaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('estacaoDanificadaTodos', [
      'estacaoDanificadaColab',
      'estacaoDanificadaCdo',
    ]);
  });

document
  .getElementById('maCondutaMotoristaCobradorTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('maCondutaMotoristaCobradorTodos', [
      'maCondutaMotoristaCobradorColab',
      'maCondutaMotoristaCobradorCdo',
    ]);
  });

document
  .getElementById('onibusDanificadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('onibusDanificadoTodos', [
      'onibusDanificadoColab',
      'onibusDanificadoCdo',
    ]);
  });

document
  .getElementById('onibusForaHorarioRotaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('onibusForaHorarioRotaTodos', [
      'onibusForaHorarioRotaColab',
      'onibusForaHorarioRotaCdo',
    ]);
  });

document
  .getElementById('onibusTremMetrôSuperlotadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('onibusTremMetrôSuperlotadoTodos', [
      'onibusTremMetrôSuperlotadoColab',
    ]);
  });

document
  .getElementById('pontoOnibusDanificadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('pontoOnibusDanificadoTodos', [
      'pontoOnibusDanificadoColab',
      'pontoOnibusDanificadoCdo',
    ]);
  });

document
  .getElementById('pontoTransporteClandestinoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('pontoTransporteClandestinoTodos', [
      'pontoTransporteClandestinoColab',
      'pontoTransporteClandestinoCdo',
    ]);
  });

document
  .getElementById('bloqueioViaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('bloqueioViaTodos', [
      'bloqueioViaColab',
      'bloqueioViaCdo',
    ]);
  });

document
  .getElementById('buracoNasViasTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('buracoNasViasTodos', [
      'buracoNasViasColab',
      'buracoNasViasCdo',
    ]);
  });

document
  .getElementById('placaSinalizacaoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('placaSinalizacaoTodos', [
      'placaSinalizacaoColab',
      'placaSinalizacaoCdo',
    ]);
  });

document
  .getElementById('pontoInfracaoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('pontoInfracaoTodos', [
      'pontoInfracaoColab',
      'pontoInfracaoCdo',
    ]);
  });

document
  .getElementById('pontoAnimaisTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('pontoAnimaisTodos', [
      'pontoAnimaisColab',
      'pontoAnimaisCdo',
    ]);
  });

document
  .getElementById('semaforoQuebradoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('semaforoQuebradoTodos', [
      'semaforoQuebradoColab',
      'semaforoQuebradoCdo',
    ]);
  });

document
  .getElementById('veiculoAbandonadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('veiculoAbandonadoTodos', [
      'veiculoAbandonadoColab',
      'veiculoAbandonadoCdo',
    ]);
  });

document
  .getElementById('viaDeTerraComDesnivelTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('viaDeTerraComDesnivelTodos', [
      'viaDeTerraComDesnivelColab',
      'viaDeTerraComDesnivelCdo',
    ]);
  });

document
  .getElementById('bueiroSemTampaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('bueiroSemTampaTodos', [
      'bueiroSemTampaColab',
      'bueiroSemTampaCdo',
    ]);
  });

document
  .getElementById('ocupacaoIrregularDeAreaPublicaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('ocupacaoIrregularDeAreaPublicaTodos', [
      'ocupacaoIrregularDeAreaPublicaColab',
      'ocupacaoIrregularDeAreaPublicaCdo',
    ]);
  });

document
  .getElementById('entulhoNaCalcadaViaPublicaTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('entulhoNaCalcadaViaPublicaTodos', [
      'entulhoNaCalcadaViaPublicaColab',
      'entulhoNaCalcadaViaPublicaCdo',
    ]);
  });

document
  .getElementById('matoAltoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('matoAltoTodos', ['matoAltoColab', 'matoAltoCdo']);
  });

document
  .getElementById('quedaDeArvoresTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('quedaDeArvoresTodos', [
      'quedaDeArvoresColab',
      'quedaDeArvoresCdo',
    ]);
  });

document
  .getElementById('rampaDeAcessibilidadeTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('rampaDeAcessibilidadeTodos', [
      'rampaDeAcessibilidadeColab',
      'rampaDeAcessibilidadeCdo',
    ]);
  });

document
  .getElementById('equipamentoPublicoDanificadoTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('equipamentoPublicoDanificadoTodos', [
      'equipamentoPublicoDanificadoColab',
      'equipamentoPublicoDanificadoCdo',
    ]);
  });

document
  .getElementById('vielasEscadariasTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('vielasEscadariasTodos', [
      'vielasEscadariasColab',
      'vielasEscadariasCdo',
    ]);
  });

document
  .getElementById('lampadaApagadaANoiteTodos')
  .addEventListener('change', function () {
    toggleAllCheckboxes('lampadaApagadaANoiteTodos', [
      'lampadaApagadaANoiteColab',
      'lampadaApagadaANoiteCdo',
    ]);
  });

// Inicio Botão Priorização
document.addEventListener('DOMContentLoaded', async function () {
  const prioridadeButton = document.createElement('button');
  prioridadeButton.innerHTML =
    '<img src="assets/prioridade.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
  prioridadeButton.className = 'myButton';

  const prioridadeElement = document.createElement('div');
  prioridadeElement.className = 'prioridadeDiv';
  prioridadeElement.appendChild(prioridadeButton);

  const prioridadeControl = new ol.control.Control({
    element: prioridadeElement,
  });

  map.addControl(prioridadeControl);

  async function verificarPermissao(funcionalidade) {
    const roleMap = {
      'IT': 'TI', // Exemplo de mapeamento de roles
    };

    const userRoles = localStorage.getItem('userRoles'); // Obtém as roles do usuário como uma string

    if (!userRoles) {
      console.error('Roles do usuário não encontradas no localStorage.');
      return false;
    }

    try {
      const response = await fetch(
        `https://portalgeobim.com.br/portalpsa/api/permissoes/funcionalidade/${funcionalidade}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro ao verificar permissões: ${response.statusText}`);
      }

      let roles = await response.json();

      // Processa as roles permitidas para garantir que estejam corretamente divididas
      roles = roles.flatMap((role) => role.split(',')); // Divide strings como 'EN2,EN1,ED1' em ['EN2', 'EN1', 'ED1']

      // Converte as roles do usuário em um array e aplica o mapeamento
      const userRolesArray = userRoles
        .split(',')
        .map((role) => roleMap[role] || role);

      // Verifica se qualquer role do usuário está nas roles permitidas
      const hasPermission = roles.some((role) =>
        userRolesArray.includes(role.trim())
      );

      return hasPermission;
    } catch (error) {
      console.error(
        `Erro ao verificar permissões para ${funcionalidade}:`,
        error
      );
      return false;
    }
  }

  // Ação ao clicar no botão de priorização
  prioridadeButton.onclick = async function () {
    const hasPermission = await verificarPermissao('PRIORIZAR');

    if (!hasPermission) {
      alert(
        'Você não tem as permissões necessárias para realizar a ação de priorização.'
      );
      return;
    }

    // Chamada à API para executar a ação de priorização
    try {
      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      const response = await fetch(
        'https://portalgeobim.com.br/portalpsa/api/run-script',
        {
          method: 'GET', // ou 'POST', dependendo de como a API está configurada
          headers: {
            'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        console.log('Base atualizada com sucesso.');
      } else {
        console.error('Erro ao atualizar a base.');
      }
    } catch (error) {
      console.error('Erro ao tentar fazer a solicitação:', error);
    } finally {
      // Exibe o modal independentemente do sucesso da solicitação
      const prioridadeModal = document.getElementById(
        'modal-criar-prioridade-inicial'
      );
      prioridadeModal.style.display = 'block';
    }
  };

  // Adiciona a tooltip ao botão de Priorizar
  setTimeout(() => {
    if (prioridadeButton) {
      // Cria a tooltip e define o texto
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.innerText = 'Priorizar';
      document.body.appendChild(tooltip);

      // Adicionar eventos de mouse para hover
      prioridadeButton.addEventListener('mouseover', function (e) {
        const rect = prioridadeButton.getBoundingClientRect();
        tooltip.style.left = `${rect.right + 10}px`;
        tooltip.style.top = `${
          rect.top + rect.height / 2 - tooltip.offsetHeight / 2
        }px`;
        tooltip.classList.add('custom-tooltip-visible');
      });

      prioridadeButton.addEventListener('mouseout', function () {
        tooltip.classList.remove('custom-tooltip-visible');
      });
    }
  }, 0);

  map.addControl(prioridadeControl);

  // Evento de clique no botão de fechar do modal de prioridade
  var closeButton = document.querySelector(
    '#modal-criar-prioridade-inicial .close'
  );
  if (closeButton) {
    closeButton.addEventListener('click', function () {
      var prioridadeModal = document.getElementById(
        'modal-criar-prioridade-inicial'
      );
      prioridadeModal.style.display = 'none';
    });
  } else {
    console.error('Botão de fechar do modal de prioridade não encontrado.');
  }

  // Evento de clique no botão de fechar do modal de configuração de priorização
  var closeButtonConfigurar = document.querySelector(
    '#modal-configurar-priorizacao .close'
  );
  if (closeButtonConfigurar) {
    closeButtonConfigurar.addEventListener('click', function () {
      var modalConfigurarPriorizacao = document.getElementById(
        'modal-configurar-priorizacao'
      );
      modalConfigurarPriorizacao.style.display = 'none';
    });
  } else {
    console.error(
      'Botão de fechar do modal de configuração de priorização não encontrado.'
    );
  }

  // Evento de clique no botão de fechar do modal de relatório
  var closeButtonRelatorio = document.querySelector('.close-relatorio');
  if (closeButtonRelatorio) {
    closeButtonRelatorio.addEventListener('click', function () {
      var modalRelatorio = document.getElementById('modal-relatorio');
      modalRelatorio.style.display = 'none';
    });
  } else {
    console.error('Botão de fechar do modal de relatório não encontrado.');
  }

  // Inicializa a classe PrioridadeManager
  var prioridadeManager = new PrioridadeManager(
    'btn-confirmar-criar-prioridade-inicial',
    'modal-criar-prioridade-inicial',
    '.close',
    'modal-configurar-priorizacao',
    'prioridade-numero',
    'nome-prioridade',
    {
      setPrioridadeInfo: function (numeroUnico, nomePrioridade) {
        document.getElementById('prioridade-numero').innerText = numeroUnico;
        document.getElementById('nome-prioridade').value = nomePrioridade;
      },
      showRelatorio: function () {
        var modalConfigurarPriorizacao = document.getElementById(
          'modal-configurar-priorizacao'
        );
        var modalRelatorio = document.getElementById('modal-relatorio');

        modalConfigurarPriorizacao.style.display = 'none';
        modalRelatorio.style.display = 'block';
      },
    }
  );

  // Evento de clique no botão "Criar" no modal de configuração de priorização
  var savePriorizacaoButton = document.getElementById('save-priorizacao');
  if (savePriorizacaoButton) {
    savePriorizacaoButton.onclick = function () {
      prioridadeManager.modalManager.showRelatorio();
    };
  } else {
    console.error(
      'Botão "Criar" do modal de configuração de priorização não encontrado.'
    );
  }
});

// Fim Botão Priorização

// Inicio Botão Filtro Espacial

document.addEventListener('DOMContentLoaded', function () {
  // Configuração do botão de agrupamento para abrir/fechar o formulário
  var agrupamentoButton = document.createElement('button');
  agrupamentoButton.innerHTML =
    '<img src="assets/filtro.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
  agrupamentoButton.className = 'myButton';

  var agrupamentoElement = document.createElement('div');
  agrupamentoElement.className = 'agrupamentoDiv';
  agrupamentoElement.appendChild(agrupamentoButton);

  var agrupamentoControl = new ol.control.Control({
    element: agrupamentoElement,
  });

  agrupamentoButton.onclick = function () {
    var agrupamentoDiv = document.getElementById('agrupamento');
    agrupamentoDiv.classList.toggle('show');
  };

  map.addControl(agrupamentoControl);

  // Adiciona a tooltip ao botão de Análise Espacial
  setTimeout(() => {
    if (agrupamentoButton) {
      // Cria a tooltip e define o texto
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.innerText = 'Analisar';
      document.body.appendChild(tooltip);

      // Adicionar eventos de mouse para hover
      agrupamentoButton.addEventListener('mouseover', function (e) {
        const rect = agrupamentoButton.getBoundingClientRect();
        tooltip.style.left = `${rect.right + 10}px`;
        tooltip.style.top = `${
          rect.top + rect.height / 2 - tooltip.offsetHeight / 2
        }px`;
        tooltip.classList.add('custom-tooltip-visible');
      });

      agrupamentoButton.addEventListener('mouseout', function () {
        tooltip.classList.remove('custom-tooltip-visible');
      });
    }
  });

  // Evento de clique no botão de fechar do formulário
  var closeButton = document.querySelector('.form-close-button-agrupamento');
  if (closeButton) {
    closeButton.addEventListener('click', function () {
      var agrupamentoDiv = document.getElementById('agrupamento');
      agrupamentoDiv.classList.remove('show');
    });
  } else {
    console.error('Botão de fechar não encontrado.');
  }
});

const featureSelector = new FeatureSelector(map);

//Inicio Nova Demanda

//Fim Nova Demanda

document.addEventListener('DOMContentLoaded', () => {
  const modalManager = new ModalManager(
    'modal-criar-prioridade-inicial',
    '.close',
    'selecione-criterios',
    'modal-criterios',
    'modal-config-data',
    'modal-config-pesos-categorias',
    'modal-config-pesos-logradouro',
    'btn-confirmar-criar-prioridade-inicial',
    'modal-configurar-priorizacao',
    'prioridade-numero',
    'nome-prioridade',
    'modal-config-pesos-setoresTecnicos',
    'modal-relatorio',
    'modal-config-hierarquia-viaria' // Adicionei o ID do modal de Hierarquia Viária
  );

  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    language: 'pt-BR',
    autoclose: true,
  });

  function formatarData(data, tipo) {
    const [dia, mes, ano] = data.split('/');
    if (tipo === 'start') {
      return `${ano}-${mes}-${dia}T00:00:00Z`;
    } else if (tipo === 'end') {
      return `${ano}-${mes}-${dia}T23:59:59Z`;
    }
    return '';
  }

  const btnConfigurarPesos = document.getElementById('save-pesos');
  if (btnConfigurarPesos) {
    btnConfigurarPesos.addEventListener('click', () => {
      const dataInicialInput = document.getElementById('data-inicial').value;
      if (dataInicialInput) {
        const dataInicialFormatada = formatarData(dataInicialInput, 'start');
        modalManager.setDataInicial(dataInicialFormatada);
        modalManager.savePesos();
      } else {
        alert('Por favor, insira uma data inicial antes de salvar os pesos.');
      }
    });
  } else {
    console.error("Elemento com ID 'save-pesos' não encontrado.");
  }
});

var tileLayerDemandas = new ol.layer.Vector({
  title: 'Demandas',
  source: new ol.source.Vector({
    format: new ol.format.GeoJSON(),
    url: function () {
      return (
        'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&' +
        'version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&' +
        'outputFormat=application/json&srsname=EPSG:4326&' +
        'CQL_FILTER=' +
        encodeURIComponent('ID_COLAB=0') // Filtro inicial para não carregar nada
      );
    },
    strategy: ol.loadingstrategy.bbox,
  }),
  style: function (feature) {
    return feature.get('selected') ? selectedStyle : defaultStyle;
  },
  visible: false,
});

map.addLayer(tileLayerDemandas);

// Armazenar os IDs visíveis
var idsVisiveis = {};

function setLayerVisibility(idColab, visible) {
  if (visible) {
    idsVisiveis[idColab] = true;
  } else {
    delete idsVisiveis[idColab];
  }

  const ids = Object.keys(idsVisiveis);
  if (ids.length > 0) {
    const cqlFilter = ids.map((id) => `ID_COLAB='${id}'`).join(' OR ');
    const source = tileLayerDemandas.getSource();
    source.setUrl(
      'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&' +
        'version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&' +
        'outputFormat=application/json&srsname=EPSG:4326&' +
        'CQL_FILTER=' +
        encodeURIComponent(cqlFilter)
    );
    source.clear();
    source.refresh();
    tileLayerDemandas.setVisible(true);
  } else {
    tileLayerDemandas.setVisible(false);
  }
}

document.addEventListener('DOMContentLoaded', function () {
  // Tornando a função global para ser usada no DataTable
  window.setLayerVisibility = setLayerVisibility;

  const closeRelatorioButton = document.querySelector('.close-relatorio');
  if (closeRelatorioButton) {
    closeRelatorioButton.addEventListener('click', function () {
      const checkboxes = document.querySelectorAll(
        '#relatorio-table tbody .select-checkbox'
      );
      checkboxes.forEach((checkbox) => {
        const id = checkbox.getAttribute('data-id');
        window.setLayerVisibility(id, false);
      });
      const modalRelatorio = document.getElementById('modal-relatorio');
      modalRelatorio.style.display = 'none';
    });
  }
});

// Inicio Logout
export function checkAuthToken() {
  const token = localStorage.getItem('authToken');
  //console.log('Auth Token:', token); // Adicionando log para verificar o token
  return !!token;
}

export function handleLogout() {
  console.log('Logging out...'); // Adicionando log para depuração

  // Remover token do localStorage
  localStorage.removeItem('authToken');
  localStorage.removeItem('pkce_code_verifier'); // Adicionalmente, remova o código verificador

  // Redirecionar para a página de logout da Autodesk
  window.location.href = 'https://accounts.autodesk.com/Authentication/LogOut';
}
document.addEventListener('DOMContentLoaded', function () {
  // Seleciona o ícone de logout
  const logoutIcon = document.getElementById('logout-icon');

  if (logoutIcon) {
    // Criar a tooltip específica para o ícone de logout e definir o texto "Sair"
    const logoutTooltip = document.createElement('div');
    logoutTooltip.className = 'logout-tooltip';
    logoutTooltip.innerText = 'Sair';
    document.body.appendChild(logoutTooltip);

    console.log('Logout tooltip criada e anexada ao DOM');

    // Adicionar eventos de mouse para hover
    logoutIcon.addEventListener('mouseover', function () {
      const rect = logoutIcon.getBoundingClientRect();
      logoutTooltip.style.left = `${
        rect.left + rect.width / 2 - logoutTooltip.offsetWidth / 2
      }px`;
      logoutTooltip.style.top = `${rect.bottom + 5}px`; // Coloca a tooltip logo abaixo do ícone

      logoutTooltip.classList.add('logout-tooltip-visible');
      //console.log('Mouseover event: logoutTooltip deve estar visível agora');
    });

    logoutIcon.addEventListener('mouseout', function () {
      logoutTooltip.classList.remove('logout-tooltip-visible');
      //console.log('Mouseout event: logoutTooltip removida');
    });

    // Adicionar a função de logout ao clique
    logoutIcon.addEventListener('click', function () {
      //console.log('Logout icon clicked');
      handleLogout();
    });
  } else {
    console.error('Logout icon not found');
  }
});

// Fim Logout

// Inicio Salvamento Relatorio PDF

document
  .getElementById('btn-salvar-pdf')
  .addEventListener('click', saveTableAsPDF);

export function saveTableAsPDF() {
  console.log('saveTableAsPDF chamado'); // Log para verificar se a função está sendo chamada

  const doc = new jsPDF('p', 'mm', 'a4'); // Cria a instância do jsPDF
  const dataTable = $('#relatorio-table').DataTable();

  if (!dataTable) {
    console.error('DataTable não encontrado');
    return;
  }

  const data = dataTable.rows().data().toArray(); // Obtém todos os dados
  console.log('Dados da tabela obtidos:', data);

  // Obtém os valores dinâmicos do relatório
  const prioridadeElem = document.querySelector(
    '.priorizacao-block div:nth-child(1)'
  );
  const nomeElem = document.querySelector(
    '.priorizacao-block div:nth-child(2)'
  );

  if (!prioridadeElem || !nomeElem) {
    console.error('Um ou mais elementos não encontrados');
    return;
  }

  const numeroUnico = prioridadeElem.textContent.split(': ')[1].trim();
  const nomePrioridade = nomeElem.textContent.split(': ')[1].trim();

  // Obter data e hora atual
  const now = new Date();
  const dateStr = now.toLocaleDateString();
  const timeStr = now.toLocaleTimeString();

  // Adicionar os campos adicionais na primeira página
  doc.setFontSize(12);
  doc.text(`Prioridade: ${numeroUnico}`, 10, 10);
  doc.text(`Nome: ${nomePrioridade}`, 10, 16);
  doc.text('Legendas dos Critérios:', 10, 22);

  // Adicionar data e hora de geração do PDF
  doc.setFontSize(10);
  doc.text(`Gerado em: ${dateStr} às ${timeStr}`, 10, 28);

  addLegendsTable(doc); // Adiciona as legendas na primeira página

  doc.addPage();
  const columns = dataTable.settings().init().columns;
  const header = columns
    .filter((col) => col.title !== 'Selecionar')
    .map((col) => col.title);
  const rowsPerPage = 25; // Ajuste o número de linhas por página
  const totalRows = data.length;
  let currentPage = 1;

  function addTablePage(startRow) {
    const body = data.slice(startRow, startRow + rowsPerPage).map((row) => {
      return columns
        .filter((col) => col.title !== 'Selecionar')
        .map((col) => {
          if (typeof col.data === 'function') {
            return col.data(row);
          }
          return row[col.data];
        });
    });

    // Adiciona o conteúdo da tabela à página atual
    doc.autoTable({
      head: [header],
      body,
      startY: 10, // Ajustar o início da tabela
      margin: { top: 10 },
      styles: {
        fontSize: 10,
        halign: 'center', // Centraliza o texto nas células
      },
      pageBreak: 'auto',
      didDrawPage: function (data) {
        doc.text(
          `Página ${currentPage}`,
          10,
          doc.internal.pageSize.height - 10
        );
        currentPage++;
      },
    });

    // Verifica se há mais páginas
    if (startRow + rowsPerPage < totalRows) {
      doc.addPage();
      addTablePage(startRow + rowsPerPage);
    }
  }

  function addLegendsTable(doc) {
    const criteriosContent = document.getElementById('criterios-content');
    let startY = 35; // Posição Y inicial ajustada para evitar sobreposição com a data
    let startX = 10; // Posição X inicial
    let cellHeight = 10; // Altura da célula
    let cellWidth = 90; // Largura da célula

    const orderedCriterioBlocks = [
      'Categorias',
      'Hierarquia Viária',
      'Setores Técnicos',
      'Data',
      'Tipo de Logradouro',
    ];

    if (criteriosContent) {
      let blocks = {};
      criteriosContent.querySelectorAll('.criterio-block').forEach((block) => {
        const header = block.querySelector('.criterio-header strong').innerText;
        const contentItems = block.querySelectorAll('.criterio-content div');
        blocks[header] = Array.from(contentItems).map((item) =>
          item.innerText.trim()
        );
      });

      let tableData = [];

      orderedCriterioBlocks.forEach((key) => {
        if (blocks[key]) {
          // Adiciona cabeçalhos de grupo com preenchimento cinza
          tableData.push([
            { content: 'Critério', styles: { fillColor: [211, 211, 211] } },
            { content: 'Descrição', styles: { fillColor: [211, 211, 211] } },
          ]);

          blocks[key].forEach((item, index) => {
            tableData.push([key, item]);
          });
        }
      });

      // Cria a tabela de legendas no PDF
      doc.autoTable({
        head: [], // Cabeçalhos já estão incluídos em `tableData`
        body: tableData,
        startY: startY, // Posição Y inicial
        margin: { left: startX }, // Margem esquerda
        styles: { fontSize: 10 }, // Tamanho da fonte
        theme: 'grid', // Tema da tabela
        headStyles: { fillColor: [11, 178, 0] }, // Cor de fundo do cabeçalho
      });
    }
  }

  addTablePage(0); // Começa a adicionar a partir da primeira linha

  doc.save('relatorio.pdf');
  console.log('PDF salvo com sucesso'); // Log para verificar se o PDF foi salvo
}

// Fim Salvamento Relatorio PDF

//Buscação de demandas por ID
// Função para criar a camada de demandas WFS
function createDemandasLayerWFS(ids) {
  return new ol.layer.Vector({
    title: 'Demandas',
    source: new ol.source.Vector({
      format: new ol.format.GeoJSON(),
      url: function () {
        return (
          'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&' +
          'version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&' +
          'outputFormat=application/json&srsname=EPSG:4326&' +
          'CQL_FILTER=' +
          encodeURIComponent('ID_COLAB IN (' + ids + ')')
        );
      },
      strategy: ol.loadingstrategy.bbox,
    }),
    style: function (feature) {
      return feature.get('selected') ? selectedStyle : defaultStyle;
    },
    visible: true,
  });
}

var demandasLayer = null;

document
  .getElementById('buscar-demandas')
  .addEventListener('click', function () {
    var ids = document.getElementById('demandas-ids').value;
    console.log('Buscar button clicked with IDs: ', ids);
    if (demandasLayer) {
      map.removeLayer(demandasLayer);
    }
    demandasLayer = createDemandasLayerWFS(ids);
    map.addLayer(demandasLayer);
    console.log('WFS Layer added to map: ', demandasLayer);
  });

document
  .getElementById('limpar-demandas')
  .addEventListener('click', function () {
    console.log('Limpar button clicked');
    if (demandasLayer) {
      map.removeLayer(demandasLayer);
      demandasLayer = null;
      console.log('WFS Layer removed from map');
    }
    document.getElementById('demandas-ids').value = '';
  });

// Funções para buscar e limpar demandas
function buscarDemandas() {
  var ids = document
    .getElementById('demandas-ids')
    .value.split(',')
    .map((id) => id.trim());
  console.log('Buscar button clicked with IDs: ', ids);
  ids.forEach((id) => setLayerVisibility(id, true));
}

function limparDemandas() {
  console.log('Limpar button clicked');
  var ids = Object.keys(idsVisiveis);
  ids.forEach((id) => setLayerVisibility(id, false));
  document.getElementById('demandas-ids').value = '';
}

// Eventos de clique para os botões de buscar e limpar

document
  .getElementById('buscar-demandas')
  .addEventListener('click', buscarDemandas);
document
  .getElementById('limpar-demandas')
  .addEventListener('click', limparDemandas);

// Fim Buscador de Ids Demanda

// Inicio Deliberação

// Inicializa o conjunto de IDs dos layers ativos
// Inicializa o conjunto de IDs dos layers ativos
let activeIds = new Set();

// Função para criar camada WMS com filtro CQL dinâmico
function createWmsLayer(ids) {
  const filter = ids.map((id) => `id_categoria=${id}`).join(' OR ');
  return new ol.layer.Tile({
    source: new ol.source.TileWMS({
      url: 'https://portalgeobim.com.br/geoserver/geobim_db/wms',
      params: {
        LAYERS: 'geobim_db:deliberacao',
        TILED: true,
        CQL_FILTER: filter, // Filtro dinâmico para múltiplos IDs
      },
      serverType: 'geoserver',
    }),
    visible: false, // Inicialmente invisível
    zIndex: 1, // altera a ordem da camada deixa ela acima das demais
  });
}

// Inicializa a camada global com os IDs iniciais (vazia inicialmente)
window.wmsLayer = createWmsLayer([]);

// Adicionar a camada ao mapa
map.addLayer(window.wmsLayer);

// Função para atualizar o filtro da camada WMS
function updateWmsLayer(ids) {
  const filter = ids.map((id) => `id_categoria=${id}`).join(' OR ');
  window.wmsLayer.getSource().updateParams({
    CQL_FILTER: filter,
  });
}

// Função para lidar com a mudança de visibilidade dos checkboxes
function handleCheckboxChange(idArray, isChecked) {
  if (isChecked) {
    idArray.forEach((id) => activeIds.add(id));
  } else {
    idArray.forEach((id) => activeIds.delete(id));
  }
  const idsArray = Array.from(activeIds);
  updateWmsLayer(idsArray);
  window.wmsLayer.setVisible(idsArray.length > 0);
}

// Adicionar event listeners para os checkboxes
document
  .getElementById('toggleGeomLayer')
  .addEventListener('change', function (e) {
    const ids = [3373 /* outros IDs para Projeto Geométrico */];
    handleCheckboxChange(ids, e.target.checked);
  });

document
  .getElementById('toggleSinalLayer')
  .addEventListener('change', function (e) {
    const ids = [13493 /* outros IDs para Projeto de Sinalização */];
    handleCheckboxChange(ids, e.target.checked);
  });

// Criação do botão "Deliberar"
var deliberarButton = document.createElement('button');
deliberarButton.innerHTML =
  '<img src="assets/deliberar.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
deliberarButton.className = 'myButton';

var deliberarElement = document.createElement('div');
deliberarElement.className = 'deliberarDiv';
deliberarElement.appendChild(deliberarButton);

var deliberarControl = new ol.control.Control({
  element: deliberarElement,
});

map.addControl(deliberarControl);

// Função que verifica a permissão do usuário para uma funcionalidade específica
async function verificarPermissao(funcionalidade) {
  const userRoles = localStorage.getItem('userRoles'); // Obtém as roles como uma string separada por vírgulas

  if (!userRoles) {
    console.error('Roles do usuário não encontradas no localStorage.');
    return false;
  }

  // Mapeamento de roles backend -> frontend
  const roleMap = {
    'IT': 'TI', // Mapeia 'IT' para 'TI'
  };

  // Converte as roles do usuário usando o roleMap
  const userRolesArray = userRoles
    .split(',')
    .map((role) => roleMap[role] || role);

  try {
    const response = await fetch(
      `https://portalgeobim.com.br/portalpsa/api/permissoes/funcionalidade/${funcionalidade}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Erro ao verificar permissões: ${response.statusText}`);
    }

    let roles = await response.json();

    // Processa as roles permitidas para garantir que estejam corretamente divididas
    roles = roles.flatMap((role) => role.split(',')); // Divide qualquer string como 'EN2,EN1,ED1' em ['EN2', 'EN1', 'ED1']

    // Verifica se qualquer role do usuário está nas roles permitidas
    const hasPermission = roles.some((role) =>
      userRolesArray.includes(role.trim())
    );

    return hasPermission;
  } catch (error) {
    console.error(
      `Erro ao verificar permissões para ${funcionalidade}:`,
      error
    );
    return false;
  }
}

// Lógica de clique no botão "Deliberar" com verificação de roles e exibição de modal
deliberarButton.onclick = async function () {
  const hasPermission = await verificarPermissao('DELIBERAR');

  if (!hasPermission) {
    alert(
      'Você não tem as permissões necessárias para realizar a ação de Deliberar.'
    );
    return;
  }

  // Se o usuário tiver a role apropriada, exibe o modal de deliberação
  var modal = document.getElementById('deliberacao-modal');
  if (modal) {
    modal.style.display = 'block';
  } else {
    console.error('Modal de deliberação não encontrado.');
  }

  // Torna o modal arrastável
  window.makeDraggable(modal);
};

// Adiciona a tooltip ao botão de Deliberar
setTimeout(() => {
  if (deliberarButton) {
    // Cria a tooltip e define o texto
    var tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = 'Deliberar';
    document.body.appendChild(tooltip);

    // Adicionar eventos de mouse para hover
    deliberarButton.addEventListener('mouseover', function (e) {
      var rect = deliberarButton.getBoundingClientRect();
      tooltip.style.left = `${rect.right + 10}px`;
      tooltip.style.top = `${
        rect.top + rect.height / 2 - tooltip.offsetHeight / 2
      }px`;
      tooltip.classList.add('custom-tooltip-visible');
    });

    deliberarButton.addEventListener('mouseout', function () {
      tooltip.classList.remove('custom-tooltip-visible');
    });
  }
}, 0);

// Função para tornar o modal arrastável
window.makeDraggable = function (modal) {
  var offsetX = 0,
    offsetY = 0,
    initialX = 0,
    initialY = 0,
    isDragging = false;

  var header =
    modal.querySelector('.email-modal-header') || modal.querySelector('h2');

  header.addEventListener('mousedown', function (e) {
    e.preventDefault(); // Evitar seleção de texto
    isDragging = true;
    initialX = e.clientX;
    initialY = e.clientY;
    offsetX = modal.offsetLeft;
    offsetY = modal.offsetTop;
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    // Adiciona uma classe para desativar a seleção de texto
    document.body.classList.add('no-select');
  });

  function onMouseMove(e) {
    if (!isDragging) return;
    var deltaX = e.clientX - initialX;
    var deltaY = e.clientY - initialY;
    modal.style.left = `${offsetX + deltaX}px`;
    modal.style.top = `${offsetY + deltaY}px`;
  }

  function onMouseUp() {
    isDragging = false;
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);

    // Remove a classe que desativa a seleção de texto
    document.body.classList.remove('no-select');
  }
};

// Inicialização de funções
document.addEventListener('DOMContentLoaded', function () {
  console.log('DOM fully loaded and parsed');
  initDeliberacaoExecutar();
  initDeliberacaoRecusar();
  initDeliberacaoEncaminhar();
});

//Inicio Configuração do Painel de Permissões

document.addEventListener('DOMContentLoaded', () => {
  let permissoes = []; // Variável global para armazenar as permissões

  const userRoles = localStorage.getItem('userRoles');
  const configIcon = document.getElementById('config-icon');
  const configModal = document.getElementById('config-modal');
  const viewRolesModal = document.getElementById('view-roles-modal');
  const configForm = document.getElementById('config-form');
  const closeModalIcon = document.getElementById('close-config-modal');
  const closeViewRolesModalIcon = document.getElementById(
    'close-view-roles-modal'
  );
  const viewRolesButton = document.getElementById('view-roles');
  const deleteSelectedButton = document.getElementById('delete-selected'); // Botão para excluir selecionados

  // Mapeamento para formatar os nomes das funcionalidades
  const funcionalidadeMap = {
    'CADASTRAR_NOVA_DEMANDA': 'Cadastrar Nova Demanda',
    'AGRUPAR': 'Agrupar',
    'PRIORIZAR': 'Priorizar',
    'DELIBERAR': 'Deliberar',
  };

  // Função para formatar as funcionalidades
  function formatFuncionalidade(funcionalidade) {
    return funcionalidadeMap[funcionalidade] || funcionalidade;
  }

  // Mostrar o ícone de configuração se a role GE1 estiver presente
  if (configIcon && userRoles && userRoles.includes('GE1')) {
    configIcon.style.display = 'block';
  }

  // Função para fechar o modal de visualização de funções
  closeViewRolesModalIcon.addEventListener('click', () => {
    viewRolesModal.style.display = 'none';
  });

  // Garantir que o modal feche ao clicar fora dele
  window.addEventListener('click', (event) => {
    if (event.target === viewRolesModal) {
      viewRolesModal.style.display = 'none';
    }
  });

  // Abrir o modal de configuração
  if (configIcon && configModal && closeModalIcon && configForm) {
    configIcon.addEventListener('click', () => {
      configModal.style.display = 'block';
    });

    closeModalIcon.addEventListener('click', () => {
      configModal.style.display = 'none';
    });

    window.addEventListener('click', (event) => {
      if (event.target === configModal) {
        configModal.style.display = 'none';
      }
    });

    // Submeter nova permissão
    configForm.addEventListener('submit', async (e) => {
      e.preventDefault();

      const funcionalidade = document.getElementById('funcionalidade').value;
      const roleInput = document.getElementById('role');
      const role = roleInput.value.toUpperCase(); // Converter para caixa alta

      try {
        const response = await fetch(
          'https://portalgeobim.com.br/portalpsa/api/permissoes',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify({ funcionalidade, role }),
          }
        );

        if (response.ok) {
          alert('Permissão salva com sucesso!');
          await carregarPermissoes(); // Recarregar permissões após a atualização
        } else {
          alert('Erro ao salvar permissão.');
        }
      } catch (error) {
        console.error('Erro ao salvar permissão:', error);
        alert('Erro ao salvar permissão.');
      }
    });

    // Converter input para caixa alta enquanto digita
    const roleInput = document.getElementById('role');
    roleInput.addEventListener('input', function () {
      this.value = this.value.toUpperCase();
    });
  }

  // Função para tornar o modal movível
  function makeDraggable(modal) {
    const header = modal.querySelector('h3');
    let offsetX = 0,
      offsetY = 0,
      isDragging = false;

    header.addEventListener('mousedown', (e) => {
      e.preventDefault();
      isDragging = true;
      offsetX = e.clientX - modal.offsetLeft;
      offsetY = e.clientY - modal.offsetTop;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });

    function onMouseMove(e) {
      if (!isDragging) return;
      modal.style.left = `${e.clientX - offsetX}px`;
      modal.style.top = `${e.clientY - offsetY}px`;
    }

    function onMouseUp() {
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }
  }

  makeDraggable(configModal);
  makeDraggable(viewRolesModal);

  // Função para centralizar o modal ao abrir
  function centralizeModal(modal) {
    modal.style.left = '50%';
    modal.style.top = '50%';
    modal.style.transform = 'translate(-50%, -50%)';
  }

  // Abrir e fechar os modais
  viewRolesButton.addEventListener('click', async () => {
    centralizeModal(viewRolesModal);
    await carregarPermissoes();
    viewRolesModal.style.display = 'block';
  });

  closeConfigModalIcon.addEventListener('click', () => {
    configModal.style.display = 'none';
  });

  closeViewRolesModalIcon.addEventListener('click', () => {
    viewRolesModal.style.display = 'none';
  });

  // Função para carregar as roles no DataTable
  async function carregarPermissoes() {
    try {
      const response = await fetch(
        'https://portalgeobim.com.br/portalpsa/api/permissoes',
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      permissoes = await response.json(); // Armazena as permissões na variável global
      const table = $('#roles-table').DataTable({
        destroy: true, // Destroi a tabela existente antes de recriar para evitar erros
        language: {
          lengthMenu: 'Mostrar _MENU_ entradas',
          zeroRecords: 'Nenhum registro encontrado',
          info: 'Mostrando de _START_ até _END_ de _TOTAL_ entradas',
          infoEmpty: 'Mostrando 0 até 0 de 0 entradas',
          infoFiltered: '(filtrado de _MAX_ entradas totais)',
          search: 'Buscar:',
          paginate: {
            first: 'Primeiro',
            last: 'Último',
            next: 'Próximo',
            previous: 'Anterior',
          },
          emptyTable: 'Nenhuma Função Registrada',
          loadingRecords: 'Carregando...',
          processing: 'Processando...',
          searchPlaceholder: 'Buscar funcionalidade',
          decimal: ',',
          thousands: '.',
          aria: {
            sortAscending:
              ': ativar para classificar a coluna em ordem crescente',
            sortDescending:
              ': ativar para classificar a coluna em ordem decrescente',
          },
        },
      });
      table.clear(); // Limpar a tabela antes de carregar novas permissões

      permissoes.forEach((permissao) => {
        table.row
          .add([
            `<input type="checkbox" class="select-row" data-id="${permissao.id}">`, // Adiciona o checkbox para cada linha
            formatFuncionalidade(permissao.funcionalidade), // Coluna 1: Formata o nome da funcionalidade
            permissao.role, // Coluna 2: Role
            `<button class="edit-button" data-id="${permissao.id}">Editar</button>
             <button class="delete-button" data-id="${permissao.id}">Excluir</button>`, // Coluna 3: Ações
          ])
          .draw(false);
      });

      // Evento para selecionar todas as linhas
      $('#select-all')
        .off('click')
        .on('click', function () {
          const rows = table.rows({ search: 'applied' }).nodes();
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
          toggleDeleteSelectedButton();
        });

      // Evento para habilitar o botão de excluir selecionados quando checkboxes são selecionados
      $('#roles-table tbody')
        .off('change', 'input[type="checkbox"]')
        .on('change', 'input[type="checkbox"]', function () {
          if (!this.checked) {
            const el = $('#select-all').get(0);
            if (el && el.checked && 'indeterminate' in el) {
              el.indeterminate = true;
            }
          }
          toggleDeleteSelectedButton();
        });

      // Evento para excluir os selecionados
      deleteSelectedButton.addEventListener('click', async () => {
        const selectedIds = getSelectedIds();
        if (selectedIds.length === 0) {
          alert('Nenhuma permissão selecionada.');
          return;
        }

        const confirmExclusion = confirm(
          `Tem certeza que deseja excluir ${selectedIds.length} permissões?`
        );

        if (confirmExclusion) {
          loader.show(); // Exibe o loader antes de iniciar o processo

          try {
            let failedIds = []; // Array para armazenar IDs que falharam na exclusão

            // Usar Promise.all para realizar todas as requisições de exclusão em paralelo
            const deletePromises = selectedIds.map(async (id) => {
              const response = await fetch(
                `https://portalgeobim.com.br/portalpsa/api/permissoes/${id}`,
                {
                  method: 'DELETE',
                  headers: {
                    'Authorization': `Bearer ${localStorage.getItem(
                      'authToken'
                    )}`,
                  },
                }
              );

              if (!response.ok) {
                console.error(`Erro ao excluir permissão com ID ${id}`);
                failedIds.push(id); // Adiciona o ID ao array de falhas
              }
            });

            // Esperar todas as exclusões terminarem
            await Promise.all(deletePromises);

            if (failedIds.length === 0) {
              alert('Permissões excluídas com sucesso!');
            } else {
              alert(
                `Ocorreu um erro ao excluir as seguintes permissões: ${failedIds.join(
                  ', '
                )}`
              );
            }

            await carregarPermissoes(); // Recarregar permissões após a operação
          } catch (error) {
            console.error('Erro ao excluir permissões:', error);
            alert('Erro ao excluir permissões.');
          } finally {
            loader.hide(); // Esconde o loader após a conclusão
          }
        }
      });

      // Função para excluir uma permissão individual
      async function excluirPermissao(id) {
        if (confirm('Tem certeza que deseja excluir esta permissão?')) {
          try {
            const response = await fetch(
              `https://portalgeobim.com.br/portalpsa/api/permissoes/${id}`,
              {
                method: 'DELETE',
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem(
                    'authToken'
                  )}`,
                },
              }
            );

            if (response.ok) {
              alert('Permissão excluída com sucesso!');
              await carregarPermissoes(); // Recarregar permissões após a exclusão
            } else {
              alert('Erro ao excluir permissão.');
            }
          } catch (error) {
            console.error('Erro ao excluir permissão:', error);
            alert('Erro ao excluir permissão.');
          }
        }
      }

      // Adicionar eventos aos botões de exclusão
      $('#roles-table')
        .off('click', '.delete-button')
        .on('click', '.delete-button', function () {
          const id = $(this).data('id');
          excluirPermissao(id);
        });

      // Adicionar eventos aos botões de edição e exclusão
      $('#roles-table')
        .off('click', '.edit-button')
        .on('click', '.edit-button', function () {
          const id = $(this).data('id');
          editarPermissao(id, this); // Passe o botão clicado como referência para posicionamento
        });

      $('#roles-table')
        .off('click', '.delete-button')
        .on('click', '.delete-button', function () {
          const id = $(this).data('id');
          excluirPermissao(id);
        });
    } catch (error) {
      console.error('Erro ao carregar permissões:', error);
    }
  }

  // Função para obter os IDs das permissões selecionadas
  function getSelectedIds() {
    const selected = [];
    $('#roles-table tbody input[type="checkbox"]:checked').each(function () {
      selected.push($(this).data('id'));
    });
    return selected;
  }

  // Função para habilitar ou desabilitar o botão de excluir selecionados
  function toggleDeleteSelectedButton() {
    const selectedIds = getSelectedIds();
    deleteSelectedButton.disabled = selectedIds.length === 0;
  }

  // Função para editar permissão
  function editarPermissao(id, button) {
    const permissao = permissoes.find((p) => p.id === id); // Usando a variável global permissoes
    if (permissao) {
      const miniModal = document.getElementById('mini-edit-modal');
      const roleInput = document.getElementById('mini-edit-role');
      const modalOverlay = document.getElementById('modal-overlay'); // Adicionar o overlay

      // Preencher o campo de input com o valor atual de Role
      roleInput.value = permissao.role.toUpperCase(); // Garantir que seja sempre em caixa alta

      // Converter input para caixa alta enquanto digita
      roleInput.addEventListener('input', function () {
        this.value = this.value.toUpperCase();
      });

      // Centralizar o mini modal
      miniModal.style.top = '50%';
      miniModal.style.left = '50%';
      miniModal.style.transform = 'translate(-50%, -50%)';

      // Exibir o mini modal e o overlay
      miniModal.style.display = 'block';
      modalOverlay.style.display = 'block'; // Exibir o overlay

      // Exibir a funcionalidade no modal
      document.getElementById(
        'mini-funcionalidade'
      ).textContent = `Funcionalidade: ${formatFuncionalidade(
        permissao.funcionalidade
      )}`;

      // Função para fechar o mini modal ao clicar no ícone de fechar
      document
        .getElementById('mini-modal-close-icon')
        .addEventListener('click', () => {
          miniModal.style.display = 'none';
          modalOverlay.style.display = 'none'; // Também esconde o overlay
        });

      // Manipular o evento de submit para salvar as alterações
      document.getElementById('mini-edit-form').onsubmit = async function (e) {
        e.preventDefault();

        const novoRole = roleInput.value;

        try {
          const response = await fetch(
            `https://portalgeobim.com.br/portalpsa/api/permissoes/${id}`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
              body: JSON.stringify({
                funcionalidade: permissao.funcionalidade,
                role: novoRole,
              }),
            }
          );

          if (response.ok) {
            // Atualizar o texto na tabela
            button.closest('tr').querySelector('td:nth-child(2)').textContent =
              novoRole;

            // Fechar o mini modal
            miniModal.style.display = 'none';
            modalOverlay.style.display = 'none'; // Também esconde o overlay

            alert('Permissão atualizada com sucesso!');
            await carregarPermissoes(); // Recarregar permissões após a atualização
          } else {
            alert('Erro ao atualizar permissão.');
          }
        } catch (error) {
          console.error('Erro ao atualizar permissão:', error);
          alert('Erro ao atualizar permissão.');
        }
      };
    }
  }

  // Fechar o mini modal ao clicar no ícone de fechar
  document
    .getElementById('mini-modal-close-icon')
    .addEventListener('click', () => {
      document.getElementById('mini-edit-modal').style.display = 'none';
      document.getElementById('modal-overlay').style.display = 'none'; // Também esconde o overlay
    });

  // Inicializa o DataTable para o modal de visualização
  $(document).ready(function () {
    $('#roles-table').DataTable({
      destroy: true, // Destroi a tabela existente antes de recriar para evitar erros
      language: {
        lengthMenu: 'Mostrar _MENU_ entradas',
        zeroRecords: 'Nenhum registro encontrado',
        info: 'Mostrando de _START_ até _END_ de _TOTAL_ entradas',
        infoEmpty: 'Mostrando 0 até 0 de 0 entradas',
        infoFiltered: '(filtrado de _MAX_ entradas totais)',
        search: 'Buscar:',
        paginate: {
          first: 'Primeiro',
          last: 'Último',
          next: 'Próximo',
          previous: 'Anterior',
        },
        emptyTable: 'Nenhuma Função Registrada',
        loadingRecords: 'Carregando...',
        processing: 'Processando...',
        searchPlaceholder: 'Buscar funcionalidade',
        decimal: ',',
        thousands: '.',
        aria: {
          sortAscending:
            ': ativar para classificar a coluna em ordem crescente',
          sortDescending:
            ': ativar para classificar a coluna em ordem decrescente',
        },
      },
    });
  });

  carregarPermissoes(); // Carregar permissões ao iniciar
});

document.addEventListener('DOMContentLoaded', function () {
  const userRoles = localStorage.getItem('userRoles');
  const configIcon = document.getElementById('config-icon');

  // Exibe o ícone de configuração apenas se a role GE1 estiver presente
  if (configIcon && userRoles && userRoles.includes('GE1')) {
    configIcon.style.display = 'block';

    const configTooltip = document.createElement('div');
    configTooltip.className = 'config-tooltip';
    configTooltip.innerText = 'Configurações';
    document.body.appendChild(configTooltip);

    configIcon.addEventListener('mouseover', function () {
      const rect = configIcon.getBoundingClientRect();
      configTooltip.style.left = `${
        rect.left + rect.width / 2 - configTooltip.offsetWidth / 2
      }px`;
      configTooltip.style.top = `${rect.bottom + 10}px`; // Coloca a tooltip logo abaixo do ícone

      configTooltip.classList.add('config-tooltip-visible');
    });

    configIcon.addEventListener('mouseout', function () {
      configTooltip.classList.remove('config-tooltip-visible');
    });

    configIcon.addEventListener('click', function () {
      const configModal = document.getElementById('config-modal');
      configModal.style.display = 'block';
    });
  }
});

//Fim configuração de permissões

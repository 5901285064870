export class PrioridadeManager {
  constructor(
    criarPrioridadeButtonId,
    modalCriarPrioridadeId,
    closeClass,
    modalConfigurarPriorizacaoId,
    prioridadeNumeroSpanId,
    nomePrioridadeLabelId,
    modalManager // Passar uma referência para o ModalManager
  ) {
    this.criarPrioridadeButton = document.getElementById(
      criarPrioridadeButtonId
    );
    this.modalCriarPrioridade = document.getElementById(modalCriarPrioridadeId);
    this.modalConfigurarPriorizacao = document.getElementById(
      modalConfigurarPriorizacaoId
    );
    this.closeButton = this.modalCriarPrioridade
      ? this.modalCriarPrioridade.querySelector(closeClass)
      : null;
    this.formCriarPrioridade = document.getElementById(
      'form-criar-prioridade-inicial'
    );
    this.btnConfirmarCriarPrioridade = document.getElementById(
      'btn-confirmar-criar-prioridade-inicial'
    );
    this.nomePrioridadeInput = document.getElementById(
      'nome-prioridade-inicial'
    );
    this.prioridadeNumeroButton = document.getElementById(
      prioridadeNumeroSpanId
    );
    this.nomePrioridadeField = document.getElementById(nomePrioridadeLabelId);

    this.modalManager = modalManager; // Referência para o ModalManager

    this.priorizacaoNumero = 1;
    this.prioridadeNome = '';

    this.initEventListeners();
  }

  initEventListeners() {
    if (this.criarPrioridadeButton) {
      this.criarPrioridadeButton.onclick = () => {
        this.modalCriarPrioridade.style.display = 'block';
      };
    }

    if (this.closeButton) {
      this.closeButton.onclick = () => {
        this.modalCriarPrioridade.style.display = 'none';
      };
    }

    if (this.btnConfirmarCriarPrioridade) {
      this.btnConfirmarCriarPrioridade.onclick = (event) => {
        event.preventDefault(); // Evita o envio do formulário e o refresh da página
        this.handleCriarPrioridade();
      };
    }

    if (this.formCriarPrioridade) {
      this.formCriarPrioridade.onsubmit = (event) => {
        event.preventDefault(); // Evita o envio do formulário e o refresh da página
        this.handleCriarPrioridade();
      };
    }

    window.onclick = (event) => {
      if (event.target === this.modalCriarPrioridade) {
        this.modalCriarPrioridade.style.display = 'none';
      }
    };
  }

  handleCriarPrioridade() {
    const nomePrioridade = this.nomePrioridadeInput.value.trim();
    if (this.isNomeValido(nomePrioridade)) {
      const numeroUnico = this.gerarNumeroUnico();
      this.prioridadeNumeroButton.innerText = numeroUnico;
      this.nomePrioridadeField.value = nomePrioridade;
      this.priorizacaoNumero++;
      this.prioridadeNome = nomePrioridade;

      // Passa os valores para o ModalManager
      this.modalManager.setPrioridadeInfo(numeroUnico, nomePrioridade);

      this.modalCriarPrioridade.style.display = 'none';
      this.modalConfigurarPriorizacao.style.display = 'block';
    } else {
      alert(
        'Por favor, insira um nome válido para a prioridade. Apenas letras são permitidas.'
      );
    }
  }

  isNomeValido(nome) {
    const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/; // Regex para permitir apenas letras e espaços
    return regex.test(nome);
  }

  gerarNumeroUnico() {
    return String(this.priorizacaoNumero).padStart(4, '0'); // Gera um número com 4 dígitos, ex: 0001
  }
}

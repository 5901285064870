import Overlay from 'ol/Overlay';

export function initializePopup(map, popupId = 'executar-popup') {
  let element = document.getElementById(popupId);
  if (!element) {
    element = document.createElement('div');
    element.id = popupId;
    element.className = 'executar-popup';
    document.body.appendChild(element);
  }

  const popup = new Overlay({
    element: element,
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });

  map.addOverlay(popup);
  return popup;
}

export function displayPopupWithProjectId(
  popup,
  data,
  coordinate,
  closePopupCallback,
  saveCallback
) {
  if (data.features.length > 0) {
    const featureProps = data.features[0].properties;
    const popupContentElement = document.createElement('div');
    popupContentElement.className = 'popup-wrapper';

    const closeButton = document.createElement('img');
    closeButton.src = 'assets/preto-x.png';
    closeButton.style.cssText =
      'position: absolute; right: 10px; top: 10px; width: 20px; height: 20px; cursor: pointer;';
    closeButton.addEventListener('click', (event) => closePopupCallback(event));

    const title = document.createElement('div');
    title.textContent = 'Informação do Ativo';
    title.style.cssText =
      'text-align: center; font-weight: bold; margin-bottom: 10px;';

    const hr = document.createElement('hr');
    hr.style.cssText = 'border: 1px solid black; margin-bottom: 10px;';

    const contentTable = document.createElement('table');
    contentTable.className = 'popup-content';

    const fields = [
      {
        label: 'ID Projeto',
        value: `${featureProps.id_colab}${featureProps.id_categoria}`,
      },
      {
        label: 'CDE',
        value: `<a href="https://acc.autodesk.com/docs/files/projects/b0c69a76-9329-4a3d-b265-4b6c1a55b655?folderUrn=urn%3Aadsk.wipprod%3Afs.folder%3Aco.IuhSCcGOUCmvGrb7KJeiIQ&viewModel=detail&moduleId=folders" target="_blank">Acessar Documentação</a>`,
      },
      {
        label: 'Status',
        value: `${featureProps.dsc_status}`,
      },
      {
        label: 'Instrução',
        value: `<textarea id="dscInstrucao" ${
          featureProps.dsc_instrucao ? 'readonly' : ''
        }>${featureProps.dsc_instrucao || ''}</textarea>`,
      },
    ];

    fields.forEach((field) => {
      const row = contentTable.insertRow();
      const th = document.createElement('th');
      th.textContent = field.label;
      row.appendChild(th);
      const td = row.insertCell();
      td.innerHTML = field.value;
    });

    const saveButton = document.createElement('button');
    saveButton.textContent = 'Enviar';
    saveButton.style.cssText =
      'background-color: green; color: white; display: block; margin: 10px auto;'; // Estiliza o botão
    saveButton.style.display = featureProps.dsc_instrucao ? 'none' : 'block'; // Esconde o botão se já houver uma instrução salva
    saveButton.addEventListener('click', () => {
      const instrucao = document.getElementById('dscInstrucao').value;
      if (!featureProps.dsc_instrucao && saveCallback) {
        saveCallback(instrucao);
      } else {
        console.error(
          'saveCallback is not defined or instruction already saved'
        );
      }
    });

    popupContentElement.appendChild(closeButton);
    popupContentElement.appendChild(title);
    popupContentElement.appendChild(hr);
    popupContentElement.appendChild(contentTable);
    popupContentElement.appendChild(saveButton);

    popup.getElement().innerHTML = ''; // Clear any old content first
    popup.getElement().appendChild(popupContentElement);
    popup.setPosition(coordinate);
  } else {
    console.error('Nenhum recurso encontrado.');
  }
}

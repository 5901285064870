export class StatusLayerManager {
  constructor(map) {
    this.map = map;
    this.layers = {};
    this.activeStatus = null;
    this.selectedCategory = null;

    this.closePopup = this.closePopup.bind(this);
    this.map.on('singleclick', (evt) => this.handleMapClick(evt));

    this.ids = [
      3325, 13361, 3375, 13381, 3379, 3373, 3374, 3378, 3377, 3376, 3309, 3355,
      3318, 3356, 3313, 3314, 3357, 3327, 3322, 3323, 3321, 3326, 3324, 3320,
      3331, 3344, 3369, 3367, 12359, 13369, 12550, 3348, 12551, 3335, 13381,
      13402, 13361, 13360, 13501, 13498, 13394, 13493, 13373, 13355, 13364,
      13491, 13391, 13368, 13356, 13363, 13384, 13388, 13404, 13492, 13406,
      13403, 13450, 13396, 13374, 13502, 225226,
    ];
  }

  closePopup(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.popup.getPosition() !== undefined) {
      this.popup.setPosition(undefined);
      this.popup.getElement().innerHTML = '';
      console.log('Popup fechado.');
    }
  }

  setActiveStatus(status) {
    this.activeStatus = status;
  }

  updateLayerWithFilter(cqlFilter) {
    const layerKey =
      this.activeStatus +
      (this.selectedCategory ? `_${this.selectedCategory}` : '');

    if (this.layers[layerKey]) {
      this.map.removeLayer(this.layers[layerKey]);
      delete this.layers[layerKey];
    }

    const categoriasDemandasColab = this.ids;

    cqlFilter += ` AND ID_CATEGORIA IN (${categoriasDemandasColab.join(',')})`;

    const source = new ol.source.Vector({
      format: new ol.format.GeoJSON(),
      url: () => {
        return `https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wfs?service=WFS&version=1.1.0&request=GetFeature&typename=siga:COLAB_DEMANDAS&outputFormat=application/json&srsname=EPSG:4326&CQL_FILTER=${encodeURIComponent(
          cqlFilter
        )}`;
      },
      strategy: ol.loadingstrategy.bbox,
    });

    const layer = new ol.layer.Vector({
      source: source,
      style: (feature) =>
        feature.get('selected') ? selectedStyle : defaultStyle,
      visible: true,
    });

    this.map.addLayer(layer);
    this.layers[layerKey] = layer;
  }

  clearAll() {
    Object.values(this.layers).forEach((layer) => {
      this.map.removeLayer(layer);
    });
    this.layers = {};

    document.getElementById('dataInicio').value = '';
    document.getElementById('dataFinal').value = '';

    this.activeStatus = null;

    document.querySelectorAll('.status-btn').forEach((button) => {
      button.classList.remove('active');
    });

    console.log('Todos os layers removidos e campos de data limpos.');
  }

  handleMapClick(evt) {
    const viewResolution = this.map.getView().getResolution();
    Object.values(this.layers).forEach((layer) => {
      if (layer.getVisible()) {
        const url = layer
          .getSource()
          .getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
            INFO_FORMAT: 'application/json',
          });
        if (url) {
          fetch(url)
            .then((response) => response.json())
            .then((data) =>
              displayPopup(this.popup, data, evt.coordinate, this.closePopup)
            )
            .catch((error) => {
              console.error('Erro ao obter informações da feature:', error);
              this.closePopup();
            });
        }
      }
    });
  }
}

const selectedStyle = new ol.style.Style({
  image: new ol.style.Circle({
    radius: 4,
    fill: new ol.style.Fill({
      color: '#FFFF00',
    }),
    stroke: new ol.style.Stroke({
      color: '#FFFF00',
      width: 2,
    }),
  }),
});

const defaultStyle = new ol.style.Style({
  image: new ol.style.Circle({
    radius: 4,
    fill: new ol.style.Fill({
      color: '#ff0000',
    }),
    stroke: new ol.style.Stroke({
      color: '#000000',
      width: 1,
    }),
  }),
});

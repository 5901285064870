export function toggleSubmenu(submenuId) {
  var submenu = document.getElementById(submenuId);
  var submenus = document.getElementsByClassName('submenu');
  //função para ocultar o botão quando outro for aberto
  for (var i = 0; i < submenus.length; i++) {
    if (submenus[i] !== submenu) {
      submenus[i].style.display = 'none';
    }
  }
  submenu.style.display =
    submenu.style.display === 'none' || submenu.style.display === ''
      ? 'block'
      : 'none';
}

function monitorSubmenus(legendPopupId, submenuId) {
  var submenus = document.getElementsByClassName('submenu');
  var legendPopup = document.getElementById(legendPopupId);
  var targetSubmenu = document.getElementById(submenuId);
  var activeSubmenu = false; // Flag para verificar se algum submenu está ativo

  // Verifica todos os submenus para ver se algum está visível, exceto o submenu alvo
  for (var i = 0; i < submenus.length; i++) {
    if (
      submenus[i] !== targetSubmenu &&
      getComputedStyle(submenus[i]).display !== 'none'
    ) {
      activeSubmenu = true;
      break;
    }
  }

  // Se nenhum outro submenu estiver ativo, feche o popup de legendas
  if (!activeSubmenu) {
    legendPopup.style.display = 'none';
  }
}

// Função para ser chamada sempre que qualquer botão de submenu for clicado
document.querySelectorAll('.botaoEstilizado').forEach((button) => {
  button.addEventListener('click', function () {
    monitorSubmenus('legendPopup', 'DemandasSubmenu'); // IDs padrão
  });
});

// Função para ser chamada sempre que qualquer botão de submenu for clicado
document.querySelectorAll('.botaoEstilizado').forEach((button) => {
  button.addEventListener('click', function () {
    monitorSubmenus('legendPopup', 'limiteSetorTecSubmenu'); // IDs padrão
  });
});
// Função para ser chamada sempre que qualquer botão de submenu for clicado
document.querySelectorAll('.botaoEstilizado').forEach((button) => {
  button.addEventListener('click', function () {
    monitorSubmenus('hierarquiaViariaLegendPopup', 'hierarquiaViariaSubmenu'); // IDs padrão
  });
});

// Função para ser chamada sempre que qualquer botão de submenu for clicado
document.querySelectorAll('.botaoEstilizado').forEach((button) => {
  button.addEventListener('click', function () {
    monitorSubmenus('logradouroLegendPopup', 'logradouroSubmenu'); // IDs padrão
  });
});

// Função para ser chamada sempre que qualquer botão de submenu for clicado
document.querySelectorAll('.botaoEstilizado').forEach((button) => {
  button.addEventListener('click', function () {
    monitorSubmenus('usoSoloLegendPopup', 'usoSoloSubmenu'); // IDs padrão
  });
});

// Adicionar eventos de clique para botões específicos
document
  .getElementById('limiteSetorTecToggle')
  .addEventListener('click', function () {
    togglePopup('legendPopup', 'limiteSetorTecSubmenu');
  });

document
  .getElementById('hierarquiaViariaToggle')
  .addEventListener('click', function () {
    togglePopup('hierarquiaViariaLegendPopup', 'hierarquiaViariaSubmenu');
  });

document
  .getElementById('logradouroToggle')
  .addEventListener('click', function () {
    togglePopup('logradouroLegendPopup', 'logradouroSubmenu');
  });

document.getElementById('usoSoloToggle').addEventListener('click', function () {
  togglePopup('usoSoloLegendPopup', 'usoSoloSubmenu');
});

document
  .getElementById('portfolioToggle')
  .addEventListener('click', function () {
    togglePopup('portfolioLegendPopup', 'portfolioSubmenu');
  });

function togglePopup(legendPopupId, submenuId) {
  var popup = document.getElementById(legendPopupId);
  var submenu = document.getElementById(submenuId);

  // Alternar a visibilidade do popup baseado na visibilidade do submenu
  if (getComputedStyle(submenu).display === 'none') {
    popup.style.display = 'block';
  } else {
    popup.style.display = 'none';
  }
}

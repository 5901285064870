import { initializePopup, displayPopupWithProjectId } from './popupHelpers';

export function initDeliberacaoExecutar() {
  var closeButton = document.querySelector('.deliberacao-close-relatorio');
  if (closeButton) {
    closeButton.addEventListener('click', function () {
      var modal = document.getElementById('deliberacao-modal');
      modal.style.display = 'none';
    });
  } else {
    console.error('Botão de fechar não encontrado.');
  }

  var modal = document.getElementById('deliberacao-modal');
  window.makeDraggable(modal); // Utilize a função global

  let clickListener;

  document
    .getElementById('deliberacao-btn-executar')
    .addEventListener('click', function () {
      alert('Selecione a Demanda para Executar');
      modal.style.display = 'none';

      if (clickListener) {
        ol.Observable.unByKey(clickListener); // Remove previous listener if exists
      }

      clickListener = window.map.once('click', function (evt) {
        var feature = window.map.forEachFeatureAtPixel(
          evt.pixel,
          function (feature) {
            return feature;
          }
        );

        if (feature) {
          var properties = feature.getProperties();
          const coords = feature.getGeometry().getCoordinates();
          const newcoordinates = ol.proj.toLonLat(coords, 'EPSG:3857');
          const latitude = newcoordinates[1];
          const longitude = newcoordinates[0];

          // Adicione o link que deseja abrir em uma nova aba
          const projectLink =
            'https://acc.autodesk.com/docs/files/projects/b0c69a76-9329-4a3d-b265-4b6c1a55b655?folderUrn=urn%3Aadsk.wipprod%3Afs.folder%3Aco.IuhSCcGOUCmvGrb7KJeiIQ&viewModel=detail&moduleId=folders';

          // Abrir o link em uma nova aba
          window.open(projectLink, '_blank');

          const saveCallback = (instrucao) => {
            var requestData = {
              idColab: properties.ID_COLAB || null,
              idCategoria: properties.ID_CATEGORIA || null,
              dscCategoria: properties.DSC_CATEGORIA || null,
              dscEvento: properties.DSC_EVENTO
                ? properties.DSC_EVENTO.substring(0, 2000)
                : null,
              geom: `POINT(${longitude} ${latitude})`,
              numLat: latitude,
              numLon: longitude,
              nomBairro: properties.NOM_BAIRRO || null,
              dscEndereco: properties.DSC_ENDERECO || null,
              dscTipo: properties.DSC_TIPO || null,
              dscArea: properties.DSC_AREA || null,
              dtaCriacao: properties.DTA_CRIACAO || null,
              dtaAtualiza: properties.DTA_ATUALIZA || null,
              dtaImporta: properties.DTA_IMPORTA || null,
              dscInstrucao: instrucao || null,
            };

            const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

            fetch(
              'https://portalgeobim.com.br/portalpsa/api/deliberacoes/executar',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
                },
                body: JSON.stringify(requestData),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  return response.text().then((text) => {
                    throw new Error(text);
                  });
                }
                return response.json();
              })
              .then((responseData) => {
                alert('Demanda executada com sucesso.');

                const popup = initializePopup(window.map, 'executar-popup');
                const popupContent = {
                  features: [
                    {
                      properties: {
                        id_colab: properties.ID_COLAB,
                        id_categoria: properties.ID_CATEGORIA,
                        dsc_status: properties.DSC_STATUS,
                        DSC_STATUS: 'ABERTO',
                        dsc_instrucao: instrucao,
                      },
                    },
                  ],
                };

                displayPopupWithProjectId(popup, popupContent, coords, () => {
                  popup.setPosition(undefined);
                });
              })
              .catch((error) => {
                console.error('Erro ao executar a demanda:', error);
                alert(`Erro ao executar a demanda: ${error.message}`);

                const source = new ol.source.Vector({
                  features: [
                    new ol.Feature({
                      geometry: new ol.geom.Point(coords),
                      properties: properties,
                    }),
                  ],
                });
                const layer = new ol.layer.Vector({
                  source: source,
                  zIndex: 1000, // Defina um zIndex alto para esta camada
                });
                window.map.addLayer(layer);

                if (window.wmsLayer) {
                  window.wmsLayer.setVisible(true);
                  const toggleWmsLayerElement =
                    document.getElementById('toggleWmsLayer');
                  if (toggleWmsLayerElement) {
                    toggleWmsLayerElement.checked = true;
                  } else {
                    console.error('Elemento toggleWmsLayer não encontrado.');
                  }
                } else {
                  console.error('window.wmsLayer não está definida.');
                }

                ol.Observable.unByKey(clickListener);
              })
              .catch((error) => {
                alert(`Erro ao executar demanda: ${error.message}`);
                ol.Observable.unByKey(clickListener); // Remover mesmo em caso de erro
              });
          };

          const popup = initializePopup(window.map, 'executar-popup');
          const popupContent = {
            features: [
              {
                properties: {
                  id_colab: properties.ID_COLAB,
                  id_categoria: properties.ID_CATEGORIA,
                  dsc_status: properties.DSC_STATUS,
                  dsc_instrucao: properties.DSC_INSTRUCAO, // Certifique-se de que o campo correto está sendo usado
                },
              },
            ],
          };
          displayPopupWithProjectId(
            popup,
            popupContent,
            coords,
            () => {
              popup.setPosition(undefined);
            },
            saveCallback
          );
        } else {
          alert('Nenhum layer foi selecionado.');
          ol.Observable.unByKey(clickListener); // Remover listener se nenhum layer foi selecionado
        }
      });
    });

  window.map.on('singleclick', function (evt) {
    var viewResolution = window.map.getView().getResolution();
    if (window.wmsLayer) {
      var url = window.wmsLayer
        .getSource()
        .getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
          INFO_FORMAT: 'application/json',
        });

      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (data.features && data.features.length > 0) {
              const featureProps = data.features[0].properties;
              const coords = ol.proj.fromLonLat(
                [featureProps.longitude, featureProps.latitude],
                'EPSG:3857'
              );
              const popup = initializePopup(window.map, 'executar-popup');
              displayPopupWithProjectId(
                popup,
                data,
                evt.coordinate,
                () => {
                  popup.setPosition(undefined);
                },
                (instrucao) => {
                  var updatedData = {
                    ...featureProps,
                    dsc_instrucao: instrucao,
                  };

                  const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

                  fetch(
                    'https://portalgeobim.com.br/portalpsa/api/deliberacoes/executar',
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
                      },
                      body: JSON.stringify(updatedData),
                    }
                  )
                    .then((response) => {
                      if (!response.ok) {
                        return response.text().then((text) => {
                          throw new Error(text);
                        });
                      }
                      return response.json();
                    })
                    .then((data) => {
                      alert('Demanda executada com sucesso.');

                      featureProps.dsc_instrucao = instrucao;
                      const source = new ol.source.Vector({
                        features: [
                          new ol.Feature({
                            geometry: new ol.geom.Point(coords),
                            properties: featureProps,
                          }),
                        ],
                      });
                      const layer = new ol.layer.Vector({
                        source: source,
                        zIndex: 1000, // Defina um zIndex alto para esta camada
                      });
                      window.map.addLayer(layer);

                      if (window.wmsLayer) {
                        window.wmsLayer.setVisible(true);
                        const toggleWmsLayerElement =
                          document.getElementById('toggleWmsLayer');
                        if (toggleWmsLayerElement) {
                          toggleWmsLayerElement.checked = true;
                        } else {
                          console.error(
                            'Elemento toggleWmsLayer não encontrado.'
                          );
                        }
                      } else {
                        console.error('window.wmsLayer não está definida.');
                      }

                      ol.Observable.unByKey(clickListener);
                    })
                    .catch((error) => {
                      alert(`Erro ao executar demanda: ${error.message}`);
                      ol.Observable.unByKey(clickListener); // Remover mesmo em caso de erro
                    });
                }
              );
            }
          })
          .catch((error) => {
            console.error('Erro ao buscar informações do recurso:', error);
          });
      }
    } else {
      console.error('window.wmsLayer não está definida.');
    }
  });
}

export class GeoServerExtractor {
  constructor(url) {
    this.url = url;
  }

  // Mapeamento dos bairros para setores técnicos
  static setorTecnicoMap = {
    'Casa Branca': 1,
    'CENTRO': 1,
    'Centro': 1,
    'Jardim Bela Vista': 1,
    'JD. BELA VISTA': 1,
    'Vila Alice': 1,
    'VL. ALICE': 1,
    'Vila Alzira': 1,
    'VL. ALZIRA': 1,
    'Vila Assunção': 1,
    'VL. ASSUNCAO': 1,
    'Vila Bastos': 1,
    'VL. BASTOS': 1,
    'Campestre': 2,
    'CAMPESTRE': 2,
    'Jardim': 2,
    'JARDIM': 2,
    'Santa Maria': 2,
    'SANTA MARIA': 2,
    'Vila Alpina': 2,
    'VL. ALPINA': 2,
    'Vila Guiomar': 2,
    'VL. GUIOMAR': 2,
    'Jardim Bom Pastor': 3,
    'JD. BOM PASTOR': 3,
    'Pinheirinho': 3,
    'PINHEIRINHO': 3,
    'Vila Aquilino': 3,
    'VL. AQUILINO': 3,
    'Vila Floresta': 3,
    'VL. FLORESTA': 3,
    'Vila Gilda': 3,
    'VL. GILDA': 3,
    'Vila Palmares': 3,
    'VL. PALMARES': 3,
    'Vila Príncipe de Gales': 3,
    'VL. PRINCIPE DE GALES': 3,
    'Vila Sacadura Cabral': 3,
    'VL. SACADURA CABRAL': 3,
    'Vila Scarpelli': 3,
    'VL. SCARPELLI': 3,
    'Vila Valparaíso': 3,
    'VL. VALPARAISO': 3,
    'Centreville': 4,
    'CENTREVILLE': 4,
    'Cidade São Jorge': 4,
    'CIDADE SAO JORGE': 4,
    'Jardim Marek': 4,
    'JD. MAREK': 4,
    'Novo Homero Thon': 4,
    'NOVO HOMERO THON': 4,
    'Parque Marajoara': 4,
    'PQ. MARAJOARA': 4,
    'Vila América': 4,
    'VL. AMERICA': 4,
    'Vila Guarani': 4,
    'VL. GUARANI': 4,
    'Vila Homero Thon': 4,
    'VL. HOMERO THON': 4,
    'Vila Humaitá': 4,
    'VL. HUMAITA': 4,
    'Condomínio Maracanã': 5,
    'CONDOMINIO MARACANA': 5,
    'Jardim Ipanema': 5,
    'JD. IPANEMA': 5,
    'Parque Gerassi': 5,
    'PQ. GERASSI CENTREVILLE': 5,
    'Silveira': 5,
    'SILVEIRA': 5,
    'Vila Guaraciaba': 5,
    'VL. GUARACIABA': 5,
    'Vila Helena': 5,
    'VL. HELENA': 5,
    'Vila Junqueira': 5,
    'VL. JUNQUEIRA': 5,
    'Vila Lutécia': 5,
    'VL. LUTECIA': 5,
    'Vila Pires': 5,
    'VL. PIRES': 5,
    'Vila Progresso': 5,
    'VL. PROGRESSO': 5,
    'Vila Suíça': 5,
    'VL. SUICA': 5,
    'Vila Tibiriçá': 5,
    'VL. TIBIRICA': 5,
    'Vila Vitória': 5,
    'VL. VITORIA': 5,
    'Jardim Alvorada': 6,
    'JD. ALVORADA': 6,
    'Jardim Cristiane': 6,
    'JD. CRISTIANE': 6,
    'Jardim do Estádio': 6,
    'JD. DO ESTADIO': 6,
    'Jardim Jamaica': 6,
    'JD. JAMAICA': 6,
    'Jardim Las Vegas': 6,
    'JD. LAS VEGAS': 6,
    'Jardim Stella': 6,
    'JD. STELLA': 6,
    'Paraíso': 6,
    'PARAISO': 6,
    'Vila Linda': 6,
    'VL. LINDA': 6,
    'Jardim Ana Maria': 7,
    'JD. ANA MARIA': 7,
    'Jardim das Maravilhas': 7,
    'JD. DAS MARAVILHAS': 7,
    'Jardim Itapoan': 7,
    'JD. ITAPOAN': 7,
    'Jardim Santo Alberto': 7,
    'JD. SANTO ALBERTO': 7,
    'Jardim Santo Antônio': 7,
    'JD. SANTO ANTONIO': 7,
    'Jardim Utinga': 7,
    'JD. UTINGA': 7,
    'Parque Novo Oratório': 7,
    'PQ. NOVO ORATORIO': 7,
    'Parque Oratório': 7,
    'PQ. ORATORIO': 7,
    'Vila Francisco Matarazzo': 7,
    'VL. FRANCISCO MATARAZZO': 7,
    'Vila Lucinda': 7,
    'VL. LUCINDA': 7,
    'Cata Preta': 8,
    'CATA PRETA': 8,
    'Jardim Cipreste': 8,
    'JD. CIPRESTE': 8,
    'Jardim Guarará': 8,
    'JD. GUARARA': 8,
    'Jardim Irene': 8,
    'JD. IRENE': 8,
    'Jardim Santa Cristina': 8,
    'JD. SANTA CRISTINA': 8,
    'Jardim Santo André': 8,
    'JD. SANTO ANDRE': 8,
    'Jardim Santo André CDHU': 8,
    'JD. SANTO ANDRE CDHU': 8,
    'Jardim Telles de Menezes': 8,
    'JD. TELLES DE MENEZES': 8,
    'Jardim Vila Rica': 8,
    'JD. VILA RICA': 8,
    'Sítio dos Vianas': 8,
    'SITIO DOS VIANAS': 8,
    'Vila João Ramalho': 8,
    'VL. JOAO RAMALHO': 8,
    'Vila Luzita': 8,
    'VL. LUZITA': 8,
    'Santa Terezinha': 9,
    'SANTA TEREZINHA': 9,
    'Vila Camilópolis': 9,
    'VL. CAMILOPOLIS': 9,
    'Vila Metalúrgica': 9,
    'VL. METALURGICA': 9,
    'Bangú': 10,
    'BANGU': 10,
    'Parque das Nações': 10,
    'PQ. DAS NACOES': 10,
    'Parque Jaçatuba': 10,
    'PQ. JACATUBA': 10,
    'Várzea do Tamanduateí': 10,
    'VARZEA DO TAMANDUATEI': 10,
    'Vila Curuçá': 10,
    'VL. CURUCA': 10,
    'Jardim Alzira Franco': 11,
    'JD. ALZIRA FRANCO': 11,
    'Jardim Rina': 11,
    'JD. RINA': 11,
    'Parque Capuava': 11,
    'PQ. CAPUAVA': 11,
    'Parque Erasmo Assunção': 11,
    'PQ. ERASMO ASSUNCAO': 11,
    'Parque João Ramalho': 11,
    'PQ. JOAO RAMALHO': 11,
    'Polo Petroquímico de Capuava': 11,
    'POLO PETROQUIMICO DE CAPUAVA': 11,
    'Acampamento Anchieta': 12,
    'ACAMPAMENTO ANCHIETA': 12,
    'Araçaúva': 12,
    'ARACAUVA': 12,
    'Campo Grande': 12,
    'CAMPO GRANDE': 12,
    'Jardim Clube de Campo': 12,
    'JD. CLUBE DE CAMPO': 12,
    'Jardim Guaripocaba': 12,
    'JD. GUARIPOCABA': 12,
    'Jardim Joaquim Eugênio de Lima': 12,
    'JD. JOAQUIM EUGENIO DE LIMA': 12,
    'Miami Riviera': 12,
    'MIAMI RIVIERA': 12,
    'Paranapiacaba': 12,
    'PARANAPIACABA': 12,
    'Parque América': 12,
    'PQ. AMERICA': 12,
    'Parque das Garças': 12,
    'PQ. DAS GARCAS': 12,
    'Parque do Pedroso': 12,
    'PQ. DO PEDROSO': 12,
    'Parque Represa Billings II': 12,
    'PQ. REPRESA BILLINGS II': 12,
    'Parque Represa Billings III': 12,
    'PQ. REPRESA BILLINGS III': 12,
    'Parque Rio Grande': 12,
    'PQ. RIO GRANDE': 12,
    'Recreio da Borda do Campo': 12,
    'RECREIO DA BORDA DO CAMPO': 12,
    'Rio Bonito': 12,
    'RIO BONITO': 12,
    'Rio Grande': 12,
    'RIO GRANDE': 12,
    'Rio Mogi': 12,
    'RIO MOGI': 12,
    'Rio Pequeno': 12,
    'RIO PEQUENO': 12,
    'Sítio dos Teco': 12,
    'SITIO DOS TECO': 12,
    'Sítio Taquaral': 12,
    'SITIO TAQUARAL': 12,
    'Três Divisas': 12,
    'TRES DIVISAS': 12,
    'Waisberg': 12,
    'WAISBERG': 12,
    'Estância Rio Grande': 12,
    'ESTANCIA RIO GRANDE': 12,
  };

  async fetchGeoServerData() {
    try {
      const response = await fetch(this.url);
      const data = await response.json();
      return data.features.map((feature) => ({
        id: feature.properties.id_colab,
        dta_criacao: feature.properties.dta_criacao,
        logradouro: feature.properties.dsc_endereco,
        categoria: feature.properties.dsc_categoria,
        bairro: feature.properties.nom_bairro,
        hierarquiaViaria: feature.properties.hierarquia,
      }));
    } catch (error) {
      console.error('Erro ao buscar dados do GeoServer:', error);
      return [];
    }
  }

  filterAndApplyWeights(
    data,
    dataInicial,
    pesosData,
    pesosLogradouro,
    pesosCategorias,
    pesosSetoresTecnicos,
    pesosHierarquiaViaria,
    criteriosConfig
  ) {
    return data.map((item) => {
      const pesoData = dataInicial
        ? this.calculateDateWeight(item.dta_criacao, dataInicial, pesosData)
        : { value: 1, display: '-' };
      const pesoLogradouro = item.logradouro
        ? this.getPesoLogradouro(item.logradouro, pesosLogradouro)
        : { value: 1, display: '-' };
      const pesoCategoria = item.categoria
        ? this.getPesoCategoria(item.categoria, pesosCategorias)
        : { value: 1, display: '-' };
      const pesoSetorTecnico = item.bairro
        ? this.getPesoSetorTecnico(item.bairro, pesosSetoresTecnicos)
        : { value: 1, display: '-' };
      const pesoHierarquiaViaria = item.hierarquiaViaria
        ? this.getPesoHierarquiaViaria(
            item.hierarquiaViaria,
            pesosHierarquiaViaria
          )
        : { value: 1, display: '-' };

      const valorPriorizacao =
        pesoData.value *
        (criteriosConfig.data || 1) *
        (pesoLogradouro.value * (criteriosConfig.logradouro || 1)) *
        (pesoCategoria.value * (criteriosConfig.categoria || 1)) *
        (pesoSetorTecnico.value * (criteriosConfig.setoresTecnicos || 1)) *
        (pesoHierarquiaViaria.value * (criteriosConfig.hierarquiaViaria || 1));

      return {
        ...item,
        pesoData: pesoData.display,
        pesoLogradouro: pesoLogradouro.display,
        pesoCategoria: pesoCategoria.display,
        pesoSetorTecnico: pesoSetorTecnico.display,
        pesoHierarquiaViaria: pesoHierarquiaViaria.display,
        valorPriorizacao,
      };
    });
  }

  calculateDateWeight(dtaCriacao, dataInicial, pesosData) {
    const dateCriacao = new Date(dtaCriacao);
    const dateInicial = new Date(dataInicial);
    const diffTime = Math.abs(dateInicial - dateCriacao);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 1)
      return {
        value: pesosData['1dia'] || 1,
        display: pesosData['1dia'] || '-',
      };
    if (diffDays <= 7)
      return {
        value: pesosData['7dias'] || 1,
        display: pesosData['7dias'] || '-',
      };
    if (diffDays <= 15)
      return {
        value: pesosData['15dias'] || 1,
        display: pesosData['15dias'] || '-',
      };
    if (diffDays <= 30)
      return {
        value: pesosData['30dias'] || 1,
        display: pesosData['30dias'] || '-',
      };
    if (diffDays > 30)
      return {
        value: pesosData['mais30dias'] || 1,
        display: pesosData['mais30dias'] || '-',
      };
    return { value: 1, display: '-' };
  }

  getPesoLogradouro(logradouro, pesos) {
    const lowerLogradouro = logradouro.toLowerCase();
    for (const [key, value] of Object.entries(pesos)) {
      if (lowerLogradouro.startsWith(key.toLowerCase()) && value > 0) {
        return { value, display: value };
      }
    }
    return { value: 1, display: '-' };
  }

  getPesoCategoria(categoria, pesos) {
    const peso = pesos[categoria] || 1;
    return { value: peso, display: peso === 1 ? '-' : peso };
  }

  getPesoSetorTecnico(bairro, pesos) {
    const setor = GeoServerExtractor.setorTecnicoMap[bairro];
    const peso = setor ? pesos[`Setor ${setor}`] || 1 : 1;
    return { value: peso, display: peso === 1 ? '-' : peso };
  }

  getPesoHierarquiaViaria(hierarquia, pesos) {
    const hierarquiaLower = hierarquia.toLowerCase();
    for (const [key, value] of Object.entries(pesos)) {
      if (hierarquiaLower === key.toLowerCase() && value > 0) {
        return { value, display: value };
      }
    }
    return { value: 1, display: '-' };
  }
}

import { Control } from 'ol/control';

export function addZoomControls(map) {
  // Criando o botão de zoom +
  var zoomInButton = document.createElement('button');
  zoomInButton.innerHTML =
    '<img src="assets/mais-zoom.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
  zoomInButton.className = 'myButton';

  zoomInButton.addEventListener('click', function () {
    var view = map.getView();
    var zoom = view.getZoom();
    view.setZoom(zoom + 1);
  });

  var zoomInElement = document.createElement('div');
  zoomInElement.className = 'zoomInButtonDiv';
  zoomInElement.appendChild(zoomInButton);

  var zoomInControl = new Control({
    element: zoomInElement,
  });

  map.addControl(zoomInControl);

  // Adiciona a tooltip ao botão de zoom in
  setTimeout(() => {
    if (zoomInButton) {
      // Cria a tooltip e define o texto
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.innerText = 'Zoom In';
      document.body.appendChild(tooltip);

      // Adicionar eventos de mouse para hover
      zoomInButton.addEventListener('mouseover', function (e) {
        const rect = zoomInButton.getBoundingClientRect();
        tooltip.style.left = `${rect.right + 10}px`;
        tooltip.style.top = `${
          rect.top + rect.height / 2 - tooltip.offsetHeight / 2
        }px`;
        tooltip.classList.add('custom-tooltip-visible');
      });

      zoomInButton.addEventListener('mouseout', function () {
        tooltip.classList.remove('custom-tooltip-visible');
      });
    }
  });

  // Criando o botão de zoom -
  var zoomOutButton = document.createElement('button');
  zoomOutButton.innerHTML =
    '<img src="assets/reduzir-o-zoom.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
  zoomOutButton.className = 'myButton';

  zoomOutButton.addEventListener('click', function () {
    var view = map.getView();
    var zoom = view.getZoom();
    view.setZoom(zoom - 1); // Reduzindo o zoom
  });

  var zoomOutElement = document.createElement('div');
  zoomOutElement.className = 'zoomOutButtonDiv';
  zoomOutElement.appendChild(zoomOutButton);

  var zoomOutControl = new Control({
    element: zoomOutElement,
  });

  map.addControl(zoomOutControl);

  setTimeout(() => {
    if (zoomOutButton) {
      // Cria a tooltip e define o texto
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.innerText = 'Zoom Out';
      document.body.appendChild(tooltip);

      // Adicionar eventos de mouse para hover
      zoomOutButton.addEventListener('mouseover', function (e) {
        const rect = zoomOutButton.getBoundingClientRect();
        tooltip.style.left = `${rect.right + 10}px`;
        tooltip.style.top = `${
          rect.top + rect.height / 2 - tooltip.offsetHeight / 2
        }px`;
        tooltip.classList.add('custom-tooltip-visible');
      });

      zoomOutButton.addEventListener('mouseout', function () {
        tooltip.classList.remove('custom-tooltip-visible');
      });
    }
  });
}

import { PrioridadeManager } from './prioridadeManager.js';
import { GeoServerExtractor } from './geoServerExtractor.js';
import { Loader } from './loading';

// Função para verificar se a data inicial está configurada
function verificarDataInicial(dataInicial, criterios) {
  const criterioDataPresente =
    criterios.data && Object.values(criterios.data).some((peso) => peso > 0);

  if (criterioDataPresente && !dataInicial) {
    alert(
      'Por favor, configure uma data inicial antes de salvar os pesos de data.'
    );
    return false; // Indica que a verificação falhou
  }

  return true; // Indica que a verificação passou
}

// Função para exibir alertas
function showAlert(message) {
  const alertModal = document.getElementById('modalMessageManagerAlert');
  const alertMessage = document.getElementById('alert-message');
  const alertOkButton = document.getElementById('alert-ok');

  alertMessage.innerText = message;
  alertModal.style.display = 'block';

  alertOkButton.onclick = () => {
    alertModal.style.display = 'none';
  };
}

const CRITERIOS_MAP = {
  data: 'Data',
  logradouro: 'Tipo de Logradouro',
  setoresTecnicos: 'Setores Técnicos',
  categoria: 'Categorias',
  hierarquiaViaria: 'Hierarquia Viária',
};
// Classe ModalManager
export class ModalManager {
  constructor(
    modalId,
    closeClass,
    criteriosButtonId,
    criteriosModalId,
    configPesosModalId,
    configPesosModalCategoriasId,
    configPesosModalLogradouroId,
    criarPrioridadeButtonInicialId,
    modalConfigurarPriorizacaoId,
    prioridadeNumeroSpanId,
    nomePrioridadeLabelId,
    configPesosModalSetoresTecnicosId,
    modalRelatorioId,
    configPesosModalHierarquiaViariaId,
    demandaVisibilityManagerId
  ) {
    this.modal = document.getElementById(modalId);
    this.closeButton = this.modal ? this.modal.querySelector(closeClass) : null;
    this.criteriosButton = document.getElementById(criteriosButtonId);
    this.criteriosModal = document.getElementById(criteriosModalId);
    this.closeCriteriosButton = this.criteriosModal
      ? this.criteriosModal.querySelector(closeClass)
      : null;
    this.configPesosModal = document.getElementById(configPesosModalId);
    this.closeConfigPesosButton = this.configPesosModal
      ? this.configPesosModal.querySelector(closeClass)
      : null;
    this.configPesosModalCategorias = document.getElementById(
      configPesosModalCategoriasId
    );
    this.closeConfigPesosButtonCategorias = this.configPesosModalCategorias
      ? this.configPesosModalCategorias.querySelector(closeClass)
      : null;
    this.configPesosModalLogradouro = document.getElementById(
      configPesosModalLogradouroId
    );
    this.closeConfigPesosButtonLogradouro = this.configPesosModalLogradouro
      ? this.configPesosModalLogradouro.querySelector(closeClass)
      : null;
    this.configPesosModalSetoresTecnicos = document.getElementById(
      configPesosModalSetoresTecnicosId
    );
    this.closeConfigPesosButtonSetoresTecnicos = this
      .configPesosModalSetoresTecnicos
      ? this.configPesosModalSetoresTecnicos.querySelector(closeClass)
      : null;
    this.configPesosModalHierarquiaViaria = document.getElementById(
      configPesosModalHierarquiaViariaId
    );
    this.closeConfigPesosButtonHierarquiaViaria = this
      .configPesosModalHierarquiaViaria
      ? this.configPesosModalHierarquiaViaria.querySelector(closeClass)
      : null;

    this.savePesosButton = document.getElementById('save-pesos');
    this.savePesosButtonCategorias = document.getElementById(
      'save-pesos-categorias'
    );
    this.savePesosButtonLogradouro = document.getElementById(
      'save-pesos-logradouro'
    );
    this.savePesosButtonSetoresTecnicos = document.getElementById(
      'save-pesos-setoresTecnicos'
    );
    this.savePesosButtonHierarquiaViaria = document.getElementById(
      'save-peso-hierarquia-viaria'
    );
    this.savePriorizacaoButton = document.getElementById('save-priorizacao');
    this.modalConfigurarPriorizacao = document.getElementById(
      modalConfigurarPriorizacaoId
    );
    this.closeButtonConfigurarPriorizacao = this.modalConfigurarPriorizacao
      ? this.modalConfigurarPriorizacao.querySelector(closeClass)
      : null;
    this.modalRelatorio = document.getElementById(modalRelatorioId);
    this.closeRelatorioButton = this.modalRelatorio
      ? this.modalRelatorio.querySelector(closeClass)
      : null;

    this.pesosCriteriosButton = document.getElementById(
      'configurar-peso-criterio'
    );
    this.configPesosModalCriterios = document.getElementById(
      'modal-config-peso-criterio'
    );
    this.closeConfigPesosButtonCriterios = this.configPesosModalCriterios
      ? this.configPesosModalCriterios.querySelector(closeClass)
      : null;
    this.savePesosCriteriosButton =
      document.getElementById('save-peso-criterio');

    this.prioridadeManager = new PrioridadeManager(
      criarPrioridadeButtonInicialId,
      modalId,
      closeClass,
      modalConfigurarPriorizacaoId,
      prioridadeNumeroSpanId,
      nomePrioridadeLabelId,
      this
    );

    this.dataInicial = null;
    this.currentCriterio = null;
    this.criterios = {};
    this.loader = new Loader('loader-prioridade');
    this.geoServerExtractor = new GeoServerExtractor(
      'https://portalgeobim.com.br/geoserver/geobim_db/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=geobim_db:colab_hierarquia&outputFormat=application/json'
    );

    this.verifyElements();
    this.initEventListeners();

    this.prioridadeNumero = '';
    this.prioridadeNome = '';
    this.criterios = {
      categorias: this.getInitialPesoValues('categoria'),
      hierarquiaViaria: this.getInitialPesoValues('hierarquiaViaria'),
    };
    this.criteriosConfig = {}; // Adicione esta linha
    this.addCriterioToForm('categoria');
    console.log('ModalManager inicializado');
    this.initEditableCells();
    this.initClickableRows();
  }

  setPrioridadeInfo(numeroUnico, nomePrioridade) {
    document.getElementById('prioridade-numero').innerText = numeroUnico;
    document.getElementById('nome-prioridade').value = nomePrioridade;
  }
  // Define informações de prioridade
  setPrioridadeInfo(numero, nome) {
    this.prioridadeNumero = numero;
    this.prioridadeNome = nome;
  }

  // Verifica elementos do DOM
  verifyElements() {
    if (!this.modal) {
      console.error(`Elemento com ID modalId não encontrado.`);
    }
    if (!this.criteriosButton) {
      console.error(`Elemento com ID criteriosButtonId não encontrado.`);
    }
    if (!this.configPesosModalCriterios) {
      console.error(
        `Elemento com ID 'modal-config-peso-criterio' não encontrado.`
      );
    }
  }

  // Inicializa eventos
  initEventListeners() {
    this.initIncrementDecrementButtons(document);
    if (this.pesosCriteriosButton) {
      this.pesosCriteriosButton.addEventListener('click', () => {
        this.configPesosModalCriterios.style.display = 'block';
      });
    }
    if (this.closeConfigPesosButtonCriterios) {
      this.closeConfigPesosButtonCriterios.addEventListener('click', () => {
        this.configPesosModalCriterios.style.display = 'none';
      });
    }
    if (this.savePesosCriteriosButton) {
      this.savePesosCriteriosButton.addEventListener('click', () => {
        this.savePesosCriterios();
      });
    }

    if (this.criarButton) {
      this.criarButton.onclick = () => {
        this.modal.style.display = 'block';
      };
    }

    if (this.closeButton) {
      this.closeButton.onclick = () => {
        this.modal.style.display = 'none';
      };
    }

    if (this.criteriosButton) {
      this.criteriosButton.onclick = () => {
        this.criteriosModal.style.display = 'block';
      };
    }

    if (this.closeCriteriosButton) {
      this.closeCriteriosButton.onclick = () => {
        this.criteriosModal.style.display = 'none';
      };
    }

    if (this.closeConfigPesosButton) {
      this.closeConfigPesosButton.onclick = () => {
        this.configPesosModal.style.display = 'none';
      };
    }

    if (this.closeConfigPesosButtonCategorias) {
      this.closeConfigPesosButtonCategorias.onclick = () => {
        this.configPesosModalCategorias.style.display = 'none';
      };
    }

    if (this.closeConfigPesosButtonLogradouro) {
      this.closeConfigPesosButtonLogradouro.onclick = () => {
        this.configPesosModalLogradouro.style.display = 'none';
      };
    }

    if (this.closeButtonConfigurarPriorizacao) {
      this.closeButtonConfigurarPriorizacao.onclick = () => {
        this.modalConfigurarPriorizacao.style.display = 'none';
      };
    }

    if (this.closeConfigPesosButtonSetoresTecnicos) {
      this.closeConfigPesosButtonSetoresTecnicos.onclick = () => {
        this.configPesosModalSetoresTecnicos.style.display = 'none';
      };
    }
    if (this.closeConfigPesosButtonHierarquiaViaria) {
      this.closeConfigPesosButtonHierarquiaViaria.onclick = () => {
        this.configPesosModalHierarquiaViaria.style.display = 'none';
      };
    }

    if (this.closeRelatorioButton) {
      this.closeRelatorioButton.onclick = () => {
        this.modalRelatorio.style.display = 'none';
        this.resetCriterioSelection();
      };
    }

    if (this.savePesosButton) {
      this.savePesosButton.onclick = () => {
        this.savePesos();

        if (this.dataInicial) {
          this.configPesosModal.style.display = 'none';
        }
      };
    }

    if (this.savePesosButtonCategorias) {
      this.savePesosButtonCategorias.onclick = () => {
        this.savePesosCategorias();
      };
    }

    if (this.savePesosButtonLogradouro) {
      this.savePesosButtonLogradouro.onclick = () => {
        this.savePesosLogradouro();
      };
    }

    if (this.savePesosButtonSetoresTecnicos) {
      this.savePesosButtonSetoresTecnicos.onclick = () => {
        this.savePesosSetoresTecnicos();
      };
    }

    if (this.savePesosButtonHierarquiaViaria) {
      this.savePesosButtonHierarquiaViaria.onclick = () => {
        this.savePesosHierarquiaViaria();
      };
    }

    if (this.savePriorizacaoButton) {
      this.savePriorizacaoButton.onclick = () => {
        this.savePriorizacao();
        if (
          this.dataInicial ||
          !this.criterios['data'] ||
          !Object.values(this.criterios['data']).some((peso) => peso > 0)
        ) {
          this.showRelatorio();
        } else {
          alert(
            'Por favor, configure uma data inicial antes de gerar o relatório.'
          );
        }
      };
    }

    window.onclick = (event) => {
      if (event.target === this.modal) {
        this.modal.style.display = 'none';
      } else if (event.target === this.criteriosModal) {
        this.criteriosModal.style.display = 'none';
      } else if (event.target === this.configPesosModal) {
        this.configPesosModal.style.display = 'none';
      } else if (event.target === this.configPesosModalCategorias) {
        this.configPesosModalCategorias.style.display = 'none';
      } else if (event.target === this.modalConfigurarPriorizacao) {
        this.modalConfigurarPriorizacao.style.display = 'none';
      } else if (event.target === this.configPesosModalSetoresTecnicos) {
        this.configPesosModalSetoresTecnicos.style.display = 'none';
      } else if (event.target === this.configPesosModalHierarquiaViaria) {
        this.configPesosModalHierarquiaViaria.style.display = 'none';
      } else if (event.target === this.modalRelatorio) {
        this.modalRelatorio.style.display = 'none';
      }
    };

    this.initAddCriterioButtons();
    this.initIncrementDecrementButtons(document);

    document
      .getElementById('data-inicial')
      .addEventListener('change', (event) => {
        const dataSelecionada = event.target.value;
        if (dataSelecionada) {
          const dataInicialFormatada = this.formatarData(
            dataSelecionada,
            'start'
          );
          this.setDataInicial(dataInicialFormatada);
        } else {
          this.setDataInicial(null);
        }
        this.savePesos();
      });

    this.makeModalDraggable(this.modalRelatorio);
  }

  // Definição da função formatarData
  formatarData(data, tipo) {
    const dataObj = new Date(data);
    if (tipo === 'start') {
      dataObj.setHours(0, 0, 0, 0);
    } else if (tipo === 'end') {
      dataObj.setHours(23, 59, 59, 999);
    }
    return dataObj.toISOString();
  }

  // Definição da função initIncrementDecrementButtons
  initIncrementDecrementButtons(parentElement) {
    const incrementButtons = parentElement.querySelectorAll('.increment');
    const decrementButtons = parentElement.querySelectorAll('.decrement');

    incrementButtons.forEach((button) => {
      button.onclick = () => {
        const target = document.getElementById(button.dataset.target);
        let value = parseInt(target.innerText, 10);
        if (value < 10) {
          target.innerText = value + 1;
        } else {
          alert('O peso máximo permitido é 10.');
        }
      };
    });

    decrementButtons.forEach((button) => {
      button.onclick = () => {
        const target = document.getElementById(button.dataset.target);
        const value = parseInt(target.innerText, 10);
        target.innerText = Math.max(0, value - 1); // mínimo de 1
      };
    });
  }

  // Função para obter o valor máximo permitido para um critério específico
  getMaxValueForCriterio(criterio) {
    if (criterio === 'data') return 10;
    if (criterio === 'categoria') return 10;
    if (criterio === 'logradouro') return 10;
    if (criterio === 'setoresTecnicos') return 10;
    if (criterio === 'hierarquiaViaria') return 10;
    return Infinity;
  }
  // Definição da função initAddCriterioButtons
  initAddCriterioButtons() {
    const addButtons = document.querySelectorAll('.add-criterio');

    addButtons.forEach((button) => {
      button.onclick = (event) => {
        event.stopPropagation();
        const criterio = button.dataset.criterio;
        this.addCriterioToForm(criterio);
        button.parentElement.style.display = 'none';
      };
    });
  }

  addCriterioToForm(criterio) {
    const selectedCriterios = document.getElementById('selected-criterios');

    if (!selectedCriterios.querySelector(`#selected-${criterio}`)) {
      const item = document.createElement('button');
      item.id = `selected-${criterio}`;
      item.className = 'selected-criterio';
      item.innerText =
        CRITERIOS_MAP[criterio] ||
        criterio.charAt(0).toUpperCase() + criterio.slice(1);

      if (criterio !== 'categoria') {
        const removeIcon = document.createElement('img');
        removeIcon.src = 'assets/cancelar.png';
        removeIcon.className = 'remove-criterio';
        item.appendChild(removeIcon);

        item.onclick = (event) => {
          if (event.target.classList.contains('remove-criterio')) {
            this.removeCriterioFromForm(criterio);
          } else {
            event.preventDefault();
            this.currentCriterio = criterio;
            this.openConfigPesosModal();
          }
        };
      } else {
        item.onclick = (event) => {
          event.preventDefault();
          this.currentCriterio = criterio;
          this.openConfigPesosModal();
        };
      }

      selectedCriterios.appendChild(item);
      this.criterios[criterio] = this.getInitialPesoValues(criterio);
    }
  }

  removeCriterioFromForm(criterio) {
    const item = document.getElementById(`selected-${criterio}`);
    if (item) {
      item.remove();
      delete this.criterios[criterio];
      document.querySelector(
        `.add-criterio[data-criterio="${criterio}"]`
      ).parentElement.style.display = 'flex';
    }
  }
  openConfigPesosModal() {
    if (this.currentCriterio === 'data') {
      if (this.configPesosModal) {
        this.configPesosModal.style.display = 'block';
      }
    } else if (this.currentCriterio === 'categoria') {
      if (this.configPesosModalCategorias) {
        this.configPesosModalCategorias.style.display = 'block';
      }
    } else if (this.currentCriterio === 'logradouro') {
      if (this.configPesosModalLogradouro) {
        this.configPesosModalLogradouro.style.display = 'block';
      }
    } else if (this.currentCriterio === 'setoresTecnicos') {
      if (this.configPesosModalSetoresTecnicos) {
        this.configPesosModalSetoresTecnicos.style.display = 'block';
      }
    } else if (this.currentCriterio === 'hierarquiaViaria') {
      if (this.configPesosModalHierarquiaViaria) {
        this.configPesosModalHierarquiaViaria.style.display = 'block';
      }
    }
    this.populatePesos();
    this.initIncrementDecrementButtons(document);
  }

  getInitialPesoValues(criterio) {
    if (criterio === 'data') {
      return {
        '1dia': 0,
        '7dias': 0,
        '15dias': 0,
        '30dias': 0,
        mais30dias: 0,
      };
    } else if (criterio === 'categoria') {
      return {
        'Rampa de acessibilidade irregular ou inexistente': 0,
        'Calçada irregular': 0,
        'Calçada inexistente': 0,
        'Calçada inexistente/irregular': 0,
        'Bicicletário danificado': 0,
        'Ciclovia/ciclofaixa mal sinalizada ou apagada': 0,
        'Faixa de pedestre apagada': 0,
        'Ponto de travessia irregular': 0,
        'Ônibus fora do horário/rota': 0,
        'Estação de ônibus/trem/metrô danificada': 0,
        'Má conduta de motorista ou cobrador': 0,
        'Ônibus/trem/metrô danificado': 0,
        'Ponto de ônibus danificado': 0,
        'Rampa de acessibilidade irregular': 0,
        'Ponto de transporte clandestino': 0,
        'Ônibus/trem/metrô superlotado': 0,
        'Bloqueio na via': 0,
        'Buraco nas vias': 0,
        'Placa de sinalização quebrada/inexistente': 0,
        'Ponto de infração de trânsito recorrente': 0,
        'Ponto recorrente de animais na via': 0,
        'Semáforo quebrado': 0,
        'Veículo abandonado': 0,
        'Via de terra com desnível': 0,
        'Bueiro sem tampa': 0,
        'Ocupação irregular de área pública': 0,
        'Entulho na calçada/via pública': 0,
        'Mato alto': 0,
        'Queda de árvores': 0,
        'Rampa de acessibilidade': 0,
        'Equipamento público danificado': 0,
        'Vielas/Escadarias': 0,
        'Lâmpada apagada à noite': 0,
      };
    } else if (criterio === 'logradouro') {
      return {
        alameda: 0,
        avenida: 0,
        caminho: 0,
        estrada: 0,
        jardim: 0,
        largo: 0,
        marginal: 0,
        praca: 0,
        parque: 0,
        passarela: 0,
        ponte: 0,
        rodovia: 0,
        rua: 0,
        travessa: 0,
        viaduto: 0,
        via: 0,
      };
    } else if (criterio === 'setoresTecnicos') {
      return {
        'Setor 1': 0,
        'Setor 2': 0,
        'Setor 3': 0,
        'Setor 4': 0,
        'Setor 5': 0,
        'Setor 6': 0,
        'Setor 7': 0,
        'Setor 8': 0,
        'Setor 9': 0,
        'Setor 10': 0,
        'Setor 11': 0,
        'Setor 12': 0,
      };
    } else if (criterio === 'hierarquiaViaria') {
      return {
        'Não Informado': 0,
        Rodovia: 0,
        'Via Arterial Primaria': 0,
        'Via Arterial Secundaria': 0,
        'Via Coletora Primaria': 0,
        'Via Coletora Secundaria': 0,
        'Via Local': 0,
        'Via Metropolitana': 0,
      };
    }
  }

  populatePesos() {
    if (this.currentCriterio && this.criterios[this.currentCriterio]) {
      const pesos = this.criterios[this.currentCriterio];
      if (this.currentCriterio === 'data') {
        document.getElementById('peso-1dia').innerText = pesos['1dia'] || 0;
        document.getElementById('peso-7dias').innerText = pesos['7dias'] || 0;
        document.getElementById('peso-15dias').innerText = pesos['15dias'] || 0;
        document.getElementById('peso-30dias').innerText = pesos['30dias'] || 0;
        document.getElementById('peso-mais30dias').innerText =
          pesos['mais30dias'] || 0;
      } else if (this.currentCriterio === 'categoria') {
        document.getElementById(
          'peso-rampa-acessibilidade-irregular'
        ).innerText =
          pesos['Rampa de acessibilidade irregular ou inexistente'] || 0;
        document.getElementById('peso-calcada-irregular').innerText =
          pesos['Calçada irregular'] || 0;
        document.getElementById('peso-calcada-inexistente').innerText =
          pesos['Calçada inexistente'] || 0;
        document.getElementById(
          'peso-calcada-inexistente-irregular'
        ).innerText = pesos['Calçada inexistente/irregular'] || 0;
        document.getElementById('peso-bicicletario-danificado').innerText =
          pesos['Bicicletário danificado'] || 0;
        document.getElementById('peso-ciclovia-mal-sinalizada').innerText =
          pesos['Ciclovia/ciclofaixa mal sinalizada ou apagada'] || 0;
        document.getElementById('peso-faixa-pedestre-apagada').innerText =
          pesos['Faixa de pedestre apagada'] || 0;
        document.getElementById('peso-ponto-travessia-irregular').innerText =
          pesos['Ponto de travessia irregular'] || 0;
        document.getElementById('peso-onibus-fora-horario').innerText =
          pesos['Ônibus fora do horário/rota'] || 0;
        document.getElementById('peso-estacao-danificada').innerText =
          pesos['Estação de ônibus/trem/metrô danificada'] || 0;
        document.getElementById('peso-conduta-motorista-cobrador').innerText =
          pesos['Má conduta de motorista ou cobrador'] || 0;
        document.getElementById('peso-onibus-danificado').innerText =
          pesos['Ônibus/trem/metrô danificado'] || 0;
        document.getElementById('peso-ponto-onibus-danificado').innerText =
          pesos['Ponto de ônibus danificado'] || 0;
        document.getElementById('peso-rampa-irregular').innerText =
          pesos['Rampa de acessibilidade irregular'] || 0;
        document.getElementById('peso-ponto-transporte-clandestino').innerText =
          pesos['Ponto de transporte clandestino'] || 0;
        document.getElementById('peso-onibus-superlotado').innerText =
          pesos['Ônibus/trem/metrô superlotado'] || 0;
        document.getElementById('peso-bloqueio-via').innerText =
          pesos['Bloqueio na via'] || 0;
        document.getElementById('peso-buraco-vias').innerText =
          pesos['Buraco nas vias'] || 0;
        document.getElementById('peso-placa-sinalizacao-quebrada').innerText =
          pesos['Placa de sinalização quebrada/inexistente'] || 0;
        document.getElementById('peso-infracao-transito-recorrente').innerText =
          pesos['Ponto de infração de trânsito recorrente'] || 0;
        document.getElementById('peso-animais-na-via').innerText =
          pesos['Ponto recorrente de animais na via'] || 0;
        document.getElementById('peso-semaforo-quebrado').innerText =
          pesos['Semáforo quebrado'] || 0;
        document.getElementById('peso-veiculo-abandonado').innerText =
          pesos['Veículo abandonado'] || 0;
        document.getElementById('peso-via-terra-desnivel').innerText =
          pesos['Via de terra com desnível'] || 0;
        document.getElementById('peso-bueiro-sem-tampa').innerText =
          pesos['Bueiro sem tampa'] || 0;
        document.getElementById('peso-ocupacao-irregular').innerText =
          pesos['Ocupação irregular de área pública'] || 0;
        document.getElementById('peso-entulho-calcada').innerText =
          pesos['Entulho na calçada/via pública'] || 0;
        document.getElementById('peso-mato-alto').innerText =
          pesos['Mato alto'] || 0;
        document.getElementById('peso-queda-arvores').innerText =
          pesos['Queda de árvores'] || 0;
        document.getElementById('peso-rampa-acessibilidade').innerText =
          pesos['Rampa de acessibilidade'] || 0;
        document.getElementById('peso-equipamento-danificado').innerText =
          pesos['Equipamento público danificado'] || 0;
        document.getElementById('peso-vielas-escadarias').innerText =
          pesos['Vielas/Escadarias'] || 0;
        document.getElementById('peso-lampada-apagada-noite').innerText =
          pesos['Lâmpada apagada à noite'] || 0;
      } else if (this.currentCriterio === 'logradouro') {
        document.getElementById('peso-alameda').innerText =
          pesos['alameda'] || 0;
        document.getElementById('peso-avenida').innerText =
          pesos['avenida'] || 0;
        document.getElementById('peso-caminho').innerText =
          pesos['caminho'] || 0;
        document.getElementById('peso-estrada').innerText =
          pesos['estrada'] || 0;
        document.getElementById('peso-jardim').innerText = pesos['jardim'] || 0;
        document.getElementById('peso-largo').innerText = pesos['largo'] || 0;
        document.getElementById('peso-marginal').innerText =
          pesos['marginal'] || 0;
        document.getElementById('peso-praca').innerText = pesos['praca'] || 0;
        document.getElementById('peso-parque').innerText = pesos['parque'] || 0;
        document.getElementById('peso-passarela').innerText =
          pesos['passarela'] || 0;
        document.getElementById('peso-ponte').innerText = pesos['ponte'] || 0;
        document.getElementById('peso-rodovia-logradouro').innerText =
          pesos['rodovia'] || 0;
        document.getElementById('peso-rua').innerText = pesos['rua'] || 0;
        document.getElementById('peso-travessa').innerText =
          pesos['travessa'] || 0;
        document.getElementById('peso-viaduto').innerText =
          pesos['viaduto'] || 0;
        document.getElementById('peso-via').innerText = pesos['via'] || 0;
      } else if (this.currentCriterio === 'setoresTecnicos') {
        document.getElementById('peso-setor-1').innerText =
          pesos['Setor 1'] || 0;
        document.getElementById('peso-setor-2').innerText =
          pesos['Setor 2'] || 0;
        document.getElementById('peso-setor-3').innerText =
          pesos['Setor 3'] || 0;
        document.getElementById('peso-setor-4').innerText =
          pesos['Setor 4'] || 0;
        document.getElementById('peso-setor-5').innerText =
          pesos['Setor 5'] || 0;
        document.getElementById('peso-setor-6').innerText =
          pesos['Setor 6'] || 0;
        document.getElementById('peso-setor-7').innerText =
          pesos['Setor 7'] || 0;
        document.getElementById('peso-setor-8').innerText =
          pesos['Setor 8'] || 0;
        document.getElementById('peso-setor-9').innerText =
          pesos['Setor 9'] || 0;
        document.getElementById('peso-setor-10').innerText =
          pesos['Setor 10'] || 0;
        document.getElementById('peso-setor-11').innerText =
          pesos['Setor 11'] || 0;
        document.getElementById('peso-setor-12').innerText =
          pesos['Setor 12'] || 0;
      } else if (this.currentCriterio === 'hierarquiaViaria') {
        document.getElementById('peso-hierarquia-nao-informado').innerText =
          pesos['Não Informado'] || 0;
        document.getElementById('peso-hierarquia-rodovia').innerText =
          pesos['Rodovia'] || 0;
        document.getElementById('peso-hierarquia-arterial-primaria').innerText =
          pesos['Via Arterial Primaria'] || 0;
        document.getElementById(
          'peso-hierarquia-arterial-secundaria'
        ).innerText = pesos['Via Arterial Secundaria'] || 0;
        document.getElementById('peso-hierarquia-coletora-primaria').innerText =
          pesos['Via Coletora Primaria'] || 0;
        document.getElementById(
          'peso-hierarquia-coletora-secundaria'
        ).innerText = pesos['Via Coletora Secundaria'] || 0;
        document.getElementById('peso-hierarquia-local').innerText =
          pesos['Via Local'] || 0;
        document.getElementById('peso-hierarquia-metropolitana').innerText =
          pesos['Via Metropolitana'] || 0;
      }
    }
  }

  setDataInicial(dataInicial) {
    this.dataInicial = dataInicial;
    if (dataInicial) {
      console.log('Data inicial configurada:', this.dataInicial);
    } else {
      console.log('Data inicial não configurada.');
    }
  }

  // Atualiza a função para salvar pesos dos critérios
  savePesosCriterios() {
    const pesos = {
      data: Math.min(
        parseInt(document.getElementById('peso-criterio-data').innerText, 10) ||
          1,
        10
      ),
      logradouro: Math.min(
        parseInt(
          document.getElementById('peso-criterio-logradouro').innerText,
          10
        ) || 1,
        10
      ),
      categoria: Math.min(
        parseInt(
          document.getElementById('peso-criterio-categoria').innerText,
          10
        ) || 1,
        10
      ),
      setoresTecnicos: Math.min(
        parseInt(
          document.getElementById('peso-criterio-setoresTecnicos').innerText,
          10
        ) || 1,
        10
      ),
      hierarquiaViaria: Math.min(
        parseInt(
          document.getElementById('peso-criterio-hierarquia-viaria').innerText,
          10
        ) || 1,
        10
      ),
    };

    // Atualiza os pesos configurados no objeto criteriosConfig
    this.criteriosConfig = {
      ...this.criteriosConfig,
      ...pesos,
    };

    console.log('Pesos dos Critérios salvos:', this.criteriosConfig);
    this.configPesosModalCriterios.style.display = 'none';

    // Atualiza a interface para refletir os valores corrigidos
    document.getElementById('peso-criterio-data').innerText = pesos.data;
    document.getElementById('peso-criterio-logradouro').innerText =
      pesos.logradouro;
    document.getElementById('peso-criterio-categoria').innerText =
      pesos.categoria;
    document.getElementById('peso-criterio-setoresTecnicos').innerText =
      pesos.setoresTecnicos;
    document.getElementById('peso-criterio-hierarquia-viaria').innerText =
      pesos.hierarquiaViaria;
  }

  // Salva pesos dos critérios individuais
  savePesos() {
    if (this.currentCriterio === 'data') {
      if (!verificarDataInicial(this.dataInicial, this.criterios)) {
        showAlert(
          'Por favor, configure uma data inicial antes de salvar os pesos de data.'
        );
        return;
      }

      this.criterios[this.currentCriterio] = {
        '1dia':
          parseInt(document.getElementById('peso-1dia').innerText, 10) || 0,
        '7dias':
          parseInt(document.getElementById('peso-7dias').innerText, 10) || 0,
        '15dias':
          parseInt(document.getElementById('peso-15dias').innerText, 10) || 0,
        '30dias':
          parseInt(document.getElementById('peso-30dias').innerText, 10) || 0,
        mais30dias:
          parseInt(document.getElementById('peso-mais30dias').innerText, 10) ||
          0,
      };

      console.log(
        'Pesos de Data salvos:',
        this.criterios[this.currentCriterio]
      );
      this.configPesosModal.style.display = 'none';
    }
  }

  savePesosCategorias() {
    if (this.currentCriterio) {
      this.criterios[this.currentCriterio] = {
        'Rampa de acessibilidade irregular ou inexistente':
          parseInt(
            document.getElementById('peso-rampa-acessibilidade-irregular')
              .innerText,
            10
          ) || 0,
        'Calçada irregular':
          parseInt(
            document.getElementById('peso-calcada-irregular').innerText,
            10
          ) || 0,
        'Calçada inexistente':
          parseInt(
            document.getElementById('peso-calcada-inexistente').innerText,
            10
          ) || 0,
        'Calçada inexistente/irregular':
          parseInt(
            document.getElementById('peso-calcada-inexistente-irregular')
              .innerText,
            10
          ) || 0,
        'Bicicletário danificado':
          parseInt(
            document.getElementById('peso-bicicletario-danificado').innerText,
            10
          ) || 0,
        'Ciclovia/ciclofaixa mal sinalizada ou apagada':
          parseInt(
            document.getElementById('peso-ciclovia-mal-sinalizada').innerText,
            10
          ) || 0,
        'Faixa de pedestre apagada':
          parseInt(
            document.getElementById('peso-faixa-pedestre-apagada').innerText,
            10
          ) || 0,
        'Ponto de travessia irregular':
          parseInt(
            document.getElementById('peso-ponto-travessia-irregular').innerText,
            10
          ) || 0,
        'Ônibus fora do horário/rota':
          parseInt(
            document.getElementById('peso-onibus-fora-horario').innerText,
            10
          ) || 0,
        'Estação de ônibus/trem/metrô danificada':
          parseInt(
            document.getElementById('peso-estacao-danificada').innerText,
            10
          ) || 0,
        'Má conduta de motorista ou cobrador':
          parseInt(
            document.getElementById('peso-conduta-motorista-cobrador')
              .innerText,
            10
          ) || 0,
        'Ônibus/trem/metrô danificado':
          parseInt(
            document.getElementById('peso-onibus-danificado').innerText,
            10
          ) || 0,
        'Ponto de ônibus danificado':
          parseInt(
            document.getElementById('peso-ponto-onibus-danificado').innerText,
            10
          ) || 0,
        'Rampa de acessibilidade irregular':
          parseInt(
            document.getElementById('peso-rampa-irregular').innerText,
            10
          ) || 0,
        'Ponto de transporte clandestino':
          parseInt(
            document.getElementById('peso-ponto-transporte-clandestino')
              .innerText,
            10
          ) || 0,
        'Ônibus/trem/metrô superlotado':
          parseInt(
            document.getElementById('peso-onibus-superlotado').innerText,
            10
          ) || 0,
        'Bloqueio na via':
          parseInt(
            document.getElementById('peso-bloqueio-via').innerText,
            10
          ) || 0,
        'Buraco nas vias':
          parseInt(document.getElementById('peso-buraco-vias').innerText, 10) ||
          0,
        'Placa de sinalização quebrada/inexistente':
          parseInt(
            document.getElementById('peso-placa-sinalizacao-quebrada')
              .innerText,
            10
          ) || 0,
        'Ponto de infração de trânsito recorrente':
          parseInt(
            document.getElementById('peso-infracao-transito-recorrente')
              .innerText,
            10
          ) || 0,
        'Ponto recorrente de animais na via':
          parseInt(
            document.getElementById('peso-animais-na-via').innerText,
            10
          ) || 0,
        'Semáforo quebrado':
          parseInt(
            document.getElementById('peso-semaforo-quebrado').innerText,
            10
          ) || 0,
        'Veículo abandonado':
          parseInt(
            document.getElementById('peso-veiculo-abandonado').innerText,
            10
          ) || 0,
        'Via de terra com desnível':
          parseInt(
            document.getElementById('peso-via-terra-desnivel').innerText,
            10
          ) || 0,
        'Bueiro sem tampa':
          parseInt(
            document.getElementById('peso-bueiro-sem-tampa').innerText,
            10
          ) || 0,
        'Ocupação irregular de área pública':
          parseInt(
            document.getElementById('peso-ocupacao-irregular').innerText,
            10
          ) || 0,
        'Entulho na calçada/via pública':
          parseInt(
            document.getElementById('peso-entulho-calcada').innerText,
            10
          ) || 0,
        'Mato alto':
          parseInt(document.getElementById('peso-mato-alto').innerText, 10) ||
          0,
        'Queda de árvores':
          parseInt(
            document.getElementById('peso-queda-arvores').innerText,
            10
          ) || 0,
        'Rampa de acessibilidade':
          parseInt(
            document.getElementById('peso-rampa-acessibilidade').innerText,
            10
          ) || 0,
        'Equipamento público danificado':
          parseInt(
            document.getElementById('peso-equipamento-danificado').innerText,
            10
          ) || 0,
        'Vielas/Escadarias':
          parseInt(
            document.getElementById('peso-vielas-escadarias').innerText,
            10
          ) || 0,
        'Lâmpada apagada à noite':
          parseInt(
            document.getElementById('peso-lampada-apagada-noite').innerText,
            10
          ) || 0,
      };

      console.log(
        'Pesos de categorias salvos:',
        this.criterios[this.currentCriterio]
      );
      // Fecha o modal apenas se não houver pesos duplicados e nenhum peso exceder o limite
      this.configPesosModalCategorias.style.display = 'none';
    }
  }

  savePesosLogradouro() {
    if (this.currentCriterio) {
      this.criterios[this.currentCriterio] = {
        alameda:
          parseInt(document.getElementById('peso-alameda').innerText, 10) || 0,
        avenida:
          parseInt(document.getElementById('peso-avenida').innerText, 10) || 0,
        caminho:
          parseInt(document.getElementById('peso-caminho').innerText, 10) || 0,
        estrada:
          parseInt(document.getElementById('peso-estrada').innerText, 10) || 0,
        jardim:
          parseInt(document.getElementById('peso-jardim').innerText, 10) || 0,
        largo:
          parseInt(document.getElementById('peso-largo').innerText, 10) || 0,
        marginal:
          parseInt(document.getElementById('peso-marginal').innerText, 10) || 0,
        praca:
          parseInt(document.getElementById('peso-praca').innerText, 10) || 0,
        parque:
          parseInt(document.getElementById('peso-parque').innerText, 10) || 0,
        passarela:
          parseInt(document.getElementById('peso-passarela').innerText, 10) ||
          0,
        ponte:
          parseInt(document.getElementById('peso-ponte').innerText, 10) || 0,
        rodovia:
          parseInt(
            document.getElementById('peso-rodovia-logradouro').innerText,
            10
          ) || 0,
        rua: parseInt(document.getElementById('peso-rua').innerText, 10) || 0,
        travessa:
          parseInt(document.getElementById('peso-travessa').innerText, 10) || 0,
        viaduto:
          parseInt(document.getElementById('peso-viaduto').innerText, 10) || 0,
        via: parseInt(document.getElementById('peso-via').innerText, 10) || 0,
      };

      console.log(
        'Pesos de logradouro salvos:',
        this.criterios[this.currentCriterio]
      );
      this.configPesosModalLogradouro.style.display = 'none';
    }
  }

  savePesosSetoresTecnicos() {
    if (this.currentCriterio) {
      this.criterios[this.currentCriterio] = {
        'Setor 1':
          parseInt(document.getElementById('peso-setor-1').innerText, 10) || 0,
        'Setor 2':
          parseInt(document.getElementById('peso-setor-2').innerText, 10) || 0,
        'Setor 3':
          parseInt(document.getElementById('peso-setor-3').innerText, 10) || 0,
        'Setor 4':
          parseInt(document.getElementById('peso-setor-4').innerText, 10) || 0,
        'Setor 5':
          parseInt(document.getElementById('peso-setor-5').innerText, 10) || 0,
        'Setor 6':
          parseInt(document.getElementById('peso-setor-6').innerText, 10) || 0,
        'Setor 7':
          parseInt(document.getElementById('peso-setor-7').innerText, 10) || 0,
        'Setor 8':
          parseInt(document.getElementById('peso-setor-8').innerText, 10) || 0,
        'Setor 9':
          parseInt(document.getElementById('peso-setor-9').innerText, 10) || 0,
        'Setor 10':
          parseInt(document.getElementById('peso-setor-10').innerText, 10) || 0,
        'Setor 11':
          parseInt(document.getElementById('peso-setor-11').innerText, 10) || 0,
        'Setor 12':
          parseInt(document.getElementById('peso-setor-12').innerText, 10) || 0,
      };

      console.log(
        'Pesos de setores técnicos salvos:',
        this.criterios[this.currentCriterio]
      );
      this.configPesosModalSetoresTecnicos.style.display = 'none';
    }
  }

  // Adicione a lógica para salvar os pesos configurados para "Hierarquia Viária" na função savePesosHierarquiaViaria
  savePesosHierarquiaViaria() {
    const pesos = this.criterios['hierarquiaViaria'] || {};
    pesos['Não Informado'] = parseInt(
      document.getElementById('peso-hierarquia-nao-informado').innerText,
      10
    );
    pesos['Rodovia'] = parseInt(
      document.getElementById('peso-hierarquia-rodovia').innerText,
      10
    );
    pesos['Via Arterial Primaria'] = parseInt(
      document.getElementById('peso-hierarquia-arterial-primaria').innerText,
      10
    );
    pesos['Via Arterial Secundaria'] = parseInt(
      document.getElementById('peso-hierarquia-arterial-secundaria').innerText,
      10
    );
    pesos['Via Coletora Primaria'] = parseInt(
      document.getElementById('peso-hierarquia-coletora-primaria').innerText,
      10
    );
    pesos['Via Coletora Secundaria'] = parseInt(
      document.getElementById('peso-hierarquia-coletora-secundaria').innerText,
      10
    );
    pesos['Via Local'] = parseInt(
      document.getElementById('peso-hierarquia-local').innerText,
      10
    );
    pesos['Via Metropolitana'] = parseInt(
      document.getElementById('peso-hierarquia-metropolitana').innerText,
      10
    );
    this.criterios['hierarquiaViaria'] = pesos;
    this.configPesosModalHierarquiaViaria.style.display = 'none';
    console.log(
      'Pesos de Hierarquia Viária salvos:',
      this.criterios['hierarquiaViaria']
    );
  }

  savePriorizacao() {
    const dataInicial = this.dataInicial || new Date().toISOString();
    const loader = this.loader;
    loader.show();

    this.geoServerExtractor
      .fetchGeoServerData()
      .then((data) => {
        console.log('Data fetched from GeoServer:', data); // Colocado aqui
        const filteredData = this.geoServerExtractor.filterAndApplyWeights(
          data,
          dataInicial,
          this.criterios['data'],
          this.criterios['logradouro'],
          this.criterios['categoria'],
          this.criterios['setoresTecnicos'],
          this.criterios['hierarquiaViaria'],
          this.criteriosConfig
        );

        loader.hide();
        this.renderRelatorio(filteredData);
      })
      .catch((error) => {
        loader.hide();
        console.error('Erro ao buscar ou processar dados:', error);
      });
  }

  initClickableRows() {
    document.querySelectorAll('#relatorio-table tbody tr').forEach((row) => {
      row.addEventListener('click', () => {
        const numeroUnicoPriorizacao = row.cells[1].innerText;
        carregarRelatorio(numeroUnicoPriorizacao);
      });

      row.addEventListener('mouseover', () => {
        row.style.cursor = 'pointer';
      });

      row.addEventListener('mouseout', () => {
        row.style.cursor = 'default';
      });
    });
  }

  renderRelatorio(data) {
    console.log('Renderizando relatório com os dados:', data);
    const tabela = document.getElementById('relatorio-table');
    const tbody = tabela.querySelector('tbody');
    tbody.innerHTML = '';

    data.forEach((item) => {
      const row = document.createElement('tr');
      row.innerHTML = `
        <td><input type="checkbox" class="select-checkbox" data-id="${item.id}"></td>
        <td>${item.id}</td>
        <td>${item.pesoData}</td>
        <td>${item.pesoLogradouro}</td>
        <td>${item.pesoCategoria}</td>
        <td>${item.pesoSetorTecnico}</td>
        <td>${item.pesoHierarquiaViaria}</td>
        <td contenteditable="true" class="editable-priorizacao" data-id="${item.id}">${item.valorPriorizacao}</td>
      `;
      tbody.appendChild(row);
    });

    if ($.fn.dataTable.isDataTable('#relatorio-table')) {
      $('#relatorio-table').DataTable().destroy(); // Destrua a instância existente antes de criar uma nova
    }

    $('#relatorio-table').DataTable({
      order: [[7, 'desc']],
      paging: true,
      searching: true,
      language: {
        paginate: {
          next: 'Próximo',
          previous: 'Anterior',
        },
        lengthMenu: 'Mostrar _MENU_ entradas',
        search: '',
        info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
        infoFiltered: '(filtrado de _MAX_ entradas no total)',
        infoEmpty: 'Mostrando 0 a 0 de 0 entradas',
        zeroRecords: 'Nenhum registro correspondente encontrado',
        emptyTable: 'Nenhum dado disponível na tabela',
      },
      createdRow: function (row, data, dataIndex) {
        $(row)
          .children()
          .each(function () {
            $(this).css('text-align', 'center');
            $(this).css('cursor', 'pointer');
          });
      },
    });

    this.prioridadeManager.initEditableCells(); // Inicializa as células editáveis
    this.prioridadeManager.initClickableRows(); // Inicializa os eventos de clique nas células
  }

  savePriorizacao() {
    if (!this.isCategoriaValid()) {
      alert(
        'O critério de Categoria é obrigatório. Por favor, preencha pelo menos um peso.'
      );
      return;
    }

    console.log('Prioridade salva:', this.criterios);
    this.showRelatorioSeguro(); // Chama showRelatorioSeguro apenas se a categoria for válida
  }

  isCategoriaValid() {
    const categoriaPesos = this.criterios['categoria'] || {};
    return Object.values(categoriaPesos).some((peso) => peso > 0);
  }

  initEditableCells() {
    document.querySelectorAll('.editable-priorizacao').forEach((cell) => {
      cell.addEventListener('blur', (event) => {
        const id = event.target.dataset.id;
        const newValue = parseInt(event.target.innerText, 10);
        this.updateValorPriorizacao(id, newValue);
      });

      cell.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.target.blur(); // Força a célula a perder o foco e aciona o evento 'blur'
        }
      });
    });
  }

  updateValorPriorizacao(id, newValue) {
    if (!$.fn.dataTable.isDataTable('#relatorio-table')) {
      console.error('DataTable is not initialized');
      return;
    }

    const dataTable = $('#relatorio-table').DataTable();
    const rowIndex = dataTable.column(1).data().indexOf(parseInt(id, 10));

    if (rowIndex !== -1) {
      const rowData = dataTable.row(rowIndex).data();
      rowData.valorPriorizacao = newValue;
      dataTable.row(rowIndex).data(rowData).draw(false);
      dataTable.order([7, 'desc']).draw(false); // Ordena pela coluna "Valor da Priorização" em ordem decrescente
    }
  }

  moveRowToTop(id) {
    const dataTable = this.dataTable;
    const rowIndex = dataTable.column(1).data().indexOf(parseInt(id, 10));

    if (rowIndex !== -1) {
      const rowData = dataTable.row(rowIndex).data();
      rowData.valorPriorizacao = parseInt(rowData.valorPriorizacao, 10); // Converte o valor para número se não for
      dataTable.row(rowIndex).data(rowData).draw(false);
      dataTable.order([7, 'desc']).draw(false); // Ordena pela coluna "Valor da Priorização" em ordem decrescente
    }
  }

  async showRelatorioSeguro() {
    // Verificação de Data Inicial e Critérios
    if (!verificarDataInicial(this.dataInicial, this.criterios)) {
      return;
    }

    // Mostra o Loader
    this.loader.show();

    // Oculta o Modal de Configuração de Priorização
    if (this.modalConfigurarPriorizacao) {
      this.modalConfigurarPriorizacao.style.display = 'none';
    }

    // Geração de Número Único e Nome da Prioridade
    const numeroUnico = this.prioridadeManager.gerarNumeroUnico();
    const nomePrioridade = this.prioridadeManager.nomePrioridadeInput.value;

    // Formatação da Data Inicial
    let dataInicialFormatada = '';
    if (this.dataInicial) {
      const data = new Date(this.dataInicial);
      data.setHours(data.getHours() + 3);
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();
      dataInicialFormatada = `${dia}/${mes}/${ano}`;
    }

    // Criação do Conteúdo HTML do Relatório
    const relatorioContent = document.getElementById('relatorio-content');
    relatorioContent.innerHTML = `
      <div class="priorizacao-block">
        <div><strong>Prioridade:</strong> ${numeroUnico}</div>
        <div><strong>Nome:</strong> ${nomePrioridade}</div>
        <div><strong>Data Inicial Configurada:</strong> ${dataInicialFormatada}</div>
      </div>
      <div id="search-container"></div>
      <div style="display: flex; width: 100%;">
        <div id="relatorio-table-container" style="flex: 3;">
          <table id="relatorio-table" class="display" style="width:100%">
            <thead>
              <tr>
                <th><input type="checkbox" id="select-all-checkbox"> Selecionar</th>
                <th>Demanda</th>
                ${
                  this.criterios['data'] &&
                  Object.values(this.criterios['data']).some((peso) => peso > 0)
                    ? '<th>Data (' + (this.criteriosConfig.data || 1) + ')</th>'
                    : ''
                }
                ${
                  this.criterios['logradouro'] &&
                  Object.values(this.criterios['logradouro']).some(
                    (peso) => peso > 0
                  )
                    ? '<th>Tipo de Logradouro (' +
                      (this.criteriosConfig.logradouro || 1) +
                      ')</th>'
                    : ''
                }
                ${
                  this.criterios['categoria'] &&
                  Object.values(this.criterios['categoria']).some(
                    (peso) => peso > 0
                  )
                    ? '<th>Categorias (' +
                      (this.criteriosConfig.categoria || 1) +
                      ')</th>'
                    : ''
                }
                ${
                  this.criterios['setoresTecnicos'] &&
                  Object.values(this.criterios['setoresTecnicos']).some(
                    (peso) => peso > 0
                  )
                    ? '<th>Setores Técnicos (' +
                      (this.criteriosConfig.setoresTecnicos || 1) +
                      ')</th>'
                    : ''
                }
                ${
                  this.criterios['hierarquiaViaria'] &&
                  Object.values(this.criterios['hierarquiaViaria']).some(
                    (peso) => peso > 0
                  )
                    ? '<th>Hierarquia Viária (' +
                      (this.criteriosConfig.hierarquiaViaria || 1) +
                      ')</th>'
                    : ''
                }
                <th>Valor da Priorização</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
        <div id="criterios-content" style="flex: 2; margin-left: 10px;"></div>
      </div>
    `;

    try {
      // Busca e Filtragem dos Dados do GeoServer
      const tableData = await this.geoServerExtractor.fetchGeoServerData();
      const filteredData = this.geoServerExtractor.filterAndApplyWeights(
        tableData,
        this.dataInicial,
        this.criterios['data'] || {},
        this.criterios['logradouro'] || {},
        this.criterios['categoria'] || {},
        this.criterios['setoresTecnicos'] || {},
        this.criterios['hierarquiaViaria'] || {},
        this.criteriosConfig
      );

      // Configuração das Colunas da Tabela
      const columns = [
        {
          data: null,
          render: function (data, type, row) {
            return `<input type="checkbox" class="select-checkbox" data-id="${row.id}">`;
          },
          title: 'Selecionar',
          orderable: false,
          searchable: false,
        },
        { data: 'id', title: 'Demanda' },
        this.criterios['data'] &&
          Object.values(this.criterios['data']).some((peso) => peso > 0) && {
            data: 'pesoData',
            title: 'Data (' + (this.criteriosConfig.data || 1) + ')',
            render: function (data) {
              return data > 0 ? data : '-';
            },
          },
        this.criterios['logradouro'] &&
          Object.values(this.criterios['logradouro']).some(
            (peso) => peso > 0
          ) && {
            data: 'pesoLogradouro',
            title:
              'Tipo de Logradouro (' +
              (this.criteriosConfig.logradouro || 1) +
              ')',
            render: function (data) {
              return data > 0 ? data : '-';
            },
          },
        {
          data: 'pesoCategoria',
          title: 'Categorias (' + (this.criteriosConfig.categoria || 1) + ')',
          render: function (data) {
            return data > 0 ? data : '-';
          },
        },
        this.criterios['setoresTecnicos'] &&
          Object.values(this.criterios['setoresTecnicos']).some(
            (peso) => peso > 0
          ) && {
            data: 'pesoSetorTecnico',
            title:
              'Setores Técnicos (' +
              (this.criteriosConfig.setoresTecnicos || 1) +
              ')',
            render: function (data) {
              return data > 0 ? data : '-';
            },
          },
        this.criterios['hierarquiaViaria'] &&
          Object.values(this.criterios['hierarquiaViaria']).some(
            (peso) => peso > 0
          ) && {
            data: 'pesoHierarquiaViaria',
            title:
              'Hierarquia Viária (' +
              (this.criteriosConfig.hierarquiaViaria || 1) +
              ')',
            render: function (data) {
              return data > 0 ? data : '-';
            },
          },
        {
          data: 'valorPriorizacao',
          title: 'Valor da Priorização',
          className: 'editable-priorizacao',
          render: function (data) {
            return `<div contenteditable="true">${data}</div>`;
          },
        },
      ].filter(Boolean);

      // Inicialização da Tabela DataTable
      this.dataTable = $('#relatorio-table').DataTable({
        data: filteredData,
        columns,
        order: [[columns.length - 1, 'desc']],
        paging: true,
        searching: true,
        language: {
          paginate: {
            next: 'Próximo',
            previous: 'Anterior',
          },
          lengthMenu: 'Mostrar _MENU_ entradas',
          search: '',
          info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
          infoFiltered: '(filtrado de _MAX_ entradas no total)',
          infoEmpty: 'Mostrando 0 a 0 de 0 entradas',
          zeroRecords: 'Nenhum registro correspondente encontrado',
          emptyTable: 'Nenhum dado disponível na tabela',
        },
        createdRow: function (row, data, dataIndex) {
          $(row)
            .children()
            .each(function () {
              $(this).css('text-align', 'center');
            });
        },
      });

      // Configuração do Filtro da Tabela
      $('#relatorio-table_filter').html(`
        <div id="filter-container" style="display: flex; align-items: center; margin-bottom: 10px; padding: 0; margin: 0;">
          <label for="filter-criteria" style="margin-right: 8px;">Critério:</label>
          <select id="filter-criteria" style="margin-right: 8px; padding: 7px;">
            ${
              this.criterios['data'] ? '<option value="data">Data</option>' : ''
            }
            ${
              this.criterios['logradouro']
                ? '<option value="tipoLogradouro">Tipo de Logradouro</option>'
                : ''
            }
            <option value="categoria">Categorias</option>
            ${
              this.criterios['setoresTecnicos']
                ? '<option value="setorTecnico">Setores Técnicos</option>'
                : ''
            }
            ${
              this.criterios['hierarquiaViaria']
                ? '<option value="hierarquiaViaria">Hierarquia Viária</option>'
                : ''
            }
          </select>
          <label for="filter-value" style="margin-right: 8px;">Peso Atribuído:</label>
          <input type="number" id="filter-value" style="width: 80px; padding: 7px; margin-right: 8px;" />
          <button id="apply-filter" style="padding: 7px 10px; margin-top: 4px;">Aplicar Filtro</button>
        </div>
      `);

      $('#relatorio-table_filter').css({
        display: 'flex',
        'justify-content': 'flex-start',
        width: '100%',
        padding: '0',
        margin: '0',
      });

      document.getElementById('apply-filter').addEventListener('click', () => {
        const criteria = document.getElementById('filter-criteria').value;
        const value = parseInt(
          document.getElementById('filter-value').value,
          10
        );

        this.dataTable.columns().every(function () {
          this.search('');
        });

        if (criteria === 'data') {
          this.dataTable.column(2).search(`^${value}$`, true, false).draw();
        } else if (criteria === 'tipoLogradouro') {
          this.dataTable.column(3).search(`^${value}$`, true, false).draw();
        } else if (criteria === 'categoria') {
          const columnIndex = this.criterios['data']
            ? this.criterios['logradouro']
              ? 4
              : 3
            : 2;
          this.dataTable
            .column(columnIndex)
            .search(`^${value}$`, true, false)
            .draw();
        } else if (criteria === 'setorTecnico') {
          const columnIndex = this.criterios['data']
            ? this.criterios['logradouro']
              ? 5
              : 4
            : 3;
          this.dataTable
            .column(columnIndex)
            .search(`^${value}$`, true, false)
            .draw();
        } else if (criteria === 'hierarquiaViaria') {
          const columnIndex = this.criterios['data']
            ? this.criterios['logradouro']
              ? 6
              : 5
            : 4;
          this.dataTable
            .column(columnIndex)
            .search(`^${value}$`, true, false)
            .draw();
        }
      });

      // Renderização dos Critérios
      const criteriosContent = document.getElementById('criterios-content');
      criteriosContent.innerHTML = '';

      for (const [criterio, pesos] of Object.entries(this.criterios)) {
        const filteredPesos = Object.entries(pesos).filter(
          ([key, value]) => value > 0
        );

        if (criterio === 'data' && this.dataInicial) {
          const div = document.createElement('div');
          div.classList.add('criterio-block');
          const header = document.createElement('div');
          header.classList.add('criterio-header');
          const title = document.createElement('strong');
          title.innerText = 'Data';
          const toggleIcon = document.createElement('img');
          toggleIcon.src = 'assets/add.png';
          toggleIcon.classList.add('toggle-icon');

          header.appendChild(title);
          header.appendChild(toggleIcon);
          div.appendChild(header);

          const content = document.createElement('div');
          content.classList.add('criterio-content');
          content.innerHTML = `
            <div><strong>Peso Atribuído:</strong> ${
              this.criteriosConfig.data || 1
            }</div>
            <div><strong>Data Inicial:</strong> ${dataInicialFormatada}</div>
            ${filteredPesos
              .map(([key, value]) => `<div>${key}: ${value}</div>`)
              .join('')}
          `;

          div.appendChild(content);
          criteriosContent.appendChild(div);

          header.onclick = () => {
            const isVisible = content.style.display === 'block';
            content.style.display = isVisible ? 'none' : 'block';
            toggleIcon.src = isVisible ? 'assets/add.png' : 'assets/minus.png';
          };
        } else if (criterio === 'logradouro' && filteredPesos.length > 0) {
          const div = document.createElement('div');
          div.classList.add('criterio-block');
          const header = document.createElement('div');
          header.classList.add('criterio-header');
          const title = document.createElement('strong');
          title.innerText = 'Tipo de Logradouro';
          const toggleIcon = document.createElement('img');
          toggleIcon.src = 'assets/add.png';
          toggleIcon.classList.add('toggle-icon');

          header.appendChild(title);
          header.appendChild(toggleIcon);
          div.appendChild(header);

          const content = document.createElement('div');
          content.classList.add('criterio-content');
          content.innerHTML = `
            <div><strong>Peso Atribuído:</strong> ${
              this.criteriosConfig.logradouro || 1
            }</div>
            ${filteredPesos
              .map(([key, value]) => `<div>${key}: ${value}</div>`)
              .join('')}
          `;

          div.appendChild(content);
          criteriosContent.appendChild(div);

          header.onclick = () => {
            const isVisible = content.style.display === 'block';
            content.style.display = isVisible ? 'none' : 'block';
            toggleIcon.src = isVisible ? 'assets/add.png' : 'assets.minus.png';
          };
        } else if (criterio === 'setoresTecnicos' && filteredPesos.length > 0) {
          const div = document.createElement('div');
          div.classList.add('criterio-block');
          const header = document.createElement('div');
          header.classList.add('criterio-header');
          const title = document.createElement('strong');
          title.innerText = 'Setores Técnicos';
          const toggleIcon = document.createElement('img');
          toggleIcon.src = 'assets/add.png';
          toggleIcon.classList.add('toggle-icon');

          header.appendChild(title);
          header.appendChild(toggleIcon);
          div.appendChild(header);

          const content = document.createElement('div');
          content.classList.add('criterio-content');
          content.innerHTML = `
            <div><strong>Peso Atribuído:</strong> ${
              this.criteriosConfig.setoresTecnicos || 1
            }</div>
            ${filteredPesos
              .map(([key, value]) => `<div>${key}: ${value}</div>`)
              .join('')}
          `;

          div.appendChild(content);
          criteriosContent.appendChild(div);

          header.onclick = () => {
            const isVisible = content.style.display === 'block';
            content.style.display = isVisible ? 'none' : 'block';
            toggleIcon.src = isVisible ? 'assets/add.png' : 'assets.minus.png';
          };
        } else if (criterio === 'categoria' && filteredPesos.length > 0) {
          const div = document.createElement('div');
          div.classList.add('criterio-block');
          const header = document.createElement('div');
          header.classList.add('criterio-header');
          const title = document.createElement('strong');
          title.innerText = 'Categorias';
          const toggleIcon = document.createElement('img');
          toggleIcon.src = 'assets/add.png';
          toggleIcon.classList.add('toggle-icon');

          header.appendChild(title);
          header.appendChild(toggleIcon);
          div.appendChild(header);

          const content = document.createElement('div');
          content.classList.add('criterio-content');
          content.innerHTML = `
            <div><strong>Peso Atribuído:</strong> ${
              this.criteriosConfig.categoria || 1
            }</div>
            ${filteredPesos
              .map(([key, value]) => `<div>${key}: ${value}</div>`)
              .join('')}
          `;

          div.appendChild(content);
          criteriosContent.appendChild(div);

          header.onclick = () => {
            const isVisible = content.style.display === 'block';
            content.style.display = isVisible ? 'none' : 'block';
            toggleIcon.src = isVisible ? 'assets/add.png' : 'assets.minus.png';
          };
        } else if (
          criterio === 'hierarquiaViaria' &&
          filteredPesos.length > 0
        ) {
          const div = document.createElement('div');
          div.classList.add('criterio-block');
          const header = document.createElement('div');
          header.classList.add('criterio-header');
          const title = document.createElement('strong');
          title.innerText = 'Hierarquia Viária';
          const toggleIcon = document.createElement('img');
          toggleIcon.src = 'assets.add.png';
          toggleIcon.classList.add('toggle-icon');

          header.appendChild(title);
          header.appendChild(toggleIcon);
          div.appendChild(header);

          const content = document.createElement('div');
          content.classList.add('criterio-content');
          content.innerHTML = `
            <div><strong>Peso Atribuído:</strong> ${
              this.criteriosConfig.hierarquiaViaria || 1
            }</div>
            ${filteredPesos
              .map(([key, value]) => `<div>${key}: ${value}</div>`)
              .join('')}
          `;

          div.appendChild(content);
          criteriosContent.appendChild(div);

          header.onclick = () => {
            const isVisible = content.style.display === 'block';
            content.style.display = isVisible ? 'none' : 'block';
            toggleIcon.src = isVisible ? 'assets/add.png' : 'assets.minus.png';
          };
        }
      }

      // Gerenciamento da Visibilidade das Camadas
      $('#relatorio-table').on('change', '.select-checkbox', function () {
        const id = $(this).data('id');
        const visible = $(this).is(':checked');
        window.setLayerVisibility(id, visible);
      });

      const selectAllCheckbox = document.getElementById('select-all-checkbox');
      if (selectAllCheckbox) {
        selectAllCheckbox.addEventListener('change', function () {
          const checkboxes = document.querySelectorAll(
            '#relatorio-table tbody .select-checkbox'
          );
          checkboxes.forEach((checkbox) => {
            checkbox.checked = selectAllCheckbox.checked;
            const id = checkbox.getAttribute('data-id');
            window.setLayerVisibility(id, checkbox.checked);
          });
        });
      }

      // Oculta o Loader e Mostra o Modal do Relatório
      this.loader.hide();
      this.modalRelatorio.style.display = 'block';

      // Inicialização das Células Editáveis
      this.initEditableCells();
    } catch (error) {
      // Tratamento de Erros
      console.error('Erro ao gerar o relatório:', error);
      this.loader.hide();
      alert(
        'Ocorreu um erro ao gerar o relatório. Por favor, tente novamente.'
      );
    }
  }

  makeModalDraggable(modal) {
    let isMouseDown = false;
    let offset = { x: 0, y: 0 };

    modal.addEventListener('mousedown', (e) => {
      // Verifica se o clique é na barra de título do modal, e não na célula da tabela
      if (e.target.closest('.relatorio-modal-content')) {
        isMouseDown = true;
        offset = {
          x: modal.offsetLeft - e.clientX,
          y: modal.offsetTop - e.clientY,
        };
      }
    });

    document.addEventListener('mouseup', () => {
      isMouseDown = false;
    });

    document.addEventListener('mousemove', (e) => {
      if (isMouseDown) {
        const mousePosition = {
          x: e.clientX,
          y: e.clientY,
        };
        modal.style.left = mousePosition.x + offset.x + 'px';
        modal.style.top = mousePosition.y + offset.y + 'px';
      }
    });
  }

  resetCriterioSelection() {
    const selectedCriterios = document.getElementById('selected-criterios');
    selectedCriterios.innerHTML = '';
    this.criterios = {};
    this.addCriterioToForm('categoria');
    document
      .querySelectorAll('.add-criterio-container')
      .forEach((container) => (container.style.display = 'flex'));
  }

  setDataInicial(dataInicial) {
    this.dataInicial = dataInicial;
  }
}

export class Loader {
  constructor(loaderId) {
    this.element = document.getElementById(loaderId);
    if (!this.element) {
      console.error(`Loader element with ID ${loaderId} not found.`);
    }
  }

  show() {
    if (this.element) {
      this.element.style.display = 'flex';
    }
  }

  hide() {
    if (this.element) {
      this.element.style.display = 'none';
    }
  }
}

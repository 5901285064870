import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get as getProjection, transform } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { Loader } from './loading.js';

// Definir e registrar a projeção EPSG:31983 (UTM Zone 23S)
proj4.defs(
  'EPSG:31983',
  '+proj=utm +zone=23 +south +datum=WGS84 +units=m +no_defs'
);
register(proj4);

const projection31983 = getProjection('EPSG:31983');
const projection4326 = getProjection('EPSG:4326');
if (!projection31983 || !projection4326) {
  console.error(
    'Projeções EPSG:31983 ou EPSG:4326 não foram registradas corretamente.'
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const encarregaturaElement = document.getElementById('encarregatura');
  const assuntoElement = document.getElementById('assunto');
  const codigoAssuntoElement = document.getElementById('codigo');

  if (encarregaturaElement && assuntoElement && codigoAssuntoElement) {
    encarregaturaElement.addEventListener('change', () => {
      const selectedEncarregatura = encarregaturaElement.value;
      const optgroups = assuntoElement.querySelectorAll('optgroup');

      optgroups.forEach((optgroup) => {
        if (
          optgroup.label === selectedEncarregatura ||
          selectedEncarregatura === 'TODOS'
        ) {
          optgroup.style.display = 'block';
        } else {
          optgroup.style.display = 'none';
        }
      });

      assuntoElement.value = '';
      codigoAssuntoElement.value = '';
    });

    assuntoElement.addEventListener('change', () => {
      const selectedOption = assuntoElement.selectedOptions[0];
      codigoAssuntoElement.value = selectedOption.value;
    });
  } else {
    console.error('Um ou mais elementos do formulário não foram encontrados.');
  }
});

export class DemandaFormHandler {
  constructor(map) {
    this.map = map;
    this.loader = new Loader('loader-nova-demanda');
    this.demandaLayer = this.map
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'Demanda');

    this.initForm();
    this.initializePopup();
    this.addControlButton();

    this.map.on('singleclick', (evt) => this.handleMapClick(evt));
  }

  initForm() {
    const enviarDemandaButton = document.getElementById('enviarDemanda');
    const limparFormButton = document.getElementById('limparForm');
    const descEventoButton = document.getElementById('desc_evento_btn');
    const closeButton = document.getElementById('closeNovaDemanda');
    const saveDescButton = document.getElementById('saveDesc');
    const closeDescModal = document.getElementById('closeDescModal');
    const encarregaturaElement = document.getElementById('encarregatura');
    const assuntoElement = document.getElementById('assunto');
    const codigoAssuntoElement = document.getElementById('codigo');

    if (enviarDemandaButton) {
      enviarDemandaButton.onclick = () => {
        this.submitForm();
      };
    }

    if (limparFormButton) {
      limparFormButton.onclick = () => {
        this.clearForm();
      };
    }

    if (descEventoButton) {
      descEventoButton.addEventListener('click', () => {
        const descPopup = document.getElementById('descPopup');
        if (descPopup) {
          descPopup.style.display = 'block';
        }
      });
    }

    if (closeButton) {
      closeButton.addEventListener('click', () => {
        const novaDemandaDiv = document.getElementById('novaDemanda');
        if (novaDemandaDiv) {
          novaDemandaDiv.classList.remove('show');
        }
      });
    }

    if (saveDescButton) {
      saveDescButton.addEventListener('click', () => {
        const descText = document.getElementById('popupDescText').value;
        document.getElementById('dsc_evento').value = descText;
        const descPopup = document.getElementById('descPopup');
        if (descPopup) {
          descPopup.style.display = 'none';
        }
      });
    }

    if (closeDescModal) {
      closeDescModal.addEventListener('click', () => {
        const descPopup = document.getElementById('descPopup');
        if (descPopup) {
          descPopup.style.display = 'none';
        }
      });
    }

    if (encarregaturaElement && assuntoElement && codigoAssuntoElement) {
      encarregaturaElement.addEventListener('change', (event) => {
        const selectedGroup = event.target.value;
        this.filterAssuntos(selectedGroup);
      });

      assuntoElement.addEventListener('change', (event) => {
        const selectedOption = event.target.selectedOptions[0];
        codigoAssuntoElement.value = selectedOption.value;
      });
    }

    this.makeFormDraggable();
  }

  filterAssuntos(group) {
    const assuntoElement = document.getElementById('assunto');
    const optgroups = assuntoElement.querySelectorAll('optgroup');

    optgroups.forEach((optgroup) => {
      if (optgroup.label === group || group === 'TODOS') {
        optgroup.style.display = 'block';
      } else {
        optgroup.style.display = 'none';
      }
    });

    assuntoElement.selectedIndex = 0;
    document.getElementById('codigo').value = '';
  }

  initializePopup() {
    let element = document.getElementById('demanda-popup');
    if (!element) {
      element = document.createElement('div');
      element.id = 'demanda-popup';
      document.body.appendChild(element);
    }

    const demandaPopup = new ol.Overlay({
      element: element,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.map.addOverlay(demandaPopup);
    this.popup = demandaPopup;
  }

  toggleForm() {
    const novaDemandaDiv = document.getElementById('novaDemanda');
    if (novaDemandaDiv) {
      novaDemandaDiv.classList.toggle('show');
    }
  }

  addControlButton() {
    if (document.querySelector('.novaDemandaDiv')) {
      return;
    }

    const novaDemandaButton = document.createElement('button');
    novaDemandaButton.innerHTML =
      '<img src="assets/icons8-adicionar-camada-64.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
    novaDemandaButton.className = 'myButton';

    const novaDemandaElement = document.createElement('div');
    novaDemandaElement.className = 'novaDemandaDiv';
    novaDemandaElement.appendChild(novaDemandaButton);

    const novaDemandaControl = new ol.control.Control({
      element: novaDemandaElement,
    });

    this.map.addControl(novaDemandaControl);

    novaDemandaButton.addEventListener('click', async () => {
      const hasPermission = await this.verificarPermissao(
        'CADASTRAR_NOVA_DEMANDA'
      );

      if (!hasPermission) {
        alert(
          'Você não tem as permissões necessárias para criar uma nova demanda.'
        );
        return;
      }

      const novaDemandaDiv = document.getElementById('novaDemanda');
      if (novaDemandaDiv) {
        novaDemandaDiv.classList.toggle('show');
      }
    });
  }

  async verificarPermissao(funcionalidade) {
    const roleMap = {
      'IT': 'TI', // Mapeia 'IT' para 'TI'
    };

    const userRoles = localStorage.getItem('userRoles');

    if (!userRoles) {
      console.error('Roles do usuário não encontradas no localStorage.');
      return false;
    }

    try {
      const response = await fetch(
        `https://portalgeobim.com.br/portalpsa/api/permissoes/funcionalidade/${funcionalidade}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro ao verificar permissões: ${response.statusText}`);
      }

      let roles = await response.json();
      roles = roles.flatMap((role) => role.split(','));
      let userRolesArray = userRoles.split(',');
      userRolesArray = userRolesArray.map((role) => roleMap[role] || role);
      return roles.some((role) => userRolesArray.includes(role.trim()));
    } catch (error) {
      console.error(
        `Erro ao verificar permissões para ${funcionalidade}:`,
        error
      );
      return false;
    }
  }

  makeFormDraggable() {
    const element = document.getElementById('novaDemanda');
    const header = element.querySelector('.nova-demanda-modal-header');

    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    if (header) {
      header.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e.preventDefault();
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e.preventDefault();
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      element.style.top = element.offsetTop - pos2 + 'px';
      element.style.left = element.offsetLeft - pos1 + 'px';
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  closePopup(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.popup.getPosition() !== undefined) {
      this.popup.setPosition(undefined);
      this.popup.getElement().innerHTML = '';
    }
  }

  clearForm() {
    const formElement = document.getElementById('yourFormId');

    if (formElement) {
      formElement.reset();
    }

    const codigoElement = document.getElementById('codigo');
    const encarregaturaElement = document.getElementById('encarregatura');
    const dscEventoElement = document.getElementById('dsc_evento');
    const dscEnderecoElement = document.getElementById('dscEndereco');

    if (codigoElement) codigoElement.value = '';
    if (encarregaturaElement) encarregaturaElement.value = '';
    if (dscEventoElement) dscEventoElement.textContent = '';
    if (dscEnderecoElement) dscEnderecoElement.value = '';
  }

  async submitForm() {
    const codigoElement = document.getElementById('codigo');
    const encarregaturaElement = document.getElementById('encarregatura');
    const assuntoElement = document.getElementById('assunto');
    const dscEventoElement = document.getElementById('dsc_evento');
    const dscEnderecoElement = document.getElementById('dscEndereco');

    if (
      !codigoElement ||
      !encarregaturaElement ||
      !assuntoElement ||
      !dscEventoElement ||
      !dscEnderecoElement
    ) {
      console.error(
        'Um ou mais elementos do formulário não foram encontrados.'
      );
      return;
    }

    const codigo = codigoElement.value;
    const encarregatura =
      encarregaturaElement.options[encarregaturaElement.selectedIndex].text;
    const assunto = assuntoElement.options[assuntoElement.selectedIndex].text;
    const dscEvento = dscEventoElement.value;
    const dscEndereco = dscEnderecoElement.value;

    const demandaData = {
      codigo: codigo,
      encarregatura: encarregatura,
      assunto: assunto,
      dscEvento: dscEvento,
      dscEndereco: dscEndereco,
      dscStatus: 'NOVO',
      dtaCriacao: new Date().toISOString(),
      dtaAtualiza: new Date().toISOString(),
      dtaImporta: new Date().toISOString(),
    };

    alert('Escolha a Inserção da Nova Demanda');
    this.map.once('click', async (event) => {
      this.loader.show();

      const coordinates = ol.proj.toLonLat(event.coordinate);

      const transformedCoordinates = transform(
        coordinates,
        'EPSG:4326',
        'EPSG:31983'
      );

      const isInsideMunicipality = await this.checkPointWithinMunicipality(
        transformedCoordinates
      );
      if (!isInsideMunicipality) {
        alert(
          'O ponto clicado está fora do limite do município de Santo André'
        );
        this.loader.hide();
        return;
      }

      demandaData.longitude = coordinates[0];
      demandaData.latitude = coordinates[1];

      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          this.showMessage(
            'Erro: Token não encontrado. Faça login novamente.',
            'error'
          );
          return;
        }

        const bairroResponse = await fetch(
          `https://portalgeobim.com.br/portalpsa/api/get-bairro?lat=${demandaData.latitude}&lon=${demandaData.longitude}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!bairroResponse.ok) {
          throw new Error('Failed to fetch bairro data');
        }

        const bairroData = await bairroResponse.json();

        if (bairroData.features && bairroData.features.length > 0) {
          demandaData.nomBairro = bairroData.features[0].properties.NOM_BAIRRO;
        } else {
          demandaData.nomBairro = 'Bairro desconhecido';
        }
      } catch (error) {
        demandaData.nomBairro = 'Bairro desconhecido';
      }

      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          this.showMessage(
            'Erro: Token não encontrado. Faça login novamente.',
            'error'
          );
          return;
        }

        const createResponse = await fetch(
          'https://portalgeobim.com.br/portalpsa/api/demandas',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(demandaData),
          }
        );

        const createResult = await createResponse.json();

        if (createResponse.ok) {
          this.addDemandaToMap(createResult);
          this.clearForm();
          this.showMessage('Demanda criada com sucesso!', 'success');
        } else {
          this.showMessage(
            'Erro ao criar demanda. Por favor, tente novamente.',
            'error'
          );
        }
      } catch (error) {
        this.showMessage(
          'Erro ao criar demanda. Por favor, tente novamente.',
          'error'
        );
      } finally {
        this.loader.hide();
      }
    });
  }

  async checkPointWithinMunicipality(coordinates) {
    const wfsUrl = `https://sigamapa.santoandre.sp.gov.br/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=siga:SIGA_LIM_MUNICIPAL&cql_filter=CONTAINS(GEOMETRY,%20POINT(${coordinates[0]}%20${coordinates[1]}))`;

    try {
      const response = await fetch(wfsUrl);
      const responseText = await response.text();
      return responseText.includes('<gml:Polygon');
    } catch (error) {
      return false;
    }
  }

  addDemandaToMap(demanda) {
    const vectorSource = new ol.source.Vector({
      features: [
        new ol.Feature({
          geometry: new ol.geom.Point(
            ol.proj.fromLonLat([demanda.longitude, demanda.latitude])
          ),
        }),
      ],
    });

    const vectorLayer = new ol.layer.Vector({
      source: vectorSource,
    });

    this.map.addLayer(vectorLayer);

    if (this.demandaLayer) {
      this.demandaLayer.setVisible(true);
      this.demandaLayer.getSource().updateParams({ _: new Date().getTime() });
      this.demandaLayer.getSource().refresh();
      const checkbox = document.getElementById('novasDemandas');
      checkbox.checked = true;
    }
  }

  showMessage(message, type) {
    const messageBox = document.createElement('div');
    messageBox.className = `message-box ${type}`;
    messageBox.textContent = message;
    document.body.appendChild(messageBox);

    setTimeout(() => {
      messageBox.remove();
    }, 3000);
  }

  displayPopup(data, coordinate) {
    const popupContentElement = document.createElement('div');
    popupContentElement.className = 'popup-wrapper';

    const closeButton = document.createElement('img');
    closeButton.src = 'assets/preto-x.png';
    closeButton.alt = 'Close';
    closeButton.className = 'popup-close-button';
    closeButton.addEventListener('click', (event) => this.closePopup(event));

    const titleElement = document.createElement('div');
    titleElement.className = 'popup-title';
    titleElement.textContent = 'Detalhes da Demanda';

    const contentTable = document.createElement('div');
    contentTable.className = 'popup-content';

    const id = data.fid.split('.')[1];

    const rows = [
      { label: 'ID:', value: id },
      { label: 'Código:', value: data.codigo },
      { label: 'Encarregatura:', value: data.encarregatura },
      { label: 'Assunto:', value: data.assunto },
      { label: 'Descrição:', value: data.dsc_evento },
      { label: 'Data de Criação:', value: this.formatDate(data.dta_criacao) },
      { label: 'Endereço:', value: data.dsc_endereco },
      { label: 'Bairro:', value: data.nom_bairro },
      { label: 'Status:', value: data.dsc_status },
    ];

    rows.forEach((rowData) => {
      const row = document.createElement('div');
      row.className = 'popup-row';
      row.innerHTML = `<div class="popup-cell"><strong>${
        rowData.label
      }</strong> ${rowData.value || ''}</div>`;
      contentTable.appendChild(row);
    });

    const deleteButton = document.createElement('button');
    deleteButton.className = 'popup-delete-button';
    deleteButton.textContent = 'Excluir Demanda';
    deleteButton.addEventListener('click', () => this.deleteDemanda(id));

    popupContentElement.appendChild(closeButton);
    popupContentElement.appendChild(titleElement);
    popupContentElement.appendChild(contentTable);
    popupContentElement.appendChild(deleteButton);

    this.popup.getElement().innerHTML = '';
    this.popup.getElement().appendChild(popupContentElement);
    this.popup.setPosition(coordinate);
  }

  async deleteDemanda(id) {
    const confirmation = confirm(
      `Tem certeza que deseja excluir a demanda com ID ${id}?`
    );
    if (confirmation) {
      try {
        const token = localStorage.getItem('authToken');

        const response = await fetch(
          `https://portalgeobim.com.br/portalpsa/api/demandas/${id}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          alert('Demanda removida com sucesso!');
          this.closePopup();
          this.demandaLayer
            .getSource()
            .updateParams({ _: new Date().getTime() });
          this.demandaLayer.getSource().refresh();
        } else {
          alert('Erro ao remover demanda');
        }
      } catch (error) {
        alert('Erro ao excluir a demanda');
      }
    }
  }

  formatDate(dateString) {
    if (!dateString) return '';

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  }

  async handleMapClick(evt) {
    const viewResolution = this.map.getView().getResolution();
    if (this.demandaLayer && this.demandaLayer.getVisible()) {
      const url = this.demandaLayer
        .getSource()
        .getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
          INFO_FORMAT: 'application/json',
        });

      if (url) {
        try {
          const response = await fetch(url);
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            if (data.features.length > 0) {
              const feature = data.features[0];
              feature.properties.fid = feature.id;
              this.displayPopup(feature.properties, evt.coordinate);
            }
          }
        } catch (error) {
          this.closePopup();
        }
      }
    }
  }
}

function updateCategoriaButton() {
  const selectedOption =
    document.getElementById('group').options[
      document.getElementById('group').selectedIndex
    ];
  const categoriaButton = document.getElementById('codigo');
  if (categoriaButton) {
    categoriaButton.value = selectedOption.value;
  }
}

import { Loader } from './loading';

export class FeatureSelector {
  constructor(map) {
    this.map = map;
    this.loader = new Loader('loader-feature-selector');
    this.selectedFeatures = [];
    this.isInterrogationModeActive = false;
    this.eventHandler = this.handleMapClick.bind(this);
    this.popupContainer = this.createPopup();
    this.detailContainer = this.createDetailContainer();
    this.setupButton();
    this.yellowLayer = null;
    this.ids = [
      3325, 13361, 3375, 13381, 3379, 3373, 3374, 3378, 3377, 3376, 3309, 3355,
      3318, 3356, 3313, 3314, 3357, 3327, 3322, 3323, 3321, 3326, 3324, 3320,
      3331, 3344, 3369, 3367, 12359, 13369, 12550, 3348, 12551, 3335, 13381,
      13402, 13361, 13360, 13501, 13498, 13394, 13493, 13373, 13355, 13364,
      13491, 13391, 13368, 13356, 13363, 13384, 13388, 13404, 13492, 13406,
      13403, 13450, 13396, 13374, 13502, 225226,
    ];
  }

  clearSelectedFeatures() {
    if (this.yellowLayer) {
      const source = this.yellowLayer.getSource();
      source.clear(); // Limpa todas as feições da camada
    }

    this.selectedFeatures = [];
  }

  hideAllLayers() {
    this.map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if (layer.get('title') && layer.get('title').startsWith('Demandas')) {
          layer.setVisible(false);
        }
      });

    // Limpar os marcadores amarelos
    this.clearSelectedFeatures();
  }

  createPopup() {
    const popupContainer = document.createElement('div');
    popupContainer.id = 'popupContainer';
    popupContainer.style.display = 'none';
    document.body.appendChild(popupContainer);

    const closeButton = document.createElement('span');
    closeButton.className = 'form-close-button-popup';
    closeButton.innerHTML = '<img src="assets/preto-x.png" alt="Fechar" />';
    popupContainer.appendChild(closeButton);

    const title = document.createElement('h2');
    title.textContent = 'Selecionar Demandas para Agrupamento';
    popupContainer.appendChild(title);

    const separator = document.createElement('hr');
    popupContainer.appendChild(separator);

    // Cria um contêiner para a contagem
    const counterContainer = document.createElement('div');
    counterContainer.className = 'counter-container';

    this.counter = document.createElement('div');
    this.counter.textContent = 'Quantidade: 0';
    counterContainer.appendChild(this.counter);

    popupContainer.appendChild(counterContainer);

    // Cria um contêiner para os botões
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'button-container';

    const viewButton = document.createElement('button');
    viewButton.textContent = 'Visualizar';
    viewButton.className = 'button-style';
    viewButton.onclick = () => this.showDetails();
    buttonContainer.appendChild(viewButton);

    const groupButton = document.createElement('button');
    groupButton.textContent = 'Agrupar';
    groupButton.className = 'button-style';
    groupButton.onclick = () => this.confirmGrouping();
    buttonContainer.appendChild(groupButton);

    // Adiciona para o contêiner de botões ao popupContainer
    popupContainer.appendChild(buttonContainer);

    // Evento de clique no botão de fechar do popup
    closeButton.addEventListener('click', () => {
      popupContainer.style.display = 'none';
      this.map.getTargetElement().style.cursor = ''; // Redefine o cursor do mouse
      this.isInterrogationModeActive = false; // Atualiza o estado
    });

    return popupContainer;
  }

  createDetailContainer() {
    const container = document.createElement('div');
    container.id = 'detailContainer';
    container.className = 'detail-container';
    document.body.appendChild(container);

    const closeButton = document.createElement('button');
    closeButton.className = 'button-style close-button';
    closeButton.innerHTML =
      '<img src="assets/preto-x.png" alt="Fechar" style="width: 20px; height: 20px;">';
    closeButton.onclick = () => {
      container.style.display = 'none';
    };
    container.appendChild(closeButton);

    return container;
  }

  // Função para formatar a data
  formatDate(dateString) {
    if (!dateString) return '';

    const date = new Date(dateString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  }

  showDetails() {
    this.detailContainer.innerHTML = ''; // Limpa o conteúdo anterior

    const closeButton = document.createElement('button');
    closeButton.className = 'close-button';
    closeButton.innerHTML =
      '<img src="assets/preto-x.png" alt="Fechar" style="width: 20px; height: 20px;">';
    closeButton.onclick = () => {
      this.detailContainer.style.display = 'none';
    };
    this.detailContainer.appendChild(closeButton);

    const title = document.createElement('h3');
    title.textContent = 'Demandas para Agrupamento';
    title.className = 'detail-title';
    this.detailContainer.appendChild(title);

    const separator = document.createElement('hr');
    this.detailContainer.appendChild(separator);

    this.selectedFeatures.forEach((feature, index) => {
      const details = document.createElement('div');
      details.className = 'feature-detail';
      details.style.position = 'relative';

      const removeButton = document.createElement('button');
      removeButton.className = 'remove-button';
      removeButton.innerHTML =
        '<img src="assets/cancelar.png" alt="Remover" style="width: 12px; height: 12px;">';
      removeButton.style.position = 'absolute';
      removeButton.style.right = '10px';
      removeButton.style.top = '10px';
      removeButton.onclick = () => {
        this.removeFeature(index);
      };
      details.appendChild(removeButton);

      const content = document.createElement('div');
      content.style.paddingLeft = '30px';
      content.innerHTML = `
        <p><strong>ID:</strong> ${feature.ID_COLAB}</p>
        <p><strong>Id da Categoria:</strong> ${feature.ID_CATEGORIA}</p>
        <p><strong>Descrição da Categoria:</strong> ${feature.DSC_CATEGORIA}</p>
        <p><strong>Data de Criação:</strong> ${this.formatDate(
          feature.DTA_CRIACAO
        )}</p>
        <p><strong>Endereço:</strong> ${feature.DSC_ENDERECO}</p>
        <p><strong>Bairro:</strong> ${feature.NOM_BAIRRO}</p>
      `;
      details.appendChild(content);
      this.detailContainer.appendChild(details);
    });

    this.detailContainer.style.display = 'block';
  }

  removeFeature(index) {
    if (index > -1 && index < this.selectedFeatures.length) {
      this.selectedFeatures.splice(index, 1);
      this.counter.textContent = `Demandas selecionadas: ${this.selectedFeatures.length}`;
      this.showDetails(); // Atualiza a interface
    }
  }

  confirmGrouping() {
    if (this.selectedFeatures.length < 2) {
      this.showAlert(
        'Você precisa selecionar pelo menos duas demandas para agrupar.'
      );
      return;
    }

    const overlay = document.createElement('div');
    overlay.style = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1500;
    `;
    document.body.appendChild(overlay);

    const confirmContainer = document.createElement('div');
    confirmContainer.id = 'confirmContainer';
    confirmContainer.style =
      'position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: white; border: 1px solid black; padding: 20px; z-index: 3000;';
    document.body.appendChild(confirmContainer);

    const idsList = document.createElement('ul');
    this.selectedFeatures.forEach((feature) => {
      const listItem = document.createElement('li');
      listItem.textContent = feature.ID_COLAB;
      idsList.appendChild(listItem);
    });

    confirmContainer.innerHTML = `<p>Deseja Agrupar estas Demandas?</p>`;
    confirmContainer.appendChild(idsList);

    this.loader.show();

    const buttonsContainer = document.createElement('div');
    buttonsContainer.style =
      'display: flex; justify-content: center; gap: 10px; margin-top: 10px;';

    const yesButton = document.createElement('button');
    yesButton.textContent = 'Sim';
    yesButton.className = 'button-style';
    yesButton.onclick = () => {
      this.selectedFeatures.forEach((feature) => {
        feature.DSC_STATUS = 'AGRUPADO';
      });

      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      fetch('https://portalgeobim.com.br/portalpsa/api/agrupamento/agrupar', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.selectedFeatures),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Erro ao agrupar as demandas.');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Demandas agrupadas com sucesso:', data);
          this.loader.hide();

          alert('Selecione o ponto de inserção da nova demanda');
          this.map.once('click', (event) => {
            const coords = event.coordinate;
            const newcoordinates = ol.proj.toLonLat(coords, 'EPSG:900913');
            const latitude = newcoordinates[1];
            const longitude = newcoordinates[0];
            const selecionadoId = data.selecionadoId;

            let insertionSuccessful = false;

            fetch(
              'https://portalgeobim.com.br/portalpsa/api/agrupamento/inserir-ponto',
              {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  selecionadoId: selecionadoId,
                  latitude: latitude,
                  longitude: longitude,
                  geom: `POINT(${longitude} ${latitude})`,
                }),
              }
            )
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Erro ao inserir o ponto.');
                }
                return response.json();
              })
              .then((data) => {
                insertionSuccessful = true;
                console.log('Ponto inserido com sucesso:', data);
                alert('Ponto inserido com sucesso!');

                // Disparar evento customizado para informar o main.js
                const agrupamentoRealizadoEvent = new CustomEvent(
                  'agrupamentoRealizado',
                  {
                    detail: { selecionadoId, latitude, longitude },
                  }
                );
                document.dispatchEvent(agrupamentoRealizadoEvent);

                this.clearSelectedFeatures(); // Limpar feições selecionadas e resetar estilos
                this.hideAllLayers(); // Ocultar todos os layers relevantes
                this.hideYellowLayer(); // Ocultar a camada amarela
                this.resetInterrogationMode(); // Resetar o modo de interrogação
              })
              .catch((error) => {
                console.error('Erro ao inserir o ponto:', error);
                if (!insertionSuccessful) {
                  alert('Erro ao inserir o ponto.');
                }
              });
          });
        })
        .catch((error) => {
          console.error('Erro ao agrupar as demandas:', error);
          alert('Erro ao agrupar as demandas.');
        });

      document.body.removeChild(confirmContainer);
      document.body.removeChild(overlay);
      this.popupContainer.style.display = 'none';
      this.detailContainer.style.display = 'none';
    };

    const noButton = document.createElement('button');
    noButton.textContent = 'Não';
    noButton.className = 'button-style';
    noButton.onclick = () => {
      document.body.removeChild(confirmContainer);
      document.body.removeChild(overlay);
      this.loader.hide();
    };

    buttonsContainer.appendChild(yesButton);
    buttonsContainer.appendChild(noButton);
    confirmContainer.appendChild(buttonsContainer);
  }

  hideSelectedFeatures() {
    if (this.yellowLayer) {
      const source = this.yellowLayer.getSource();
      this.selectedFeatures.forEach((feature) => {
        const olFeature = source.getFeatureById(feature.ID_COLAB);
        if (olFeature) {
          source.removeFeature(olFeature);
        }
      });
    }

    this.selectedFeatures = [];
    this.counter.textContent = 'Demandas selecionadas: 0';
  }

  hideYellowLayer() {
    console.log('Tentando encontrar e ocultar a camada amarela...');
    if (this.yellowLayer) {
      this.map.removeLayer(this.yellowLayer);
      this.yellowLayer = null;
      console.log('Camada amarela encontrada e oculta.');
    } else {
      console.log('Camada amarela não encontrada.');
    }
  }

  inserirPonto(event) {
    const coordinate = this.map.getCoordinateFromPixel(event.pixel);
    const longitude = coordinate[0];
    const latitude = coordinate[1];

    const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

    fetch(
      'https://portalgeobim.com.br/portalpsa/api/agrupamento/inserir-ponto',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: this.selectedFeatures[0].ID_COLAB,
          latitude: latitude,
          longitude: longitude,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao inserir o ponto.');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Ponto inserido com sucesso:', data);
      })
      .catch((error) => {
        console.error('Erro ao inserir o ponto:', error);
      });

    this.map.un('click', this.inserirPonto);
  }

  showInsertPointPopup() {
    const insertPointOverlay = document.createElement('div');
    insertPointOverlay.style = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1500;
    `;
    document.body.appendChild(insertPointOverlay);

    const insertPointContainer = document.createElement('div');
    insertPointContainer.id = 'insertPointContainer';
    insertPointContainer.style =
      'position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); background: white; border: 1px solid black; padding: 20px; z-index: 3000;';
    insertPointContainer.innerHTML = `<p>Selecione o ponto de inserção da nova demanda</p>`;
    document.body.appendChild(insertPointContainer);

    const closeButton = document.createElement('button');
    closeButton.textContent = 'Fechar';
    closeButton.className = 'button-style';
    closeButton.onclick = () => {
      document.body.removeChild(insertPointContainer);
      document.body.removeChild(insertPointOverlay);
    };

    insertPointContainer.appendChild(closeButton);

    this.map.once('click', (event) => {
      const coordinates = event.coordinate;
      const latitude = coordinates[1];
      const longitude = coordinates[0];

      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      fetch(
        'https://portalgeobim.com.br/portalpsa/api/agrupamento/inserir-ponto',
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.selectedFeatures[0].ID_COLAB,
            latitude: latitude,
            longitude: longitude,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Erro ao inserir o ponto.');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Ponto inserido com sucesso:', data);
          document.body.removeChild(insertPointContainer);
          document.body.removeChild(insertPointOverlay);
        })
        .catch((error) => {
          console.error('Erro ao inserir o ponto:', error);
        });
    });
  }

  setupButton() {
    const groupButton = document.createElement('button');
    groupButton.innerHTML =
      '<img src="assets/network-group-svgrepo-com (1).svg" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle">';
    groupButton.className = 'myButton';
    groupButton.id = 'groupButton';

    const groupElement = document.createElement('div');
    groupElement.className = 'groupDiv';
    groupElement.style.margin = '10px auto auto 10px';
    groupElement.appendChild(groupButton);

    const groupControl = new ol.control.Control({
      element: groupElement,
    });

    this.map.addControl(groupControl);

    groupButton.addEventListener('click', async () => {
      const hasPermission = await this.verificarPermissao('AGRUPAR');

      if (!hasPermission) {
        alert(
          'Você não possui as permissões necessárias para realizar a ação de Agrupamento.'
        );
        return;
      }

      this.toggleInterrogationMode();
    });

    // Adiciona a tooltip ao botão de Agrupar
    setTimeout(() => {
      if (groupButton) {
        const tooltip = document.createElement('div');
        tooltip.className = 'custom-tooltip';
        tooltip.innerText = 'Agrupar';
        document.body.appendChild(tooltip);

        groupButton.addEventListener('mouseover', function (e) {
          const rect = groupButton.getBoundingClientRect();
          tooltip.style.left = `${rect.right + 10}px`;
          tooltip.style.top = `${
            rect.top + rect.height / 2 - tooltip.offsetHeight / 2
          }px`;
          tooltip.classList.add('custom-tooltip-visible');
        });

        groupButton.addEventListener('mouseout', function () {
          tooltip.classList.remove('custom-tooltip-visible');
        });
      }
    }, 0);
  }

  // Função que verifica a permissão do usuário para uma funcionalidade específica
  async verificarPermissao(funcionalidade) {
    const roleMap = {
      'IT': 'TI', // Mapeia 'IT' para 'TI'
    };

    const userRoles = localStorage.getItem('userRoles'); // Obtém as roles como uma string separada por vírgulas

    if (!userRoles) {
      console.error('Roles do usuário não encontradas no localStorage.');
      return false;
    }

    try {
      const response = await fetch(
        `https://portalgeobim.com.br/portalpsa/api/permissoes/funcionalidade/${funcionalidade}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Erro ao verificar permissões: ${response.statusText}`);
      }

      let roles = await response.json();

      // Processa as roles permitidas para garantir que estejam corretamente divididas
      roles = roles.flatMap((role) => role.split(',')); // Divide qualquer string como 'EN2,EN1,ED1' em ['EN2', 'EN1', 'ED1']

      // Converte as roles do usuário (que é uma string) em um array
      let userRolesArray = userRoles.split(',');

      // Aplica o mapeamento de roles conforme o roleMap
      userRolesArray = userRolesArray.map((role) => roleMap[role] || role);

      // Verifica se qualquer role do usuário está nas roles permitidas
      const hasPermission = roles.some((role) =>
        userRolesArray.includes(role.trim())
      );

      return hasPermission;
    } catch (error) {
      console.error(
        `Erro ao verificar permissões para ${funcionalidade}:`,
        error
      );
      return false;
    }
  }

  toggleInterrogationMode() {
    this.isInterrogationModeActive = !this.isInterrogationModeActive;
    if (this.isInterrogationModeActive) {
      console.log(
        'Modo de interrogação ativado. Clique na camada para obter informações.'
      );
      this.map.getTargetElement().style.cursor = 'crosshair';
      this.map.on('singleclick', this.eventHandler);
      this.popupContainer.style.display = 'block';
      this.getYellowLayer(); // Garantir que a camada amarela esteja presente
    } else {
      console.log('Modo de interrogação desativado.');
      this.map.getTargetElement().style.cursor = '';
      this.map.un('singleclick', this.eventHandler);
      this.clearSelectedFeatures();
      this.hideYellowLayer(); // Ocultar a camada amarela ao desativar o modo de interrogação
      this.counter.textContent = 'Demandas selecionadas: 0';
      this.popupContainer.style.display = 'none';
      this.detailContainer.style.display = 'none';
    }
  }

  resetInterrogationMode() {
    this.isInterrogationModeActive = false;
    this.map.getTargetElement().style.cursor = '';
    this.map.un('singleclick', this.eventHandler);
    this.clearSelectedFeatures();
    this.hideYellowLayer();
    this.counter.textContent = 'Demandas selecionadas: 0';
    this.popupContainer.style.display = 'none';
    this.detailContainer.style.display = 'none';
  }

  handleMapClick(evt) {
    console.log('Mapa clicado nas coordenadas:', evt.coordinate);
    this.loader.show(); // Mostra o loader ao clicar no mapa
    this.getFeatureInfo(
      'https://sigamapa.santoandre.sp.gov.br/geoserver/siga/wms',
      evt.coordinate
    )
      .then(() => {
        if (this.detailContainer.style.display === 'block') {
          this.showDetails();
        }
        this.loader.hide(); // Esconde o loader após processar as informações
      })
      .catch((error) => {
        console.error('Erro ao buscar informações da feição:', error);
        this.loader.hide(); // Esconde o loader em caso de erro
      });
  }

  getFeatureInfo(url, coordinate) {
    console.log(
      'Buscando informações da feição para as coordenadas:',
      coordinate
    );
    const viewResolution = this.map.getView().getResolution();
    const wmsSource = new ol.source.TileWMS({
      url: url,
      params: { LAYERS: 'siga:COLAB_DEMANDAS' },
      INFO_FORMAT: 'application/json',
    });

    const infoUrl = wmsSource.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      'EPSG:3857',
      { INFO_FORMAT: 'application/json' }
    );

    return fetch(infoUrl)
      .then((response) => response.json())
      .then((json) => {
        console.log('Resposta da informação da feição:', json);

        // Filtra as features para incluir apenas aquelas com IDs de categoria permitidos
        const filteredData = json.features
          .map((feature) => this.extractFeatureData(feature))
          .filter((feature) => this.ids.includes(feature.ID_CATEGORIA));

        if (filteredData.length > 0) {
          this.processFeatureData(filteredData);
        } else {
          console.log('Nenhuma feição correspondente encontrada neste ponto.');
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar informações da feição:', error);
      });
  }

  extractFeatureData(feature) {
    const { geometry, properties, geometry_name } = feature;
    return {
      type: geometry.type,
      geometry_name: geometry_name,
      coordinates: geometry.coordinates,
      ID_COLAB: properties.ID_COLAB,
      DSC_ENDERECO: properties.DSC_ENDERECO,
      DTA_CRIACAO: properties.DTA_CRIACAO,
      DSC_EVENTO: properties.DSC_EVENTO,
      ID_CATEGORIA: properties.ID_CATEGORIA,
      DSC_CATEGORIA: properties.DSC_CATEGORIA,
      NUM_LAT: properties.NUM_LAT,
      NUM_LON: properties.NUM_LON,
      NOM_BAIRRO: properties.NOM_BAIRRO,
      DSC_STATUS: properties.DSC_STATUS,
      DSC_TIPO: properties.DSC_TIPO,
      DTA_ATUALIZA: properties.DTA_ATUALIZA,
      DTA_IMPORTA: properties.DTA_IMPORTA,
      DSC_SATUS_IMPORT: properties.DSC_SATUS_IMPORT,
      DSC_AREA: properties.DSC_AREA,
    };
  }

  processFeatureData(filteredData) {
    const duplicatedIds = filteredData.filter((feature) =>
      this.selectedFeatures.some(
        (selectedFeature) => selectedFeature.ID_COLAB === feature.ID_COLAB
      )
    );

    if (duplicatedIds.length > 0) {
      const message = `Demanda ${duplicatedIds[0].ID_COLAB} já foi selecionada.`;
      this.showAlert(message);
      return;
    }

    if (this.selectedFeatures.length > 0) {
      const currentCategories = this.selectedFeatures.map(
        (f) => f.DSC_CATEGORIA
      );
      const newCategories = filteredData.filter(
        (fd) => !currentCategories.includes(fd.DSC_CATEGORIA)
      );

      if (newCategories.length > 0) {
        this.showAlert(
          `Deseja adicionar categoria diferente "${newCategories[0].DSC_CATEGORIA}"?`,
          true,
          () => {
            this.selectedFeatures.push(...filteredData);
            this.counter.textContent = `Demandas selecionadas: ${this.selectedFeatures.length}`;
            filteredData.forEach((feature) => this.updateFeatureStyle(feature)); // Atualizar estilo para todas as novas feições
          },
          () => {
            // Não faz nada, apenas fecha o alerta
          }
        );
        return;
      }
    }

    this.selectedFeatures.push(...filteredData);
    this.counter.textContent = `Demandas selecionadas: ${this.selectedFeatures.length}`;

    // Atualizar o estilo das feições clicadas
    filteredData.forEach((feature) => this.updateFeatureStyle(feature));
    this.loader.hide(); // Esconde o loader após atualizar o estilo da feição
  }

  updateFeatureStyle(feature, isSelected = true) {
    const vectorLayer = this.getYellowLayer();

    if (vectorLayer) {
      const source = vectorLayer.getSource();
      let olFeature = source.getFeatureById(feature.ID_COLAB);

      if (!olFeature) {
        olFeature = new ol.Feature({
          geometry: new ol.geom.Point(
            ol.proj.fromLonLat([feature.NUM_LON, feature.NUM_LAT])
          ),
          id: feature.ID_COLAB,
        });
        source.addFeature(olFeature);
      }

      if (isSelected) {
        olFeature.setStyle(
          new ol.style.Style({
            image: new ol.style.Circle({
              radius: 7,
              fill: new ol.style.Fill({
                color: '#FFFF00',
              }),
              stroke: new ol.style.Stroke({
                color: '#000000',
                width: 2,
              }),
            }),
          })
        );
      } else {
        source.removeFeature(olFeature); // Remover a feição quando não estiver selecionada
      }
    }
  }

  getYellowLayer() {
    if (!this.yellowLayer) {
      this.yellowLayer = new ol.layer.Vector({
        source: new ol.source.Vector(),
        style: new ol.style.Style({
          image: new ol.style.Circle({
            radius: 7,
            fill: new ol.style.Fill({
              color: '#FFFF00',
            }),
            stroke: new ol.style.Stroke({
              color: '#FFFF00',
              width: 2,
            }),
          }),
        }),
      });
      this.map.addLayer(this.yellowLayer);
    }

    return this.yellowLayer;
  }

  showAlert(message, hasConfirm = false, onConfirm = null, onCancel = null) {
    const overlay = document.createElement('div');
    overlay.className = 'popup-show';
    overlay.style = `
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2000;
      `;
    document.body.appendChild(overlay);

    const alertBox = document.createElement('div');
    alertBox.className = 'popup-show';
    alertBox.style = `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid black;
        padding: 20px;
        z-index: 3000;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: red;
        width: auto;
      `;
    alertBox.innerHTML = `<p>${message}</p>`;

    if (hasConfirm) {
      const buttonsContainer = document.createElement('div');
      buttonsContainer.style =
        'display: flex; justify-content: center; gap: 10px; margin-top: 10px;';

      const yesButton = document.createElement('button');
      yesButton.textContent = 'Sim';
      yesButton.className = 'button-style';
      yesButton.onclick = () => {
        document.body.removeChild(overlay);
        document.body.removeChild(alertBox);
        if (onConfirm) onConfirm();
      };

      const noButton = document.createElement('button');
      noButton.textContent = 'Não';
      noButton.className = 'button-style no-button';
      noButton.onclick = () => {
        document.body.removeChild(overlay);
        document.body.removeChild(alertBox);
        if (onCancel) onCancel();
      };

      buttonsContainer.appendChild(yesButton);
      buttonsContainer.appendChild(noButton);
      alertBox.appendChild(buttonsContainer);
    } else {
      const closeButton = document.createElement('button');
      closeButton.className = 'popup-close-button';
      closeButton.innerHTML = '<img src="assets/preto-x.png" alt="Fechar">';
      closeButton.onclick = () => {
        document.body.removeChild(overlay);
        document.body.removeChild(alertBox);
      };
      alertBox.appendChild(closeButton);
    }

    document.body.appendChild(alertBox);
  }
}

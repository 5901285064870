import WKT from 'ol/format/WKT';
import { unByKey } from 'ol/Observable';

export function initDeliberacaoRecusar() {
  console.log('initDeliberacaoRecusar inicializado');

  const recusarButton = document.getElementById('deliberacao-btn-recusar');
  if (!recusarButton) {
    console.log('Botão Recusar não encontrado');
    return;
  }

  recusarButton.addEventListener('click', function () {
    console.log('Botão Recusar clicado');
    alert('Selecione a Demanda para Recusar');

    var modal = document.getElementById('deliberacao-modal');
    modal.style.display = 'none';

    window.isProcessingClick = true;

    const clickListener = window.map.once('click', function (evt) {
      var feature = window.map.forEachFeatureAtPixel(
        evt.pixel,
        function (feature) {
          return feature;
        }
      );

      if (feature) {
        var properties = feature.getProperties();
        console.log('Dados do layer selecionado:', properties);

        const coords = feature.getGeometry().getCoordinates();
        const newcoordinates = ol.proj.toLonLat(coords, 'EPSG:900913');
        const idColab = properties.ID_COLAB || null;

        if (idColab) {
          const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

          fetch(
            `https://portalgeobim.com.br/portalpsa/api/verificarRecusado?idColab=${idColab}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.recusado) {
                alert('Esta demanda já foi recusada.');
                window.isProcessingClick = false;
                unByKey(clickListener);
              } else {
                abrirModalEmail(properties, newcoordinates);
              }
            })
            .catch((error) => {
              console.error('Erro ao verificar a demanda:', error);
              alert('Erro ao verificar a demanda.');
              window.isProcessingClick = false;
              unByKey(clickListener);
            });
        } else {
          alert('ID do colaborador não encontrado.');
          window.isProcessingClick = false;
          unByKey(clickListener);
        }
      } else {
        console.log('Nenhum layer foi selecionado.');
        alert('Nenhum layer foi selecionado.');
        window.isProcessingClick = false;
        unByKey(clickListener);
      }
    });
  });

  function abrirModalEmail(properties, newcoordinates) {
    var emailModal = document.getElementById('email-modal');
    emailModal.style.display = 'block';

    document.getElementsByClassName('email-close')[0].onclick = function () {
      emailModal.style.display = 'none';
    };

    document.getElementById('email-form').onsubmit = function (e) {
      e.preventDefault();

      var name = document.getElementById('email-name').value;
      var email = document.getElementById('email-address').value;
      var subject = document.getElementById('email-subject').value;
      var message = document.getElementById('email-message').value;

      var emailData = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        featureData: {
          idColab: properties.ID_COLAB || null,
          idCategoria: properties.ID_CATEGORIA || null,
          dscCategoria: properties.DSC_CATEGORIA || null,
          dscEvento: properties.DSC_EVENTO
            ? properties.DSC_EVENTO.substring(0, 2000)
            : null,
          geom: `POINT(${newcoordinates[0]} ${newcoordinates[1]})`,
          numLat: newcoordinates[1],
          numLon: newcoordinates[0],
          nomBairro: properties.NOM_BAIRRO || null,
          dscEndereco: properties.DSC_ENDERECO || null,
          dscTipo: properties.DSC_TIPO || null,
          dscArea: properties.DSC_AREA || null,
          dtaCriacao: properties.DTA_CRIACAO || null,
          dtaAtualiza: properties.DTA_ATUALIZA || null,
          dtaImporta: properties.DTA_IMPORTA || null,
        },
      };

      var loader = document.getElementById('loader-email');
      loader.style.display = 'block';

      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      fetch('https://portalgeobim.com.br/portalpsa/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => {
          loader.style.display = 'none'; // Hide loader
          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
          return response.text();
        })
        .then((data) => {
          emailModal.style.display = 'none';

          recusarDemanda(properties, newcoordinates);
        })
        .catch((error) => {
          loader.style.display = 'none'; // Hide loader
          console.error('Error:', error);
          alert(`Erro ao enviar e-mail: ${error.message}`);
        });

      window.isProcessingClick = false;
    };

    function recusarDemanda(properties, newcoordinates) {
      var data = {
        idColab: properties.ID_COLAB || null,
        idCategoria: properties.ID_CATEGORIA || null,
        dscCategoria: properties.DSC_CATEGORIA || null,
        dscEvento: properties.DSC_EVENTO
          ? properties.DSC_EVENTO.substring(0, 2000)
          : null,
        geom: `POINT(${newcoordinates[0]} ${newcoordinates[1]})`,
        numLat: newcoordinates[1],
        numLon: newcoordinates[0],
        nomBairro: properties.NOM_BAIRRO || null,
        dscEndereco: properties.DSC_ENDERECO || null,
        dscTipo: properties.DSC_TIPO || null,
        dscArea: properties.DSC_AREA || null,
        dtaCriacao: properties.DTA_CRIACAO || null,
        dtaAtualiza: properties.DTA_ATUALIZA || null,
        dtaImporta: properties.DTA_IMPORTA || null,
      };

      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      fetch('https://portalgeobim.com.br/portalpsa/api/deliberacoes/recusar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            return response.text().then((text) => {
              throw new Error(text);
            });
          }
          return response.json();
        })
        .then((data) => {
          alert('Demanda recusada com sucesso.');

          const source = new ol.source.Vector({
            features: [
              new ol.Feature({
                geometry: new ol.geom.Point(coords),
                properties: properties,
              }),
            ],
          });
          const layer = new ol.layer.Vector({
            source: source,
            zIndex: 1000,
          });
          window.map.addLayer(layer);

          window.wmsLayer.setVisible(true);
          document.getElementById('toggleWmsLayer').checked = true;

          window.isProcessingClick = false;
        })
        .catch((error) => {
          console.error('Error:', error);
          window.isProcessingClick = false;
        });
    }
  }
}

export function initializePopup(map, popupId = 'popup') {
  let element = document.getElementById(popupId);
  if (!element) {
    element = document.createElement('div');
    element.id = popupId;
    element.className = 'popup';
    document.body.appendChild(element);
  }

  const popup = new ol.Overlay({
    element: element,
    autoPan: true,
    autoPanAnimation: {
      duration: 250,
    },
  });

  map.addOverlay(popup);
  return popup;
}

// Função para formatar a data em português do Brasil
function formatDate(dateString) {
  if (!dateString) return '';

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'America/Sao_Paulo',
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', options).format(date);
}

// Função para adicionar 3 horas a uma data
function adicionarTresHoras(dataString) {
  const data = new Date(dataString);
  data.setHours(data.getHours() + 3);
  return data.toISOString();
}

export function displayPopup(popup, data, coordinate, closePopupCallback) {
  if (data.features.length > 0) {
    const featureProps = data.features[0].properties;
    const popupContentElement = document.createElement('div');
    popupContentElement.className = 'popup-wrapper';

    const closeButton = document.createElement('img');
    closeButton.src = 'assets/preto-x.png';
    closeButton.style.cssText =
      'position: absolute; right: 10px; top: 10px; width: 20px; height: 20px; cursor: pointer;';
    closeButton.addEventListener('click', (event) => closePopupCallback(event));

    const title = document.createElement('div');
    title.textContent = 'Informação da Demanda';
    title.style.cssText =
      'text-align: center; font-weight: bold; margin-bottom: 10px;';

    const hr = document.createElement('hr');
    hr.style.cssText = 'border: 1px solid black; margin-bottom: 10px;';

    const contentTable = document.createElement('table');
    contentTable.className = 'popup-content';

    const fields = [
      { label: 'ID', value: featureProps.ID_COLAB },
      { label: 'ID Categoria', value: featureProps.ID_CATEGORIA },
      // Ajustar a data antes de formatá-la
      {
        label: 'Data de Criação',
        value: formatDate(adicionarTresHoras(featureProps.DTA_CRIACAO)),
      },
      { label: 'Descrição da Categoria', value: featureProps.DSC_CATEGORIA },
      { label: 'Status', value: featureProps.DSC_STATUS },
      { label: 'Endereço', value: featureProps.DSC_ENDERECO },
      { label: 'Bairro', value: featureProps.NOM_BAIRRO },
      { label: 'Descrição do Evento', value: featureProps.DSC_EVENTO }, // Nova coluna adicionada
    ];

    fields.forEach((field) => {
      const row = contentTable.insertRow();
      const th = document.createElement('th');
      th.textContent = field.label;
      row.appendChild(th);
      const td = row.insertCell();
      td.textContent = field.value;
    });

    popupContentElement.appendChild(closeButton);
    popupContentElement.appendChild(title);
    popupContentElement.appendChild(hr);
    popupContentElement.appendChild(contentTable);

    popup.getElement().innerHTML = '';
    popup.getElement().appendChild(popupContentElement);
    popup.setPosition(coordinate);
  } else {
    console.error('Nenhum recurso encontrado.');
  }
}

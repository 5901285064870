import { Control } from 'ol/control';

export function addHideMenuControls(map) {
  // Evento sidebar esconder no início
  document.addEventListener('DOMContentLoaded', function () {
    // Adiciona a classe hide-sidebar à barra lateral quando o documento for carregado
    var sidebar = document.querySelector('.left');
    sidebar.classList.add('hide-sidebar');
  });

  // Botão para alternar a classe hide-sidebar
  var controlSidebar = document.createElement('button');
  controlSidebar.innerHTML =
    '<img src="assets/camadas.png" alt="" style="width:20px;height:20px;filter:brightness(0) invert(1);vertical-align:middle"><img>';
  controlSidebar.className = 'myButton';

  var sideElement = document.createElement('div');
  sideElement.className = 'sideButtonDiv';
  sideElement.appendChild(controlSidebar);

  var sideControl = new Control({
    element: sideElement,
  });

  // Adiciona um evento de clique ao botão de controle da barra lateral
  controlSidebar.addEventListener('click', toggleSidebar);

  // Função para alternar a classe hide-sidebar
  function toggleSidebar() {
    var sidebar = document.querySelector('.left');
    sidebar.classList.toggle('hide-sidebar');
  }

  map.addControl(sideControl);

  // Adicionando a tooltip ao botão controlSidebar
  setTimeout(() => {
    if (controlSidebar) {
      // Cria a tooltip e define o texto
      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.innerText = 'Painel Flutuante';
      document.body.appendChild(tooltip);

      // Adicionar eventos de mouse para hover
      controlSidebar.addEventListener('mouseover', function (e) {
        const rect = controlSidebar.getBoundingClientRect();
        tooltip.style.left = `${rect.right + 10}px`;
        tooltip.style.top = `${
          rect.top + rect.height / 2 - tooltip.offsetHeight / 2
        }px`;
        tooltip.classList.add('custom-tooltip-visible');
      });

      controlSidebar.addEventListener('mouseout', function () {
        tooltip.classList.remove('custom-tooltip-visible');
      });
    }
  });
}

export class AgrupadosLayerManager {
  constructor(map) {
    this.map = map;
    this.layer = null;

    this.closePopup = this.closePopup.bind(this);
    this.map.on('singleclick', (evt) => this.handleMapClick(evt));

    this.initializePopup();
  }

  initializePopup() {
    let element = document.getElementById('agrupados-popup');
    if (!element) {
      element = document.createElement('div');
      element.id = 'agrupados-popup';
      document.body.appendChild(element);
    }

    const agrupadosPopup = new ol.Overlay({
      element: element,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.map.addOverlay(agrupadosPopup);
    this.popup = agrupadosPopup;
  }

  closePopup(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.popup.getPosition() !== undefined) {
      this.popup.setPosition(undefined);
      this.popup.getElement().innerHTML = '';
    }
  }

  toggleAgrupadosLayer() {
    if (this.layer) {
      this.layer.setVisible(!this.layer.getVisible());
    } else {
      this.createLayer();
    }
  }

  createLayer() {
    const source = new ol.source.TileWMS({
      url: 'https://portalgeobim.com.br/geoserver/geobim_db/wms',
      params: {
        LAYERS: 'geobim_db:selecionado-agrupado',
        TILED: true,
        CQL_FILTER: '',
        serverType: 'geoserver',
      },
    });

    this.layer = new ol.layer.Tile({ source: source, visible: true });
    this.layer.set('name', 'Agrupados'); // Define o nome da camada
    this.map.addLayer(this.layer);
    this.setLayerVisibility(true);
  }

  handleMapClick(evt) {
    const viewResolution = this.map.getView().getResolution();
    if (this.layer && this.layer.getVisible()) {
      const url = this.layer
        .getSource()
        .getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
          INFO_FORMAT: 'application/json',
        });

      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            console.log('Feature info data:', data);
            if (data.features.length > 0) {
              const featureId = data.features[0].id;
              const selecionadoId = featureId.split('.')[1];
              console.log('Selecionado ID_COLAB:', selecionadoId);
              if (selecionadoId) {
                this.fetchAgrupadosData(selecionadoId, evt.coordinate);
              } else {
                console.error(
                  'Selecionado ID_COLAB não encontrado nos dados da feature.'
                );
              }
            } else {
              console.error('Nenhum recurso encontrado.');
            }
          })
          .catch((error) => {
            console.error('Erro ao obter informações da feature:', error);
            this.closePopup();
          });
      }
    }
  }

  formatDate(dateString) {
    if (!dateString) return '';

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'America/Sao_Paulo',
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pt-BR', options).format(date);
  }

  fetchAgrupadosData(selecionadoId, coordinate) {
    const token = localStorage.getItem('authToken'); // Obtém o token do localStorage
    const url = `https://portalgeobim.com.br/portalpsa/api/agrupamento/selecionado/${selecionadoId}`;

    fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
        'Content-Type': 'application/json', // Certifique-se de que o servidor espera um Content-Type application/json
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(`Erro ${response.status}: ${text}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          this.displayPopup(data, coordinate);
        } else {
          console.error('Dados recebidos não são um array:', data);
        }
      })
      .catch((error) => {
        console.error('Erro ao obter dados agrupados:', error);
        this.closePopup();
      });
  }

  displayPopup(data, coordinate) {
    const popupContentElement = document.createElement('div');
    popupContentElement.className = 'popup-wrapper';

    const closeButton = document.createElement('img');
    closeButton.src = 'assets/preto-x.png';
    closeButton.alt = 'Close';
    closeButton.className = 'popup-close-button';
    closeButton.addEventListener('click', (event) => this.closePopup(event));

    const titleElement = document.createElement('div');
    titleElement.className = 'popup-title';
    titleElement.textContent = 'Demandas Agrupadas';

    const contentTable = document.createElement('div');
    contentTable.className = 'popup-content';

    data.forEach((item) => {
      const row = document.createElement('div');
      row.className = 'popup-row';
      row.innerHTML = `
        <div class="popup-cell"><strong>ID_COLAB:</strong> ${
          item.agrupamentoId
        }</div>
        <div class="popup-cell"><strong>Categoria:</strong> ${
          item.dscCategoria
        }</div>
        <div class="popup-cell"><strong>Data de Criação:</strong> ${this.formatDate(
          item.dtaImporta
        )}</div>
        <div class="popup-cell"><img src="assets/cancelar.png" alt="Excluir" class="delete-icon" data-id="${
          item.agrupamentoId
        }" /></div>
      `;
      contentTable.appendChild(row);
    });

    popupContentElement.appendChild(closeButton);
    popupContentElement.appendChild(titleElement);
    popupContentElement.appendChild(contentTable);

    this.popup.getElement().innerHTML = '';
    this.popup.getElement().appendChild(popupContentElement);
    this.popup.setPosition(coordinate);

    document.querySelectorAll('.delete-icon').forEach((icon) => {
      icon.addEventListener('click', (event) => {
        const agrupamentoId = event.target.getAttribute('data-id');
        this.showDeleteConfirmation(agrupamentoId);
      });
    });
  }

  showDeleteConfirmation(agrupamentoId) {
    const confirmation = confirm(
      'Deseja excluir este agrupamento? ID_COLAB: ' + agrupamentoId
    );
    if (confirmation) {
      const token = localStorage.getItem('authToken'); // Obtém o token do localStorage

      fetch(
        `https://portalgeobim.com.br/portalpsa/api/agrupamento/${agrupamentoId}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho Authorization
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            this.closePopup();
            this.refreshLayer();
            alert(`Demanda Id ${agrupamentoId} excluída com sucesso`);
          } else {
            response
              .json()
              .then((error) => {
                throw new Error(error.message);
              })
              .catch((error) => {
                console.error('Erro ao excluir agrupamento:', error);
              });
          }
        })
        .catch((error) => {
          console.error('Erro ao excluir agrupamento:', error);
        });
    }
  }

  refreshLayer() {
    this.map.removeLayer(this.layer);
    this.createLayer();
  }

  setLayerVisibility(visible) {
    if (this.layer) {
      this.layer.setVisible(visible);
    }
  }

  removeLayerByName(name) {
    this.map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if (layer.get('name') === name) {
          this.map.removeLayer(layer);
        }
      });
  }
}
